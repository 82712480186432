import { Component, OnInit, ViewChild, HostListener  } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StatisticsService } from '../../../statistics.service';
import { FieldService } from '../../../field.service';
import { DatasetService } from '../../../dataset.service';
import { LngLatLike, Map } from 'mapbox-gl';
import { StatisticsMapLayerService } from '../../../statistics-map-layer.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { IListResponse } from 'src/app/models/global.model';
import { IField } from 'src/app/models/field.model';
import * as XLSX from 'xlsx';
import { IStatistic } from 'src/app/models/statistics.model';
import { event } from 'cypress/types/jquery';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'stm-app-statistics-page',
    templateUrl: './stm-statistics-page.component.html',
    styleUrls: ['./stm-statistics-page.component.css']
  })
  export class STMStatisticsPageComponent {
 // searchboxes
 search = '';
 showSearch1 = false;
 summary: IStatistic[];

 options = ["aaaaa", "bbbb", "cccc", "dddd"];
 fieldId; // Field id
 field; // Field bbox
 datasets; // List of datasets
 selectedDatasetId; // Selected dataset
 selectedDatasetIdAverageOne;
 selectedDatasetIdAverageTwo;
 singleTreeWidthDataset;

 isFieldComparisonOn = false;

 globalSettings = {
   config: { displayModeBar: false }
 }
 treesPerType = {
   data: [
     {
       x: ['0-8cm', '8-10cm', '10-12cm', '12-14cm', '14-16cm', '16-18cm', '18-20cm', '20-25cm', '25-30cm', '30-35cm', '35-40cm', '40-45cm', '45-50cm', '50-60cm', '60-70cm', '70-80cm', '80-90cm'],
       y: [2, 5, 3, 8, 12, 8, 10, 2, 5, 3, 8, 12, 8, 10, 2, 5, 3], type: 'bar', name: "Amount of trees"
     },
   ],
   layout: {
     showlegend: false,
     yaxis: {
       automargin: true,
       title: 'Y-axis Title',
     },
     xaxis: {
       automargin: true,
       title: 'X-axis Title',
     },
   },
 };


 treesPerTypeLine = {
   data: [
     {
       x: ['0-8cm', '8-10cm', '10-12cm', '12-14cm', '14-16cm', '16-18cm', '18-20cm', '20-25cm', '25-30cm', '30-35cm', '35-40cm', '40-45cm', '45-50cm', '50-60cm', '60-70cm', '70-80cm', '80-90cm'],
       y: [2, 5, 3, 8, 12, 8, 10, 2, 5, 3, 8, 12, 8, 10, 2, 5, 3], type: 'line', name: "Amount of trees"
     },
   ],
   layout: {
     showlegend: false,
     yaxis: {
       automargin: true,
       title: 'Y-axis Title',
     },
     xaxis: {
       automargin: true,
       title: 'X-axis Title',
     },
   },
 };


 treesPerTypePie = {
   data: [{
     values: [19, 26, 55, 19, 26, 55, 19, 26, 55, 19, 26, 55, 19, 26, 55, 19, 26, 55, 19, 26, 55, 19, 26, 55, 19, 26, 55, 19, 26, 55],
     labels: ['Reshidential', 'Non-Redrsidential', 'Utilhity', 'Residentdial', 'Non-Residseential', 'Utilgity', 'Residenktial', 'Ndn-Residential', 'Utildity', 'Residegntial', 'Non-Residhential', 'Utiljity', 'Residkential', 'Non-Residlential', 'ttUtility', 'Ressidential', 'Non-Reswidential', 'Utilyity', 'Resideuntial', 'Non-Residiential', 'Utirlity', 'Residrential', 'Non-Residetntial', 'Utiliyty', 'Resiudential', 'iNon-Residential', 'Utiolity', 'Respidential', 'Nonn-Residential', 'Uvtility',],
     type: 'pie'
   }
   ],
   layout: {
     showlegend: false,
     yaxis: {
       automargin: true,
       title: 'Y-axis Title',
     },
     xaxis: {
       automargin: true,
       title: 'X-axis Title',
     },
   },
 };
 averageTreeGrowthData = {
   data: [
     { x: [], y: [], type: 'scatter', marker: { color: [] } }
   ],
   layout: {}
 };
 treesPerCircumferenceData = {
   data: [
     { x: [], y: [], type: 'bar', marker: { color: [] } }
   ],
   layout: {}
 };
 calculatedTreeCircumferenceData = {
   data: [
     { x: [], y: [], type: 'bar', marker: { color: [] } }
   ],
   layout: {},
 };
 singleTreeGrowthData = {
   data: [
     { x: [], y: [], type: 'bar', marker: { color: [] } }
   ],
   layout: {},
 };

 map: Map;
 num = 17;
 center: LngLatLike;
 layers: StatisticsMapLayerService;

 enableFieldSelector = false;
 enableDateSelector = false;
 selectedDate = '3000-10-10';
 selectedFields = [];
 selectedFieldsToCompare = [];

 allSelected = false;
 allSelectedToCompare = false;
 allSelectedExcept = false;

 fieldList: IListResponse<IField>;

 measurement = 'circumference';

 searchedField = '';


//  Additional filters
  //    tree type
treeTypeInput = "";
isTreeTypeInputFocused = false;
treeTypesList = [];
lastClickedElement: HTMLElement | null = null;

@HostListener('document:mousedown', ['$event'])
  onMouseDown(event: MouseEvent): void {
    this.lastClickedElement = event.target as HTMLElement;
    if(!this.lastClickedElement.classList.contains("tree-type-list-item")  && !this.lastClickedElement.classList.contains("tree-type-drop-selection")){
      this.isTreeTypeInputFocused = false;
    }
  }

handleToggleFieldComparison(){
  this.isFieldComparisonOn = !this.isFieldComparisonOn;

}

getActualTreeTypes(rawData): void{
  const allRawTypes = [];
  for(let i=0; i<rawData.length; i++){
      if(rawData[i].tree_type != "N/A"){
        allRawTypes.push(rawData[i].tree_type)
      }
  }

  this.treeTypesList = allRawTypes;
}

handleSelectTreeType(treeType: string = ""): void{
  if(this.treeTypeInput.includes(treeType)){
    const selectedTypes = this.treeTypeInput.split(",");
    const index = selectedTypes.indexOf(treeType);
    if (index !== -1) {
      selectedTypes.splice(index, 1);
    }
    this.treeTypeInput = selectedTypes.join(",");
  }
  else{
    if(this.treeTypeInput != ""){
      this.treeTypeInput += "," + treeType;
    }
    else{
      this.treeTypeInput = treeType;
    }
  }
}

onTreeTypeFocus(): void {
  this.isTreeTypeInputFocused = true;
}


onTreeTypeOptionSelect(option): void{
  this.treeTypeInput = option;
}


  //    bucket size
bucketSizes = [];
selectedBuckets = []

getBucketSizes(rawData): void{


  const allBuckets = new Set();
  
  rawData.forEach(item => {
    item.data.forEach(entry => {
      if(entry.bucket != ""){
        allBuckets.add(entry.bucket);
      }
    });
  });

  let buckets: any = Array.from(allBuckets);


  this.bucketSizes = buckets.sort((a, b) => {
    // Split the ranges into start and end values
    const [startA, endA] = a.split('-').map(Number);
    const [startB, endB] = b.split('-').map(Number);
  
    // Compare the starting values
    if (startA !== startB) {
      return startA - startB;
    }
  
    // If starting values are the same, compare the ending values
    return endA - endB;
  });
  
}

handleBucketSizeClick(bucketSize): void{
  if(!this.selectedBuckets.includes(bucketSize)){
    this.selectedBuckets.push(bucketSize);
  }
  else{
    const index = this.selectedBuckets.indexOf(bucketSize);
    if (index !== -1) {
      this.selectedBuckets.splice(index, 1);
    }
  }
}

  //    notes
notesInput = "";

  //    damage
damagedValue = null;

handleDamagedValueChangeTrue(event: any): void{
  if (event.target.checked) {
    this.damagedValue = true;
  } else {
    this.damagedValue = null;
  }
}

handleDamagedValueChangeFalse(event: any): void{
  if (event.target.checked) {
    this.damagedValue = false;
  } else {
    this.damagedValue = null;
  }
}

standardizeSummary(summary){
  summary.forEach(item => {
    const existingBuckets = new Set(item.data.map(entry => entry.bucket));
    // Add missing buckets with value 0
    this.bucketSizes.forEach(bucket => {
      if (!existingBuckets.has(bucket)) {
       
        item.data.push({ bucket: bucket, value: 0 });
      }
    });

    // Sort data array by bucket to maintain order if necessary
    item.data.sort((a, b) => {
      // Sort numerically by the first number in the bucket, then by the entire string if needed
      // Split the bucket strings into start and end values
      const [startA, endA] = a.bucket.split('-').map(Number);
      const [startB, endB] = b.bucket.split('-').map(Number);

      // Compare the starting values
      if (startA !== startB) {
        return startA - startB;
      }

      // If starting values are the same, compare the ending values
      return endA - endB;
    });
  });
}


filteredSummary = null;
applyAllFilters(): void {
  // Creating a deep copy of the summary
  this.filteredSummary = JSON.parse(JSON.stringify(this.summary));
  if(this.treeTypeInput != ""){
    this.filteredSummary = this.filteredSummary.filter(item => this.treeTypeInput.includes(item.tree_type));
  }

  if(this.selectedBuckets.length != 0){
    let tempArray = JSON.parse(JSON.stringify(this.filteredSummary));
    for(let i = 0; i < this.filteredSummary.length; i++){
        tempArray[i].data = this.filteredSummary[i].data.filter(item => this.selectedBuckets.includes(item.bucket))
    }

    this.filteredSummary = tempArray;
  }



  // the rest... (damaged and notes)

}

clearFilters(): void{
  this.filteredSummary = null;
  this.damagedValue = null;
  this.notesInput = "";
  this.treeTypeInput = "";
  this.selectedBuckets = [];
}

 // tslint:disable-next-line:max-line-length
 constructor(private activatedRoute: ActivatedRoute, private statisticsService: StatisticsService, private fieldService: FieldService, private datasetService: DatasetService) {
 }

 ngOnInit(): void {
   this.fieldService.get_fields(0, 1000).subscribe(data => { this.fieldList = data; });
 }

 includeField(event: any, fieldId: string) {

   if (event.checked) {
     this.selectedFields.push(fieldId)
     if(this.selectedFieldsToCompare.length == 0){
      this.loadData()
     }
     else{
      this.loadDataCompared()
    }
   } else {
     const index = this.selectedFields.indexOf(fieldId, 0);
     if (index > -1) {
       this.selectedFields.splice(index, 1);
       if(this.selectedFieldsToCompare.length == 0){
        this.loadData()
       }
       else{
        this.loadDataCompared()
      }
     }
   }
 }

 includeFieldToCompare(event: any, fieldId: string) {

  if (event.checked) {
    this.selectedFieldsToCompare.push(fieldId)
    this.loadDataCompared()
  } else {
    const index = this.selectedFieldsToCompare.indexOf(fieldId, 0);
    if (index > -1) {
      this.selectedFieldsToCompare.splice(index, 1);
      if(this.selectedFieldsToCompare.length == 0){
        this.isFieldComparisonOn = false;
        this.allSelectedToCompare = false;
        this.selectedFieldsToCompare = [];
        this.loadData()
      }
      else{
        this.loadDataCompared()
      }
      
    }
  }
}

 statRows = []

 selectAllFields(event: any) {
   if (event.checked) {
     this.searchedField = ''
     this.allSelected = true;
     this.selectedFields = this.fieldList.items.map(({ id }) => id)
     this.loadData()
   } else {
     this.allSelected = false;
     this.selectedFields = []
     this.loadData()
   }
 }

 selectAllFieldsToCompare(event: any){
  if (event.checked) {
    this.searchedField = ''
    this.allSelectedToCompare = true;
    this.selectedFieldsToCompare = this.fieldList.items.map(({ id }) => id)
    this.loadDataCompared()
  } else {
    this.isFieldComparisonOn = false;
    this.allSelectedToCompare = false;
    this.selectedFieldsToCompare = [];
    this.loadData()
  }
 }

 loadData() {
  this.summary = [];
   if (this.selectedFields.length > 0) {
     this.statisticsService.get_summary(this.selectedDate, this.selectedFields).subscribe(data => {
       if (data.collection.length > 0) {
        console.log(data.collection);
         this.summary = data.collection;
         this.getBucketSizes(data.collection);
         this.filteredSummary = null;
         this.getActualTreeTypes(data.collection);
         this.standardizeSummary(this.summary);
       } else {
         this.summary = null
       }
     });
   }
   else{
    this.summary = null;
   }
 }

 compareFieldsAndLoadSummary(primaryFields, fieldsToCompare){
  let completeData = [];
  this.standardizeSummary(primaryFields);
  this.standardizeSummary(fieldsToCompare);
  for(let i=0; i< primaryFields.length; i++){
    let treeType = {...primaryFields[i]};
    treeType.data = [];
    const foundItem = fieldsToCompare.find((compareType) => compareType.tree_type == primaryFields[i].tree_type);
    if(foundItem != undefined){
      for(let j=0; j < primaryFields[i].data.length; j++){
        let newDataBukcet = {} as {bucket: string, value: number};
        const foundBucket = foundItem.data.find((bucket) => bucket.bucket == primaryFields[i].data[j].bucket)
        if(foundBucket != undefined){
          newDataBukcet = {...primaryFields[i].data[j]};
          newDataBukcet.value = primaryFields[i].data[j].value - foundBucket.value;
          treeType.data.push(newDataBukcet);
        }    
      }
      completeData.push(treeType);
    }
  }

  this.getActualTreeTypes(completeData);
  this.getBucketSizes(completeData);
  this.summary = completeData;
  this.filteredSummary = null;
  this.standardizeSummary(this.summary)
 }

 loadDataCompared(){
  if (this.selectedFields.length > 0 && this.selectedFieldsToCompare.length > 0) {
    this.summary = [];
    let primaryFieldsSelected = [];
    let fieldsToCompareSelected = [];

    forkJoin([this.statisticsService.get_summary(this.selectedDate, this.selectedFields), this.statisticsService.get_summary(this.selectedDate, this.selectedFieldsToCompare)]).subscribe(
      ([primaryFields, fieldsToCompare]) => {

        if(primaryFields.collection.length > 0){
          primaryFieldsSelected = primaryFields.collection;
        }

        if(fieldsToCompare.collection.length > 0){
          fieldsToCompareSelected = fieldsToCompare.collection;
        }
        if(primaryFieldsSelected.length > 0 && fieldsToCompareSelected.length > 0){
          

          this.compareFieldsAndLoadSummary(primaryFieldsSelected, fieldsToCompareSelected);
        }
        else{
          this.summary = null
        }
    })
  } else {
    this.summary = null
  }
 }

//  loadMap() {
//    this.activatedRoute.queryParams.subscribe(params => {
//      this.fieldService.get_field(params.field_id).subscribe(res => {
//        this.field = res.geometry.coordinates[0];
//        this.center = res.center_point.coordinates;
//        this.layers = new StatisticsMapLayerService(this.map, this.statisticsService);
//        this.layers.setField(this.field);
//      }
//      );
//    });
//  }

 changeDate(event: any) {
   this.selectedDate = new Date(event.getTime() - (event.getTimezoneOffset() * 60000))
   .toISOString()
   .split("T")[0];

   this.loadData()
 }

 exportToPDF(): void {
   document.getElementById('loader').style.display = 'block';
   let DATA: any = document.getElementById('export_pdf');
   html2canvas(DATA).then((canvas) => {

     var imgData = canvas.toDataURL('image/png');

     var imgWidth = canvas.width;
     var imgHeight = canvas.height;
     if(imgWidth > imgHeight){
       var doc = new jsPDF('l', 'px', [imgWidth, imgHeight]);
     }
     else if(imgHeight > imgWidth){
       var doc = new jsPDF('p', 'px', [imgWidth, imgHeight]);
     }
     
     var position = 0;
     doc.setDisplayMode(1, 'continuous')
     doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
     doc.save('Agrowizard-statistics.pdf');
     document.getElementById('loader').style.display = 'none';

   });
 }

 exportTableToExcel() {
   if (this.summary || this.filteredSummary) {

    let dataToExport = this.filteredSummary ? this.filteredSummary : this.summary;
     let data = []

     for (let item of dataToExport) {
       let json = { "tree_type": item.tree_type};
       item.data.forEach(bucket => {
        json[bucket.bucket] = bucket.value;
       })
       data.push(json);
     }

     const ws = XLSX.utils.json_to_sheet(data);
     const workBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(workBook, ws, 'Aantallen');
     XLSX.writeFile(workBook, 'AgroWizard_Export.xlsx');
   }
 }

 update_search(search_val) {
   this.search = search_val.target.value;
 }

 select_field(id) {

 }

}