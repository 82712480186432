import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import * as global from '../../globals';
import { concatMap, take } from 'rxjs/operators';
import { AuthService } from '@auth0/auth0-angular';
import { environment as env } from '../../environments/environment';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient, public auth: AuthService, private api: ApiService) {
  }

  getUserInfo(localToken: string): Observable<any> {
    try {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Headers': 'Content-Type',
        token: localToken
      });
      const options = { headers };

      return this.http.get<any>(
        global.apiUrl + global.account + '/userFromToken',
        options
      );
    } catch (ex) {
      return ex;
    }
  }

  updateUserInfo(email: string, nickname: string): Observable<any> {
    let myparams = new HttpParams();
    if (email) {
      myparams = myparams.append('email', encodeURIComponent(email));
    }
    if (nickname) {
      myparams = myparams.append('nickname', encodeURIComponent(nickname));
    }
    const httpOptions = {
      params: myparams
    };
    return this.auth.user$
      .pipe(
        take(1),
        concatMap((user) => {
            return this.http.post<any>(`${env.api_gateway_url}/account/profile`, null, httpOptions);
          }
        )
      );
  }

  changePassword(
    localToken: string,
    newPassword: string,
    currentPassword: string
  ): Observable<any> {
    try {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Headers': 'Content-Type',
        token: localToken
      });
      const options = { headers };
      return this.http.post<any>(
        global.apiUrl + global.auth + '/updatepassword',
        {
          old_password: currentPassword,
          new_password: newPassword
        },
        options
      );
    } catch (ex) {
      return ex;
    }
  }


  // new methods replacing floris his crap
  public get_user(): Observable<any> {
    return this.api.get_with_auth(`/user`)
  }

  public update_user(firstName: string, lastName: string): Observable<any> {
    return this.api.patch_with_auth(`/user`, {
      "first_name": firstName,
      "last_name": lastName
    })
  }
}
