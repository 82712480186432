export function companyLogoLayer(map: mapboxgl.Map){
    map.addLayer({
        id: 'points',
        type: 'symbol',
        source: 'logo-point', // reference the data source
        layout: {
          'icon-image': 'logo', // reference the image
          'icon-size': 0.2,
        },
      });
}