<div class="tree-measurement-data-component" *ngIf="this.isVisible.value">
    <h3>{{'i18n.APP_SPECIFIC.STM.MAIN_DATA_MEASUREMENTS.TITLE' | translate}}</h3>

    <div class="tree-measurement-data-overview" *ngIf="displayData.value">
        <table>
            <tr>
                <th>
                    {{'i18n.APP_SPECIFIC.STM.MAIN_DATA_MEASUREMENTS.PROPERTY' | translate}}
                </th>
                <th>
                    {{'i18n.APP_SPECIFIC.STM.MAIN_DATA_MEASUREMENTS.VALUE' | translate}}
                </th>
            </tr>
            <tr>
                <td>{{'i18n.APP_SPECIFIC.STM.MAIN_DATA_MEASUREMENTS.ID' | translate}}</td>
                <td><span *ngIf="displayData.value.id">{{displayData.value.id}}</span></td>
            </tr>
            <tr>
                <td>{{'i18n.APP_SPECIFIC.STM.MAIN_DATA_MEASUREMENTS.TREE_CIRCUMFERENCE' | translate}}</td>
                <td><span *ngIf="displayData.value.circumference">{{getFormatedCircumference()}} cm</span></td>
            </tr>
            <tr>
                <td>{{'i18n.APP_SPECIFIC.STM.MAIN_DATA_MEASUREMENTS.SIZE_LABEL' | translate}}</td>
                <td><span *ngIf="displayData.value.bucket">{{displayData.value.bucket}} cm</span></td>
            </tr>
            <tr>
                <td>{{'i18n.APP_SPECIFIC.STM.MAIN_DATA_MEASUREMENTS.TREE_TYPE' | translate}}</td>
                <td><span *ngIf="displayData.value.treeType != 'null'">{{displayData.value.treeType}}</span></td>
            </tr>
        </table>
    </div>
   
</div>