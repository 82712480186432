<div class="">
    <div class="">
      <h1>Step 1: Create organization and admin account for organization</h1>
      <label>Organization name:</label><input #name/>
      <h2>Admin account:</h2>
      <label>Username:</label><input #username/><br />
      <label>E-mail:</label><input #email/><br />
      <!-- <label>Password (?):</label><input type="password" /> -->
      <button class="global_btn " (click)="createOrgAndAdmin(name.value, username.value, email.value)">Create</button>
    </div>
    <div class="">
      <h1>Step 2: Add connectivity device</h1>
      <label>Device name:</label><input #conName/><br />
      <button class="global_btn " (click)="newConDevice(conName.value)">Add device</button>
      <p>{{conDeviceId}}</p>
    </div>
    <div class="">
      <h1>Step 3: Add camera device</h1>
      <label>Device name:</label><input #nameDevice/><br />
      <label>Parent device id:</label><input #parentid/><br />
      <button class="global_btn " (click)="newCameraDevice(nameDevice.value)">Add camera</button>
    </div>
  </div>
  