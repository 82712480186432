import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.css'],
})
export class ChangePasswordDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
  ) {}

  get password() {
    return this.changePasswordForm.get('password');
  }
  get confirmPassword() {
    return this.changePasswordForm.get('confirmPassword');
  }

  get currentPassword() {
    return this.changePasswordForm.get('currentPassword');
  }
  changePasswordLabel = 'Change my password';
  singUpBtnClass = 'longBtnG';
  changePasswordForm: UntypedFormGroup;
  logoSize = 'BigSize';
  passwordChanged: boolean;


  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.changePasswordForm = this.fb.group(
      {
        currentPassword: [
          '',
          [
            Validators.required,
            Validators.minLength(12),
            Validators.maxLength(50),
          ],
        ],
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(12),
            Validators.maxLength(50),
          ],
        ],
        confirmPassword: [
          '',
          [
            Validators.required,
            Validators.minLength(12),
            Validators.maxLength(50),
          ],
        ],
      },
      { validator: this.checkPasswords }
    );
  }
  checkPasswords(group: UntypedFormGroup) {
    // here we have the 'passwords' group
    const pass = group.get('password').value;
    const confirmPass = group.get('confirmPassword').value;

    return pass === confirmPass ? null : { notSame: true };
  }

  functioncall(event) {
    this.changePassword();
  }

  changePassword() {
    this.passwordChanged = undefined;
    this.changePasswordForm.reset();
  }
}
