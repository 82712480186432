import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PortalAppsService } from './services/portal-apps/portal-apps.service';
import { OrganizationService } from './services/organization.service';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {

  constructor(private router: Router, private appService: PortalAppsService ) { }

  private disassebleUrl(url) {
    // Split the URL to get the base URL and query string separately
    const [baseUrl, queryString] = url.split('?');

    // Store the query parameters in an object
    const queryParams = {};
    if (queryString) {
      const searchParams = new URLSearchParams(queryString);
      searchParams.forEach((value, key) => {
        queryParams[key] = value;
      });
    }

    return {baseUrl: baseUrl, params: queryParams}
  }

  handleUnmatchedRoute(unmatchedUrl: string): void {
    // Add custom logic to handle dynamic routes here
    let found = false;


    this.appService.getAllAvaliableApps().then((apps) => {
      
      apps.forEach((app) => {
        if(unmatchedUrl.startsWith('/apps/' + app.codeName)){
          const innerPath = unmatchedUrl.replace('/apps/' + app.codeName + "/", "");
          const processedurl = this.disassebleUrl(innerPath);
          const baseInnerUrl = processedurl.baseUrl;
          const params = processedurl.params;

          app.pages.forEach(page => {
            if(page.path == baseInnerUrl){
              this.router.navigate(["/apps/" + app.codeName], {queryParams: {navigateTo: innerPath}})
              found = true
            }
          })
           
        }
      })
  })

    if(!found){
      this.router.navigateByUrl('/apps');
    }

  }

  
}
