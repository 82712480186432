export function TreeMeasurementDataOnClickEvents(map, eventCallback){
    let featureid = ''
    map.on('click', "treeMeasurementData-layer", (e) => {
        if (featureid != e.features[0].id) {
            map.setFeatureState(
              {
                source: 'treeMeasurementData-source',
                id: featureid,
              },
              {
                clicked: false,
              }
            );
          }
    
          
          featureid = e.features[0].id.toString();
          map.getCanvas().style.cursor = 'pointer';
    
          map.setFeatureState(
            {
              source: 'treeMeasurementData-source',
              id: featureid,
            },
            {
              clicked: true,
            }
          );
    
          // @ts-ignore
          const coordinates = e.features[0].geometry.coordinates.slice();
          const circumference = e.features[0].properties.circumference;
          const treeType = e.features[0].properties.treeType;
          const bucket = e.features[0].properties.bucket;
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }
    
          // Populate the popup and set its coordinates
          // based on the feature found.
          // this.popup.setLngLat(coordinates).setHTML(this.createTreeWidthHTML(circumference, coordinates,treeType ,bucket)).addTo(this.map)
          const rawData = {
            coordinates: coordinates,
            properties: e.features[0].properties
          }

          if(eventCallback){
            eventCallback(rawData)
          }
          
    }) 

    map.on('click', (e) => {
      // Check if the click was not on a feature in the specified layer
      const features = map.queryRenderedFeatures(e.point, {
          layers: ['treeMeasurementData-layer', "treeData-layer"]
      });

      if (features.length === 0) {
          // Click was outside of the layer's features
          if (featureid) {
              map.setFeatureState(
                  {
                      source: 'treeMeasurementData-source',
                      id: featureid,
                  },
                  {
                      clicked: false,
                  }
              );
              featureid = '';
              map.getCanvas().style.cursor = '';
              
              if(eventCallback){
                eventCallback(null);
              }
              
          }
      }
  });
}