import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import * as global from '../../globals';
import {AuthService} from '@auth0/auth0-angular';
import {concatMap, take} from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class DataManagementService {
  httpOptions = {
    headers: new HttpHeaders({
      'Cache-Control': 'no-cache',
    })
  };

  constructor(private http: HttpClient, private auth: AuthService) {
  }

  getDatasets(dataType: string): Observable<any> {
    return this.auth.user$
      .pipe(
        take(1),
        concatMap((user) => {
            return this.http.get<any>(global.apiUrl + global.dataset + '/manage/datasets?data_type=' + dataType, this.httpOptions);
          }
        )
      );
  }

  mergeDatasets(datasets: string[]): Observable<any> {
    return this.auth.user$
      .pipe(
        take(1),
        concatMap((user) => {
            return this.http.post<any>(global.apiUrl + global.dataset + '/manage/merge',
              {
                dataset_ids: datasets
              });
          }
        )
      );
  }

  deleteDatasetById(datasetId: number): Observable<any> {
    return this.auth.user$
      .pipe(
        take(1),
        concatMap((user) => {
            return this.http.delete<any>(global.apiUrl + global.dataset + '/manage/delete/' + datasetId);
          }
        )
      );
  }

  getDatasetFromDatasetId(datasetId: number): Observable<any> {
    return this.auth.user$
      .pipe(
        take(1),
        concatMap((user) => {
            return this.http.get<any>(global.apiUrl + global.dataset + '/from_dataset_id/' + datasetId, this.httpOptions);
          }
        )
      );
  }


}
