import { Component, Input } from '@angular/core';
import { PortalAppsService } from '../../../portal-apps.service';

@Component({
  selector: 'toggle-stm-legend-action-component',
  templateUrl: './toggle-stm-legend.component.html',
  styleUrls: ['./toggle-stm-legend.component.css']
})

export class ToggleSTMLegendActionComponent  {

    mapStrategy;
    isToggled;
    constructor(private portalAppsService: PortalAppsService){
        this.mapStrategy = this.portalAppsService.activeApp.mapStrategy;
        this.isToggled = this.mapStrategy.entityInfoComponents[2].isVisible;
    }

    toggleLegend(){
        this.mapStrategy.toggleLegend();
    }



}