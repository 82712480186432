import { Component, Input } from '@angular/core';

@Component({
  selector: 'stem-thickness-legend-component',
  templateUrl: './stem-thickness-legend.component.html',
  styleUrls: ['./stem-thickness-legend.component.css'],
  styles: [":host {margin-top: auto;}"]
})

export class StemThicknessLegend  {
    @Input() isVisible: any;
    @Input() displayData: any;
}