import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { from, EMPTY, Observable } from 'rxjs';
import { PortalAppsService } from './portal-apps.service';
import { Router } from "@angular/router";
import { IPortalApp } from './iportal-app';
import { AuthGuard } from '@auth0/auth0-angular';

@Injectable()
export class AppRouteResolver implements Resolve<IPortalApp> {
  constructor(private portalAppsService: PortalAppsService, private router: Router){}

  resolve(route: ActivatedRouteSnapshot): Observable<IPortalApp> {
    return from(this.resolveAsync(route));
  }

  private async resolveAsync(route: ActivatedRouteSnapshot): Promise<IPortalApp> {
    const appCodeName = route.paramMap.get('appCode');
    
    const availableApps = await this.portalAppsService.getAllAvaliableApps();
    const foundApp = availableApps.find(app => (app.codeName).toLowerCase() === appCodeName.toLowerCase());
    if (foundApp) {
      const pages = foundApp.pages;
      const currentRoutes = this.router.config;
      const newRoutes = [];
      pages.forEach(page => {
        const tempPage = { ...page };

        tempPage.path = 'apps/' + foundApp.codeName + '/' + tempPage.path;
        tempPage.canActivate = [AuthGuard];
        const routeExists = currentRoutes.find(existingRoute => existingRoute.path === tempPage.path);
        if (!routeExists) {
          newRoutes.push(tempPage);
        }
      });

      const updatedRoutes = [...newRoutes, ...currentRoutes];
      this.router.resetConfig(updatedRoutes);
      this.portalAppsService.setActiveApp(foundApp);
      const params = route.queryParamMap;
      const navigateLink = params.get("navigateTo");
      if(navigateLink){
        if(navigateLink.includes("?")){
          const urlObj = new URL("https://portal.agrowizard.com/" + navigateLink);    
          const params = new URLSearchParams(urlObj.search);
          const paramsObj = {};
          params.forEach((value, key) => {
            paramsObj[key] = value;
          });

          const urlToNavigate = navigateLink.split("?")[0];
          this.router.navigate(['apps/' + foundApp.codeName + '/' + urlToNavigate], {queryParams: paramsObj})
        }
        else{
          await this.router.navigate(['apps/' + foundApp.codeName + '/' + navigateLink]);
        }
        
      }
      else{
        await this.router.navigate(['apps/' + foundApp.codeName + '/' + pages[0].path]);
      }
      

      return this.portalAppsService.getAppObservableByCodeName(appCodeName).toPromise();
    } else {
      await this.router.navigate(['/apps']);
      return Promise.reject(EMPTY); 
    }
  }
}