import { Component, Input } from '@angular/core';
import { RouterLinkActive, RouterModule  } from '@angular/router';

@Component({
  selector: 'statistics-nav-item',
  templateUrl: './statistics-nav-item.component.html'
})

export class StatisticsNavItem {
    @Input() codeName: string;
}