<div class="spacing_non_map_pages">
    <div>
        <h1 class="unselectable global_title">{{'i18n.COMPANY_MANAGEMENT_PAGE.TITLE' | translate}}</h1>

        <!-- Company details -->
        <div class="content">
            <div class="section">
                <h1>{{'i18n.COMPANY_MANAGEMENT_PAGE.DETAILS.COMPANY_DETAILS' | translate}}</h1>
                <div class="global_tile item" *ngIf="organization">

                    <div class="organization_details">
                        <div>
                            <p>{{'i18n.COMPANY_MANAGEMENT_PAGE.DETAILS.COMPANY_NAME' | translate}}: </p>
                            <p>{{'i18n.COMPANY_MANAGEMENT_PAGE.DETAILS.COMPANY_REGISTERED_SINCE' | translate}}: </p>
                            <p>{{'i18n.COMPANY_MANAGEMENT_PAGE.DETAILS.COMPANY_UPDATED_ON' | translate}}: </p>
                        </div>
                        <div>
                            <p>{{organization.name}}</p>
                            <p>{{organization.created_at.slice(0, 10)}}</p>
                            <p>{{organization.updated_at.slice(0, 10)}}</p>
                        </div>

                    </div>

                </div>
            </div>

            <!-- List of user roles and permissions -->

            <div class="section item" *ngIf="roles">
                <h1>{{'i18n.COMPANY_MANAGEMENT_PAGE.ROLES.USER_ROLES' | translate}}</h1>
                <div class="global_tile">
                    <table class="role_table">
                        <tr>
                            <th>{{'i18n.COMPANY_MANAGEMENT_PAGE.ROLES.ROLE_FUNCTIONS' | translate}}</th>
                            <th class="role_permission_head" *ngFor="let role of roles">{{role.name}}</th>
                        </tr>
                        <tr *ngFor="let permission of roles[0].permissions; let i = index">
                            <td class="role_permission">{{permission.name}}<div class="info_container"
                                    *ngIf="permission.description && permission.description.length > 0"><svg xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000" version="1.1"
                                        id="Capa_1" width="800px" height="800px" viewBox="0 0 416.979 416.979"
                                        xml:space="preserve">
                                        <g>
                                            <path
                                                d="M356.004,61.156c-81.37-81.47-213.377-81.551-294.848-0.182c-81.47,81.371-81.552,213.379-0.181,294.85   c81.369,81.47,213.378,81.551,294.849,0.181C437.293,274.636,437.375,142.626,356.004,61.156z M237.6,340.786   c0,3.217-2.607,5.822-5.822,5.822h-46.576c-3.215,0-5.822-2.605-5.822-5.822V167.885c0-3.217,2.607-5.822,5.822-5.822h46.576   c3.215,0,5.822,2.604,5.822,5.822V340.786z M208.49,137.901c-18.618,0-33.766-15.146-33.766-33.765   c0-18.617,15.147-33.766,33.766-33.766c18.619,0,33.766,15.148,33.766,33.766C242.256,122.755,227.107,137.901,208.49,137.901z" />
                                        </g>
                                    </svg>
                                    <div class="role_permission_description">{{permission.description}}</div>
                                </div>
                            </td>
                            <td>✓</td>
                            <td *ngIf="checkRoles(roles[1].permissions, permission.id)">✓</td>
                            <td *ngIf="checkRoles(roles[2].permissions, permission.id)">✓</td>
                        </tr>
                    </table>
                </div>
            </div>

            <!-- List of users assigned to the organization -->
            <div class="section">
                <div class="head">
                    <h1>{{'i18n.COMPANY_MANAGEMENT_PAGE.USERS.USERS' |
                        translate}}</h1> <button class="global_btn add_user_btn"
                        (click)="newUserModal = !newUserModal">{{'i18n.COMPANY_MANAGEMENT_PAGE.USERS.ADD_USER' |
                        translate}}</button>
                </div>

                <div class="member_container">
                    <div class="global_tile member_card" *ngFor="let member of members">
                        <div class="member_icon">
                            <svg _ngcontent-ehx-c215="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                class="icon">
                                <defs _ngcontent-ehx-c215=""></defs>
                                <path _ngcontent-ehx-c215="" id="Vector"
                                    d="M75,587a7,7,0,1,1-7-7A7,7,0,0,1,75,587Zm-1.6,7.18a8.93,8.93,0,0,1-10.809,0C58.521,595.972,56,601.555,56,604H80C80,601.577,77.4,595.994,73.4,594.181Z"
                                    transform="translate(-56 -580)" class="svg-icon"></path>
                            </svg>
                        </div>
                        <div class="global_devider_vertical"></div>
                        <div class="member_details">
                            <p>{{'i18n.COMPANY_MANAGEMENT_PAGE.USERS.USER_NAME' | translate}}: <b>{{member.first_name}}
                                    {{member.last_name}}</b></p>
                            <p>{{'i18n.COMPANY_MANAGEMENT_PAGE.USERS.USER_ROLE' | translate}}: <select class="role_select"
                                    (change)="changeMemberRole(member.id, $event.target.value)">
                                    <option *ngFor="let role of roles" value="{{role.id}}"
                                        [selected]="role.name == member.role.name">
                                        {{role.name}}</option>
                                </select></p>
                            <p>{{'i18n.COMPANY_MANAGEMENT_PAGE.USERS.USER_ACTIVE_SINCE' | translate}}:
                                <b>{{member.created_at.slice(0, 10)}}</b>
                            </p>
                        </div>
                        <div class="global-option-icon unselectable" [matMenuTriggerFor]="menu"
                            (click)="$event.stopPropagation()">
                            <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" focusable="false"
                                style="pointer-events: none; display: block; width: 100%; height: 100%;">
                                <g>
                                    <path
                                        d="M12,16.5c0.83,0,1.5,0.67,1.5,1.5s-0.67,1.5-1.5,1.5s-1.5-0.67-1.5-1.5S11.17,16.5,12,16.5z M10.5,12 c0,0.83,0.67,1.5,1.5,1.5s1.5-0.67,1.5-1.5s-0.67-1.5-1.5-1.5S10.5,11.17,10.5,12z M10.5,6c0,0.83,0.67,1.5,1.5,1.5 s1.5-0.67,1.5-1.5S12.83,4.5,12,4.5S10.5,5.17,10.5,6z">
                                    </path>
                                </g>
                            </svg>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="deleteMember(member.id)">
                                    <mat-icon>delete</mat-icon>
                                    <ng-container>{{'i18n.COMPANY_MANAGEMENT_PAGE.USERS.USER_DELETE' |
                                        translate}}</ng-container>
                                </button>
                            </mat-menu>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="selectAppPopupOpen" class="choose-app-popup-background" (click)="closeAppSelectorPopup($event)">
                <div class="choose-app-popup">
                    <h1>{{'i18n.COMPANY_MANAGEMENT_PAGE.FIELDS.SELECT_APP' | translate}}</h1>
                    <div [ngClass]="{'active' : appOptionsVisible}" class="app-selector">
                        <div class="app-input">
                            <div (click)="this.appOptionsVisible = true" *ngIf="this.selectedApp != null" class="selected-app-box">{{selectedApp.name | translate}}</div>
                            <input [(ngModel)]="searchAppInput" (ngModelChange)="filterAppSelectionOptions($event)" *ngIf="this.selectedApp == null" (focus)="this.appOptionsVisible = true" (blur)="this.appOptionsVisible = false" type="text"/>
                            <div class="icon">
                                <svg *ngIf="this.selectedApp == null" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
                                <svg style="cursor: pointer;" (click)="this.selectedApp = null" *ngIf="this.selectedApp != null" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
                            </div>
                        </div>
                        <div class="app-drop-down">
                            <div (click)="this.selectedApp = app; this.appOptionsVisible = false;" *ngFor="let app of filteredApps" class="app-option">
                                {{app.name | translate}}
                            </div>
                        </div>
                    </div>
                    <button *ngIf="this.selectedApp != null" (click)="navigateToTheAppAddField(this.selectedApp)">
                        Next
                    </button>
                </div>
            </div>

            <!-- list of fields archifed and non archiefed -->
            <div class="section">
                <div class="head">
                    <h1>{{'i18n.COMPANY_MANAGEMENT_PAGE.FIELDS.FIELDS' | translate}}</h1>

                    <button class="global_btn add_user_btn add_field_btn" 
                       (click)="addFieldClick()">{{'i18n.COMPANY_MANAGEMENT_PAGE.FIELDS.FIELD_ADD' | translate}}</button>
                </div>
                <input type="text" class="global_input_field search_tree_inp"
                    placeholder="{{'i18n.COMPANY_MANAGEMENT_PAGE.FIELDS.FIELD_SEARCH' | translate}}..."
                    (keyup)='update_search_fields($event)'>

                <div class="item global_tile ">
                    <table class="table_users">
                        <thead class="table_head">
                            <th>{{'i18n.COMPANY_MANAGEMENT_PAGE.FIELDS.FIELD_NAME' | translate}}</th>
                            <th>{{'i18n.COMPANY_MANAGEMENT_PAGE.FIELDS.FIELD_ARCHIVED' | translate}}</th>
                            <th>{{'i18n.COMPANY_MANAGEMENT_PAGE.FIELDS.FIELD_LAST_MEASURED' | translate}}</th>
                            <th></th>
                        </thead>
                        <tbody class="table_body">

                            <tr id="hello1" class="table_row" *ngFor="let field of fields;">
                                <td><input class="tree_type" value="{{field.name}}" disabled></td>
                                <td>{{field.archived}}</td>
                                <td *ngIf="field.available_dates">{{field.available_dates[0]}}</td>
                                <td *ngIf="!field.available_dates">N/A</td>
                                <td class="edit_buttons" *ngIf="!field.archived"><button (click)="archiveField(field.id)"
                                        class="global_btn">{{'i18n.GLOBAL.ARCHIVE'
                                        | translate}}</button></td>
                                <td class="edit_buttons" *ngIf="field.archived"><button (click)="unarchiveField(field.id)"
                                        class="global_btn">De-{{'i18n.GLOBAL.ARCHIVE'
                                        | translate}}</button></td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="page_navigation">
                        <p>{{'i18n.COMPANY_MANAGEMENT_PAGE.PAGINATION.PAGE_ROWS' | translate}}:</p>
                        <select id="cars" name="cars" #ppFields (change)="changeAmountFields(ppFields.value)">
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="15">25</option>
                            <option value="100">100</option>
                            <option value="250">250</option>
                            <option value="100000">{{'i18n.COMPANY_MANAGEMENT_PAGE.PAGINATION.PAGE_ALL' | translate}}</option>
                        </select>
                        <p>{{(currentPageFields -1) * nrPerPageFields +1}}-{{(currentPageFields -1) * nrPerPageFields +
                            fields.length}} {{'i18n.COMPANY_MANAGEMENT_PAGE.PAGINATION.PAGE_RANGE' | translate}}
                            {{fieldsFiltered.length}}</p>
                        <button class="page_nav_btn" (click)="setPageFields(1)"><svg viewBox="0 0 24 24"
                                preserveAspectRatio="xMidYMid meet" focusable="false"
                                style="pointer-events: none; display: block; width: 100%; height: 100%;">
                                <g>
                                    <path
                                        d="M7,6H6v12h1V6z M17.35,17.65L11.71,12l5.65-5.65l0.71,0.71L13.12,12l4.94,4.94L17.35,17.65z">
                                    </path>
                                </g>
                            </svg></button>
                        <button class="page_nav_btn" (click)="changeFieldsPage(-1)"><svg viewBox="0 0 24 24"
                                preserveAspectRatio="xMidYMid meet" focusable="false"
                                style="pointer-events: none; display: block; width: 100%; height: 100%;">
                                <g>
                                    <path d="M14.6,18.4L8.3,12l6.4-6.4l0.7,0.7L9.7,12l5.6,5.6L14.6,18.4z"></path>
                                </g>
                            </svg></button>
                        <button class="page_nav_btn" (click)="changeFieldsPage(1)"><svg viewBox="0 0 24 24"
                                preserveAspectRatio="xMidYMid meet" focusable="false"
                                style="pointer-events: none; display: block; width: 100%; height: 100%;">
                                <g>
                                    <path d="M9.4,18.4l-0.7-0.7l5.6-5.6L8.6,6.4l0.7-0.7l6.4,6.4L9.4,18.4z"></path>
                                </g>
                            </svg></button>
                        <button class="page_nav_btn" (click)="setPageFields(totalPagesFields)"><svg viewBox="0 0 24 24"
                                preserveAspectRatio="xMidYMid meet" focusable="false"
                                style="pointer-events: none; display: block; width: 100%; height: 100%;">
                                <g>
                                    <path
                                        d="M18,18h-1V6h1V18z M5.65,7.06L10.59,12l-4.94,4.94l0.71,0.71L12,12L6.36,6.35L5.65,7.06z">
                                    </path>
                                </g>
                            </svg></button>
                    </div>
                </div>
            </div>

            <!-- list of tree types for this customer -->
            <div class="section">
                <div class="head">
                    <h1>{{'i18n.COMPANY_MANAGEMENT_PAGE.TREE_TYPES.TREE_TYPES' | translate}}</h1>

                    <button class="global_btn add_user_btn"
                        (click)="newTreeTypeModal = !newTreeTypeModal">{{'i18n.COMPANY_MANAGEMENT_PAGE.TREE_TYPES.TREE_ADD_TYPE' |
                        translate}}</button>
                </div>
                <input type="text" class="global_input_field search_tree_inp" placeholder="Boomsoort zoeken..."
                    (keyup)='update_search_tree_type($event)'>

                <div class="item global_tile ">
                    <table class="table_users">
                        <thead class="table_head">
                            <th (click)="sort($event)">{{'i18n.COMPANY_MANAGEMENT_PAGE.TREE_TYPES.TREE_TYPE_NAME' | translate}}
                            </th>
                            <th></th>
                        </thead>
                        <tbody class="table_body">
                            <tr id="hello1" class="table_row" *ngFor="let treeType of treeTypes;">
                                <td><input class="tree_type" value="{{treeType.name}}" disabled></td>
                                <td class="edit_buttons"><button class="global_btn edit_tree_type_btn"
                                        (click)="editTreeType($event, treeType.id)">{{'i18n.COMPANY_MANAGEMENT_PAGE.USERS.USER_EDIT'
                                        | translate}}</button></td>
                                <td class="edit_buttons"><button class="global_btn edit_tree_type_btn"
                                        (click)="deleteTreeType(treeType.id)">{{'i18n.GLOBAL.DELETE'
                                        | translate}}</button></td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="page_navigation">
                        <p>{{'i18n.COMPANY_MANAGEMENT_PAGE.PAGINATION.PAGE_ROWS' | translate}}:</p>
                        <select id="cars" name="cars" #ppTreeType (change)="changeAmountTreeType(ppTreeType.value)">
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="15">25</option>
                            <option value="100">100</option>
                            <option value="250">250</option>
                            <option value="100000">{{'i18n.COMPANY_MANAGEMENT_PAGE.PAGINATION.PAGE_ALL' | translate}}</option>
                        </select>
                        <p>{{(currentPageTreeType -1) * nrPerPageTreeType +1}}-{{(currentPageTreeType -1) *
                            nrPerPageTreeType + treeTypes.length}} {{'i18n.COMPANY_MANAGEMENT_PAGE.PAGINATION.PAGE_RANGE' |
                            translate}} {{treeTypesFiltered.length}}</p>
                        <button class="page_nav_btn" (click)="setPageTreeType(1)"><svg viewBox="0 0 24 24"
                                preserveAspectRatio="xMidYMid meet" focusable="false"
                                style="pointer-events: none; display: block; width: 100%; height: 100%;">
                                <g>
                                    <path
                                        d="M7,6H6v12h1V6z M17.35,17.65L11.71,12l5.65-5.65l0.71,0.71L13.12,12l4.94,4.94L17.35,17.65z">
                                    </path>
                                </g>
                            </svg></button>
                        <button class="page_nav_btn" (click)="changeTreeTypePage(-1)"><svg viewBox="0 0 24 24"
                                preserveAspectRatio="xMidYMid meet" focusable="false"
                                style="pointer-events: none; display: block; width: 100%; height: 100%;">
                                <g>
                                    <path d="M14.6,18.4L8.3,12l6.4-6.4l0.7,0.7L9.7,12l5.6,5.6L14.6,18.4z"></path>
                                </g>
                            </svg></button>
                        <button class="page_nav_btn" (click)="changeTreeTypePage(1)"><svg viewBox="0 0 24 24"
                                preserveAspectRatio="xMidYMid meet" focusable="false"
                                style="pointer-events: none; display: block; width: 100%; height: 100%;">
                                <g>
                                    <path d="M9.4,18.4l-0.7-0.7l5.6-5.6L8.6,6.4l0.7-0.7l6.4,6.4L9.4,18.4z"></path>
                                </g>
                            </svg></button>
                        <button class="page_nav_btn" (click)="setPageTreeType(totalPagesTreeType)"><svg
                                viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" focusable="false"
                                style="pointer-events: none; display: block; width: 100%; height: 100%;">
                                <g>
                                    <path
                                        d="M18,18h-1V6h1V18z M5.65,7.06L10.59,12l-4.94,4.94l0.71,0.71L12,12L6.36,6.35L5.65,7.06z">
                                    </path>
                                </g>
                            </svg></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal_container" *ngIf="newUserModal">
    <div class="global_tile new_member_modal">
        <div>
            <input #new_member_email type="email" class="global_input_field"
                placeholder="{{'i18n.COMPANY_MANAGEMENT_PAGE.USERS.USER_MAIL' | translate}}" required>
            <select #new_member_role>
                <option *ngFor="let role of roles" value={{role.id}}>{{role.name}}</option>
            </select>
        </div>

        <div>
            <button class="global_btn add_user_btn"
                (click)="addMember(new_member_email.value, new_member_role.value)">{{'i18n.COMPANY_MANAGEMENT_PAGE.USERS.INVITE_ADD'
                | translate}}</button>
            <button class="global_btn add_user_btn cancel"
                (click)="newUserModal = !newUserModal; email_invalid = false; invite_success = false;">{{'i18n.GLOBAL.CANCEL'
                | translate}}</button>
        </div>
        <p class="global_error_msg" *ngIf="invite_error">{{invite_error}}</p>
        <p class="global_error_msg" *ngIf="email_invalid">{{'i18n.COMPANY_MANAGEMENT_PAGE.USERS.INVITE_EMAIL_ERROR' |
            translate}}</p>
        <p class="global_success_msg" *ngIf="invite_success">{{'i18n.COMPANY_MANAGEMENT_PAGE.USERS.INVITE_SUCCESS' |
            translate}}</p>
    </div>
</div>

<div class="modal_container" *ngIf="newTreeTypeModal">
    <div class="global_tile new_member_modal">
        <div>
            <input #new_tree_type type="text" class="global_input_field"
                placeholder="{{'i18n.COMPANY_MANAGEMENT_PAGE.TREE_TYPES.TREE_TYPE' | translate}}">
        </div>

        <div>
            <button class="global_btn add_user_btn"
                (click)="addTreeType(new_tree_type.value)">{{'i18n.COMPANY_MANAGEMENT_PAGE.TREE_TYPES.TREE_ADD_TYPE' |
                translate}}</button>
            <button class="global_btn add_user_btn cancel"
                (click)="newTreeTypeModal = !newTreeTypeModal">{{'i18n.GLOBAL.CANCEL' |
                translate}}</button>
        </div>

    </div>
</div>
