<div class="spacing_non_map_pages">
    <div class="applications-page">
        <header>
            <h1 id="menu-text" class="unselectable">{{'i18n.MENU.ITEMS.APPS' | translate}}</h1>
        </header>
        <div class="content">
            <ul class="apps-list">
                <li *ngFor="let app of apps; index as i;" (click)="onAppSelect(app)">
                    <div class="image-container">
                        <img alt="app icon" src="{{app.iconPath}}" />
                    </div>
                    <div class="name">
                        <span>
                            {{app.name  | translate}}
                        </span>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>

