<div fxLayout="column" fxLayoutAlign="center center" fxFlexFill class="back-div">
    <mat-card class="card">
        <mat-card-content>
            <form class="col s12" [formGroup]="changePasswordForm" (submit)="changePassword()" fxLayoutAlign="stretch" fxLayout="column">
                <app-logo class="logo" [Size]="logoSize"></app-logo>

                <mat-form-field class="item input-field">
                    <mat-label>Current password</mat-label>
                    <input matInput type="password" class="form-control" formControlName="currentPassword" placeholder="Current password"  >
                </mat-form-field>
                <div *ngIf="currentPassword.invalid && currentPassword.touched">
                    <mat-error *ngIf="currentPassword.errors?.required ">
                        Current password is required.
                    </mat-error>
                    <mat-error *ngIf="currentPassword.errors.minlength">
                        Password must be at least 12 characters long.
                    </mat-error>
                    <mat-error *ngIf="currentPassword.errors.maxlength">
                        Password must be maximum 50 characters long.
                    </mat-error>
                </div>


                <mat-form-field class="item input-field">
                    <mat-label>New password</mat-label>
                    <input matInput type="password" class="form-control" formControlName="password" placeholder="New password"  >
                </mat-form-field>
                <div *ngIf="password.invalid && password.touched">
                    <mat-error *ngIf="password.errors?.required ">
                        Password is required.
                    </mat-error>
                    <mat-error *ngIf="password.errors.minlength">
                        Password must be at least 12 characters long.
                    </mat-error>
                    <mat-error *ngIf="password.errors.maxlength">
                        Password must be maximum 50 characters long.
                    </mat-error>
                </div>




                <mat-form-field class="item input-field">
                    <mat-label>Confirm new password</mat-label>
                    <input matInput type="password" class="form-control" formControlName="confirmPassword" placeholder="Confirm new password">


                </mat-form-field>

                <div *ngIf="confirmPassword.invalid && confirmPassword.touched">
                    <mat-error *ngIf="confirmPassword.errors?.required ">
                        Confirm password is required
                    </mat-error>
                    <mat-error *ngIf="confirmPassword.errors.minlength">
                        Confirm password must be at least 12 characters long.
                    </mat-error>
                    <mat-error *ngIf="confirmPassword.errors.maxlength">
                        Confirm password must be maximum 50 characters long.
                    </mat-error>

                </div>


                <mat-error *ngIf="changePasswordForm.hasError('notSame')">
                    There is no match between the passwords
                </mat-error>
              <!--
                <mat-error  class="center text" *ngIf="getPasswordStatusError$ | async as error"> {{error}}</mat-error>
               -->




               <a class="login-Btn"  *ngIf="passwordChanged&& passwordChanged !== undefined" > Your password is changed!</a>
            </form>
        </mat-card-content>
    </mat-card>
    <app-button class="btn"  [isDisabled]="changePasswordForm.invalid" [label]="changePasswordLabel" [buttonType]="singUpBtnClass" (onClick)="functioncall($event)" ></app-button>
</div>
