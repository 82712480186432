<div *ngIf="this.isVisible.value" class="legend_container">
    <div class="smalldesc">
        <h3>{{'i18n.FIELD_PAGE.TREE_WIDTH' | translate}}</h3>
        <div class="legend">
            <div class="legend_item" fxLayout="row" fxLayoutGap="10px">
                <svg style="height: 12px; width: 14px;" id="eJAIm6vMlbZ1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 52 52"
                    shape-rendering="geometricPrecision" text-rendering="geometricPrecision">
                    <ellipse rx="25" ry="25" transform="translate(26 26)" fill="#FFEB01" stroke="#000"
                        stroke-width="1" />
                </svg>
                <p class="value">8-10, 16-18, 30-35, 50-60</p>
            </div>
            <div class="legend_item" fxLayout="row" fxLayoutGap="10px">
                <svg style="height: 12px; width: 14px; " id="eJAIm6vMlbZ1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 52 52"
                    shape-rendering="geometricPrecision" text-rendering="geometricPrecision">
                    <ellipse rx="25" ry="25" transform="translate(26 26)" fill="#ED2938" stroke="#000"
                        stroke-width="1" />
                </svg>
                <p class="value">10-12, 18-20, 35-40, 60-70</p>
            </div>
            <div class="legend_item" fxLayout="row" fxLayoutGap="10px">
                <svg style="height: 12px; width: 14px;" id="eJAIm6vMlbZ1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 52 52"
                    shape-rendering="geometricPrecision" text-rendering="geometricPrecision">
                    <ellipse rx="25" ry="25" transform="translate(26 26)" fill="#FFFFFF" stroke="#000"
                        stroke-width="1" />
                </svg>
                <p class="value">12-14, 20-25, 40-45, 70-80</p>
            </div>
            <div class="legend_item" fxLayout="row" fxLayoutGap="10px">
                <svg style="height: 12px; width: 14px;" id="eJAIm6vMlbZ1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 52 52"
                    shape-rendering="geometricPrecision" text-rendering="geometricPrecision">
                    <ellipse rx="25" ry="25" transform="translate(26 26)" fill="#0088DD" stroke="#000"
                        stroke-width="1" />
                </svg>
                <p class="value">14-16, 25-30, 45-50, 80-90</p>
            </div>
        </div>
    </div>

</div>