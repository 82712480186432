import { Component, Input } from '@angular/core';
import { PortalAppsService } from '../../../portal-apps.service';

@Component({
  selector: 'stm-tree-point-creation-component',
  templateUrl: './stm-tree-point-creation.component.html',
  styleUrls: ['./stm-tree-point-creation.component.css']
})

export class StmTreePointCreationComponent  {

    mapStrategy;
    constructor(private portalAppsService: PortalAppsService){
        this.mapStrategy = this.portalAppsService.activeApp.mapStrategy;
    }


    handlePointCreationClick(){
        this.mapStrategy.hanldeChangePointCreationMode("tree-creation");
    }
}