export class GetBrpZonesViewModel {

  constructor(bbox: number[][], token: string) {
    this.bbox = bbox;
    this.token = token;
  }

  bbox: number[][];
  token: string;
}
