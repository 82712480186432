import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MapFacade } from '../../dashboard/map/MapFacade';
import { AnyLayer, AnySourceData, LngLatLike, Map } from 'mapbox-gl';
import { FieldService } from '../../services/field.service';
import { ActivatedRoute, Router, NavigationEnd, RouterEvent } from '@angular/router';
import { FieldMapLayerService } from '../../services/field-map-layer.service';
import { DatasetService } from '../../services/dataset.service';
import { MatCalendar, MatCalendarCellCssClasses, MatDatepicker } from '@angular/material/datepicker';
import moment from 'moment';
import { IField } from 'src/app/models/field.model';
import { AuthService } from '@auth0/auth0-angular';
import mapboxgl from 'mapbox-gl';
import { delay } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { DOCUMENT } from '@angular/common';
import { RoutingService } from 'src/app/routing.service';


@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {

  constructor(private router: Router,
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private api: ApiService,
    @Inject(DOCUMENT) private doc: Document,
    private datasetService: DatasetService,
    private auth: AuthService) {
  }

  isLoading = true;
  isReady = false;

  lastUnmatchedUrl: string = '';

  ngOnInit(): void {
    
  }



  ngAfterViewInit() {
    this.checkForOrg()
  }



  async checkForOrg() {
    this.getOrgId()

    // this.auth.isAuthenticated$.subscribe(d => {
    //   if(!d){
    //     this.auth.loginWithRedirect();
    //   }
    // })
    while (true) {
      let orgIds = localStorage.getItem('org_id');
      if (orgIds) {
        if(this.router.url.includes("/apps/")){
          this.routingService.handleUnmatchedRoute(this.router.url);
        }
        
        break
      }
      if (this.isReady) {
        this.isLoading = false;
      }
      await new Promise(resolve => setTimeout(resolve, 200))
    }
    if(!this.router.url.includes("/apps/")){
      this.router.navigateByUrl('/apps');
    }
    
  }

  getOrgId(): void {

    if (!localStorage.getItem('org_id')) {
      this.api.get_with_auth_without_headers(`/user/organizations`).subscribe(d => {
        if (d.collection.length > 0) {
          console.log("received", d.collection)
          localStorage.setItem('org_id', JSON.stringify(d.collection))
        }
        this.isReady = true
      });
    }
  }

  logout() {
    localStorage.removeItem('org_id');
    this.auth.logout({ 
      logoutParams: {
        returnTo: this.doc.location.origin
      }
    });
  }
}
