import {Inject, Injectable, Input} from '@angular/core';
import mapboxgl, {Map} from 'mapbox-gl';
import {DataManagementService} from './datamanagement.service';

@Injectable({
  providedIn: 'root'
})
export class DataManagementMapLayerService {
  fieldZone;
  popup: mapboxgl.Popup;
  enableTreeWidth: boolean;
  // enableSoilScan: boolean;
  enableBrpZones: boolean;
  enableSatellite: boolean;
  enableFieldZone: boolean;
  bbox: number[][];
  zIndex1;
  zIndex2;
  zIndex3;
  zIndex4;
  loadedDataSets = [];
  white = '#FFFFFF';
  blue = '#0088DD';
  yellow = '#FFEB01';
  red = '#ED2938';

  constructor(private map: Map, private datasetService: DataManagementService) {
    this.setZIndexes();
    this.setLayers();

  }

  setZIndexes() {
    this.map.addSource('empty', {
      type: 'geojson',
      data: {type: 'FeatureCollection', features: []}
    });
    this.zIndex1 = this.map.addLayer({
      id: 'z-index-1',
      type: 'symbol',
      source: 'empty'
    }); // place this layer on top

    this.zIndex2 = this.map.addLayer({
      id: 'z-index-2',
      type: 'symbol',
      source: 'empty'
    }, 'z-index-1'); // place this layer below zIndex1

    this.zIndex3 = this.map.addLayer({
      id: 'z-index-3',
      type: 'symbol',
      source: 'empty'
    }, 'z-index-2'); // place this layer below zIndex2

    this.zIndex4 = this.map.addLayer({
      id: 'z-index-4',
      type: 'symbol',
      source: 'empty'
    }, 'z-index-3'); // place this layer below zIndex3
  }

  setLayers() {
    // set satellite layer
    this.map.addSource(
      'mapbox-satellite', {
        type: 'raster',
        url: 'mapbox://mapbox.satellite',
        tileSize: 256
      });
    this.map.addLayer(
      {
        type: 'raster',
        id: 'satellite',
        source: 'mapbox-satellite'
      }, 'z-index-4');
    this.map.setLayoutProperty('satellite', 'visibility', 'none');
  }

  updateDataset(dataType: string, datasetId: number, center: number[]) {
    // set tree width layer
    // if (this.map.getSource('soil_scan')) {
    //   this.map.removeLayer('soil_scan');
    //   this.map.removeSource('soil_scan');
    // }
    if (this.map.getSource('tree_width')) {
      this.map.removeLayer('tree_width');
      this.map.removeSource('tree_width');
    }

    this.flyToPoint(center);

    if (dataType === 'tree_width') {
      this.datasetService.getDatasetFromDatasetId(datasetId).subscribe((res) => {
          if (res !== undefined) {
            if (res.dataset.length > 0) {
              // removes existing layer to make room for layer with new data
              if (this.map.getSource('tree_width')) {
                this.map.removeLayer('tree_width');
                this.map.removeSource('tree_width');
              }
              // Add a geojson point source.
              this.map.addSource('tree_width', {
                type: 'geojson',
                data: {
                  type: 'FeatureCollection',
                  features: res.dataset
                }
              });
              this.map.addLayer({
                  id: 'tree_width',
                  type: 'circle',
                  source: 'tree_width',
                  paint: {
                    'circle-radius': [
                      'case',
                      ['<', ['get', 'width'], 6],
                      5,
                      ['all', ['>=', ['get', 'width'], 6], ['<', ['get', 'width'], 8]],
                      5,
                      ['all', ['>=', ['get', 'width'], 8], ['<', ['get', 'width'], 10]],
                      5,
                      ['all', ['>=', ['get', 'width'], 10], ['<', ['get', 'width'], 12]],
                      5,
                      ['all', ['>=', ['get', 'width'], 12], ['<', ['get', 'width'], 14]],
                      6,
                      ['all', ['>=', ['get', 'width'], 14], ['<', ['get', 'width'], 16]],
                      6,
                      ['all', ['>=', ['get', 'width'], 16], ['<', ['get', 'width'], 18]],
                      6,
                      ['all', ['>=', ['get', 'width'], 18], ['<', ['get', 'width'], 20]],
                      6,
                      7
                    ],
                    'circle-color': [
                      'case',
                      ['<', ['get', 'width'], 6],
                      this.white,
                      ['all', ['>=', ['get', 'width'], 6], ['<', ['get', 'width'], 8]],
                      this.blue,
                      ['all', ['>=', ['get', 'width'], 8], ['<', ['get', 'width'], 10]],
                      this.yellow,
                      ['all', ['>=', ['get', 'width'], 10], ['<', ['get', 'width'], 12]],
                      this.red,
                      ['all', ['>=', ['get', 'width'], 12], ['<', ['get', 'width'], 14]],
                      this.white,
                      ['all', ['>=', ['get', 'width'], 14], ['<', ['get', 'width'], 16]],
                      this.blue,
                      ['all', ['>=', ['get', 'width'], 16], ['<', ['get', 'width'], 18]],
                      this.yellow,
                      ['all', ['>=', ['get', 'width'], 18], ['<', ['get', 'width'], 20]],
                      this.red,
                      ['all', ['>=', ['get', 'width'], 20], ['<', ['get', 'width'], 25]],
                      this.white,
                      ['all', ['>=', ['get', 'width'], 25], ['<', ['get', 'width'], 30]],
                      this.blue,
                      ['all', ['>=', ['get', 'width'], 30], ['<', ['get', 'width'], 35]],
                      this.yellow,
                      ['all', ['>=', ['get', 'width'], 35], ['<', ['get', 'width'], 40]],
                      this.red,
                      this.white
                    ]
                  }
                }
                , 'z-index-2');
            }
            if (!this.enableTreeWidth) {
              this.setVisibility('tree_width', this.enableTreeWidth);
            }
          }
        }
      );
      this.popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false
      });

      this.map.on('mouseenter', 'tree_width', (e) => {
        this.map.getCanvas().style.cursor = 'pointer';

        // @ts-ignore
        const coordinates = e.features[0].geometry.coordinates.slice();
        const width = e.features[0].properties.width;

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        // Populate the popup and set its coordinates
        // based on the feature found.
        this.popup.setLngLat(coordinates).setHTML(this.createTreeWidthHTML(width, coordinates)).addTo(this.map);
      });

      this.map.on('mouseleave', 'tree_width', () => {
        this.map.getCanvas().style.cursor = '';
        this.popup.remove();
      });
    }

    // set soil scan layer
    // if (dataType === 'soil_scan') {
    //   this.datasetService.getDatasetFromDatasetId(datasetId).subscribe((res) => {
    //       if (res !== undefined) {
    //         if (res.dataset.length > 0) {

    //           // if (this.map.getSource('soil_scan')) {
    //           //   this.map.removeLayer('soil_scan');
    //           //   this.map.removeSource('soil_scan');
    //           // }

    //           // this.map.addSource('soil_scan', {
    //           //   type: 'geojson',
    //           //   data: {
    //           //     type: 'FeatureCollection',
    //           //     features: res.dataset
    //           //   }
    //           // });
    //           // this.map.addLayer({
    //           //   id: 'soil_scan',
    //           //   type: 'fill',
    //           //   source: 'soil_scan',
    //           //   layout: {},
    //           //   paint: {
    //           //     'fill-color': '#c62424',
    //           //     'fill-opacity': 0.4
    //           //   }
    //           // }, 'z-index-1');
    //         }
    //         if (!this.enableBrpZones) {
    //           this.setVisibility('brp', this.enableBrpZones);
    //         }
    //       }
    //     }
    //   );
    // }
  }

  setVisibility(layer: string, visibility: boolean) {
    if (this.map.getLayer(layer) !== undefined) {
      if (visibility) {
        this.map.setLayoutProperty(layer, 'visibility', 'visible');
      } else {
        this.map.setLayoutProperty(layer, 'visibility', 'none');
      }
    }
  }

  createTreeWidthHTML(width: number, coordindates: number[]) {
    return '<p>Tree Width: ' + (Math.round(width * 100) / 100).toString() + 'cm.</p>' +
      '<p>Location: ' + coordindates[0] + ', ' + coordindates[1] + '</p>';
  }

  flyToPoint(coordinates: number[]) {
    this.map.flyTo({
      center: [
        coordinates[0],
        coordinates[1]
      ],
      essential: true // this animation is considered essential with respect to prefers-reduced-motion
    });
  }

  updateVisibility(layer: string, checked: boolean) {
    switch (layer) {
      case 'satellite': {
        this.enableSatellite = checked;
        this.setVisibility(layer, this.enableSatellite);
        break;
      }
    }
  }

  updateDatasetLayer(datasetId: string, checked: boolean) {
    const datasetStatus = this.loadedDataSets.find(e => e.id === datasetId);
    if (this.map.getLayer(datasetId) !== undefined) {
      if (!checked) {
        for (const d of this.loadedDataSets) {
          if (d.id === datasetId) {
            d.status = !checked;
            break;
          }
        }
        this.map.setLayoutProperty(datasetId, 'visibility', 'none');
      } else {
        for (const d of this.loadedDataSets) {
          if (d.id === datasetId) {
            d.status = checked;
            this.map.fitBounds([[d.bounds.xMin, d.bounds.yMin], [d.bounds.xMax, d.bounds.yMax]]);
            break;
          }
        }
        this.map.setLayoutProperty(datasetId, 'visibility', 'visible');

      }
    } else {
      this.datasetService.getDatasetFromDatasetId(Number(datasetId)).subscribe((res) => {
          const boundingBox = this.getBoundingBox(res.dataset);
          this.loadedDataSets.push({
            id: datasetId,
            status: checked,
            bounds: boundingBox
          });
          this.map.fitBounds([[boundingBox.xMin, boundingBox.yMin], [boundingBox.xMax, boundingBox.yMax]]);

          if (res !== undefined) {
            if (res.dataset.length > 0) {
              // removes existing layer to make room for layer with new data
              if (this.map.getSource(datasetId)) {
                this.map.removeLayer(datasetId);
                this.map.removeSource(datasetId);
              }
              // Add a geojson point source.
              this.map.addSource(datasetId, {
                type: 'geojson',
                data: {
                  type: 'FeatureCollection',
                  features: res.dataset
                }
              });
              this.map.addLayer({
                id: datasetId,
                type: 'circle',
                source: datasetId,
                paint: {
                  'circle-radius': 6,
                  'circle-color': {
                    property: 'width',
                    stops: [
                      [0, '#0000FF'],
                      [750, '#00FFFF'],
                      [1500, '#00FF00'],
                      [2250, '#FFFF00'],
                      [3000, '#FF0000']
                    ]
                  }
                }
              });
            }
          }
          this.popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false
          });

          this.map.on('mouseenter', datasetId, (e) => {
            this.map.getCanvas().style.cursor = 'pointer';

            // @ts-ignore
            const coordinates = e.features[0].geometry.coordinates.slice();
            const width = e.features[0].properties.width;

            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
              coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }

            // Populate the popup and set its coordinates
            // based on the feature found.
            this.popup.setLngLat(coordinates).setHTML(this.createTreeWidthHTML(width, coordinates)).addTo(this.map);
          });

          this.map.on('mouseleave', datasetId, () => {
            this.map.getCanvas().style.cursor = '';
            this.popup.remove();
          });
        }
      );
    }
  }

  getBoundingBox(data) {
    const bounds = {
      xMin: undefined,
      xMax: undefined,
      yMin: undefined,
      yMax: undefined,
    };
    let latitude;
    let longitude;

    for (const feature of data) {
      const point = feature.geometry.coordinates;
      longitude = point[0];
      latitude = point[1];
      bounds.xMin = bounds.xMin < longitude ? bounds.xMin : longitude;
      bounds.xMax = bounds.xMax > longitude ? bounds.xMax : longitude;
      bounds.yMin = bounds.yMin < latitude ? bounds.yMin : latitude;
      bounds.yMax = bounds.yMax > latitude ? bounds.yMax : latitude;
    }

    return bounds;
  }

  getActiveLayersId() {
    const activeLayers = [];
    for (const d of this.loadedDataSets) {
      if (d.status) {
        activeLayers.push(Number(d.id));
      }
    }
    return activeLayers;
  }
}
