import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LngLatLike, Map } from 'mapbox-gl';
import { DataManagementMapLayerService } from '../../services/data-management-map-layer.service';
import { DataManagementService } from '../../services/datamanagement.service';

@Component({
  selector: 'app-data-management-page',
  templateUrl: './data-management-page.component.html',
  styleUrls: ['./data-management-page.component.css']
})
export class DataManagementPageComponent implements OnInit {
  map: Map;
  num = 15;
  center: LngLatLike;
  layers: DataManagementMapLayerService;
  datasets = [
    { date: 'test' },
    { date: 'test' },
    { date: 'test' },
  ];
  treeWidthDatasets = [];
  // soilScanDatasets = [];

  activeDatasets = [];

  ngOnInit(): void {
    this.center = [6.02296544, 51.30270044];
  }

  constructor(private http: HttpClient, private dataManagementService: DataManagementService) {
    this.dataManagementService.getDatasets('tree_width').subscribe(res => {
      this.treeWidthDatasets = res.datasets;
    });
  }

  loadMap() {
    this.layers = new DataManagementMapLayerService(this.map, this.dataManagementService);
  }

  layerVisibility(event: any, layer: string) {
    this.layers.updateVisibility(layer, event.checked);
  }

  updateDatasetLayer(event: any, datasetId: number) {
    const dId = datasetId.toString();
    this.layers.updateDatasetLayer(dId, event.checked);
    if (event.checked) {
      this.activeDatasets.push(datasetId)
    } else {
      const index = this.activeDatasets.indexOf(datasetId, 0);
      if (index > -1) {
        this.activeDatasets.splice(index, 1);
      }
    }
  }

  mergeDataSets() {
    const activeLayers = this.layers.getActiveLayersId();
    this.dataManagementService.mergeDatasets(activeLayers).subscribe(res => {
      window.location.reload();
    });
  }

  deleteDataSets() {
    this.activeDatasets.forEach(element => {
      this.dataManagementService.deleteDatasetById(element)
    });

    //window.location.reload();
  }

  toggle_element(element_id: string, e: HTMLElement) {
    var el = document.getElementById(element_id)
    if (el.style.visibility == 'hidden') {
      el.style.visibility = 'visible'
    }
    else {
      el.style.visibility = 'hidden'
    }
    e.classList.toggle("change")
  }
}
