export function fieldBoundaryLayer(map: mapboxgl.Map, visible: boolean){
    map.addLayer({
        id: 'fieldBoundery-layer',
        type: 'fill',
        source: 'fieldBoundery-source',
        layout: {
            visibility: (visible ? 'visible' : 'none')
        },
        paint: {
            'fill-color': '#ee2308',
            'fill-opacity': 0.4
        }
    });
}