import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import * as global from '../../globals';
import { AuthService } from '@auth0/auth0-angular';
import { concatMap, take } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class DatasetService {
  constructor(private http: HttpClient, private auth: AuthService) {}

  getBRP(localToken: string, bbox: number[][]): Observable<any> {
    try {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Headers': 'Content-Type',
        token: localToken,
      });
      const options = { headers };
      return this.http.post<any>(
        global.apiUrl + global.dataset + '/dataset/brp',
        {
          geo_frame: {
            polygon: bbox
          }
        },
        options
      );
    } catch (ex) {
      return ex;
    }
  }

  // tslint:disable-next-line:max-line-length
  getDataset(dataType: string, bbox: number[][], timeFrameStart?: Date, timeFrameEnd?: Date, timestamp?: Date,
             property: string = 'circumference'): Observable<any> {

    let url = global.apiUrl + global.dataset + '/dataset/' + dataType;

    const body = {
      geo_frame: {
        polygon: bbox
      },
      timeframe: undefined,
      timestamp: undefined
    };

    if (timestamp) {
      body.timestamp = {
        timestamp
      };
    }

    if (timeFrameStart && timeFrameEnd) {
      body.timeframe = {
        timeframe_start: timeFrameStart,
        timeframe_end: timeFrameEnd
      };
    }

    if (property) {
      url = url + '?property=' + property;
    }

    return this.auth.user$
      .pipe(
        take(1),
        concatMap((user) => {
            return this.http.post<any>(url,
            body);
          }
        )
      );
  }

  getDatasetFromDatasetId(datasetId: number): Observable<any> {
    const params = new HttpParams();

    return this.auth.user$
      .pipe(
        take(1),
        concatMap((user) => {
            return this.http.get<any>(global.apiUrl + global.dataset + '/dataset/from_dataset_id/' + datasetId);
          }
        )
      );
  }

  getDatasetInfo(bbox: number[][]): Observable<any> {
    const body = {
      polygon: bbox
    };

    return this.auth.user$
      .pipe(
        take(1),
        concatMap((user) => {
            return this.http.post<any>(global.apiUrl + global.dataset + '/get_dataset_info',
            body);
          }
        )
      );
  }

  getDatasetFromIdAndGeoFrame(bbox: number[][], datasetId: number, property: string = 'circumference'): Observable<any> {

    let url = global.apiUrl + global.dataset + '/from_dataset_id_geo_frame/' + datasetId;
    const body = {
      polygon: bbox
    };

    if (property) {
      url = url + '?property=' + property;
    }

    return this.auth.user$
      .pipe(
        take(1),
        concatMap((user) => {
            return this.http.post<any>(url,
            body);
          }
        )
      );
  }
}
