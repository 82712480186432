import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StatisticsService } from '../../services/statistics.service';
import { FieldService } from '../../services/field.service';
import { DatasetService } from '../../services/dataset.service';
import { LngLatLike, Map } from 'mapbox-gl';
import { StatisticsMapLayerService } from '../../services/statistics-map-layer.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { IListResponse } from 'src/app/models/global.model';
import { IField } from 'src/app/models/field.model';
import * as XLSX from 'xlsx';
import { IStatistic } from 'src/app/models/statistics.model';
import { PortalAppsService } from 'src/app/services/portal-apps/portal-apps.service';

@Component({
  selector: 'app-statistics-page',
  templateUrl: './statistics-page.component.html',
  styleUrls: ['./statistics-page.component.css']
})
export class StatisticsPageComponent implements OnInit {


  // tslint:disable-next-line:max-line-length
  constructor(private viewContainerRef: ViewContainerRef, private portalAppsService: PortalAppsService) {
  }

  ngOnInit(): void {
    this.viewContainerRef.clear();
    const statisticsComponent = this.portalAppsService.activeApp.statisticsComponent;
    
    const componentRef = this.viewContainerRef.createComponent(statisticsComponent);
  }

  
}
