import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { IField } from 'src/app/models/field.model';
import { IListResponse } from 'src/app/models/global.model';
import { FieldService } from '../../services/field.service';
import { PortalAppsService } from 'src/app/services/portal-apps/portal-apps.service';

@Component({
  selector: 'app-fields-page',
  templateUrl: './fields-page.component.html',
  styleUrls: ['./fields-page.component.css'],
})
export class FieldsPageComponent implements AfterViewInit, OnInit {
  constructor(private fieldService: FieldService, private changeDetector: ChangeDetectorRef, private router: Router, private portalAppsService: PortalAppsService) { }

  @ViewChildren('test') test: QueryList<ElementRef<HTMLLIElement>>;


  activeAppCode;
  fields = [];
  showingFields = [];

  activePage = 1;

  pages = [];
  amountPerPage = 9;

  selectedField;
  dimensions = { width: null, height: null };

  totalFieldAmount;

  fieldList: IListResponse<IField>;

  ngOnInit(): void {
    this.activeAppCode = localStorage.getItem("active_app_code_name");
    this.getFields(0);
    this.totalFieldAmount = null;
  }

  async ngAfterViewInit() {
    this.test.changes.subscribe((list) => {
      list.forEach((element) => {
        this.dimensions.width = element.nativeElement.offsetWidth;
        this.dimensions.height = element.nativeElement.offsetHeight;
      });
    });

    
  }

  ngAfterContentChecked(): void {
    
    this.changeDetector.detectChanges();
  }

  getFields(offset = 0) {
    const measurementAppIds = Object.values(this.portalAppsService.activeApp.measurementAppsMap);
    const amountPerPage = this.amountPerPage;
    const fieldsSet = new Set();
    this.fieldList = {
      items: [],
      limit: amountPerPage,
      offset: 0,
      total: 0
    };
    this.pages = [];

    const fetchFieldsForApp = (appId, offset) => {
      return this.fieldService.get_fields_for_app(appId, offset, amountPerPage);
    };

    const fetchAllFields = async () => {
      
      for (const appId of measurementAppIds) {
        let currentOffset = offset;
        let moreData = true;

        fetchFieldsForApp(appId, currentOffset).subscribe(
          data => {
            data.items.forEach(item => {
              if (!fieldsSet.has(item.id)) {
                fieldsSet.add(item.id);
                this.fieldList.items.push(item);
              }
            });
  
            if(this.totalFieldAmount == null){
              this.totalFieldAmount = data.total;
              
            }
            calculatePages();
            
            
          },
          error => {
            console.error(`Failed to fetch fields for appId ${appId}:`, error);
            moreData = false;
            calculatePages(); // Calculate pages even if there is an error
          }
        );
      }


      // Calculate pages after all data is fetched

    };

    const calculatePages = () => {;
      this.pages = [];
      let page = 1;
      while (page <= Math.ceil(this.totalFieldAmount / amountPerPage)) {
        this.pages.push(page);
        page++;
      }
    };

    fetchAllFields();
  }



  changePage(page: number){
    if(this.pages.includes(page)){
      this.activePage = page;
      this.getFields((page - 1)*this.amountPerPage)
    }
  }

  deleteFieldButton(field: any, event: any) {
    event.stopPropagation(); //this can be achieved in the html, so rewriting this method could potentially result in lower code 
    this.selectedField = field;
  }

  deleteSelectedField() {
    this.fieldService
      .delete_field(this.selectedField.field_id)
      .subscribe((res) => {
        this.getAccessibleFields();
        this.selectedField = undefined;
      });
  }

  getAccessibleFields() {
    // this.fieldService.get_accessible_fields(this.page, 100).subscribe((d) => {
    //   this.fields = d.fields;
    //   this.pages = [];
    //   this.paginate(this.page);
    //   let i = 1;
    //   while (Math.ceil((this.fields.length + 1) / 9) >= i) {
    //     this.pages.push(i);
    //     i++;
    //   }
    // });
  }

  getFieldMinMaxLonLat(coordinates) {
    const maxLon = Math.max(...[].concat(...coordinates.map((c) => c[0])));
    const minLon = Math.min(...[].concat(...coordinates.map((c) => c[0])));
    const maxLat = Math.max(...[].concat(...coordinates.map((c) => c[1])));
    const minLat = Math.min(...[].concat(...coordinates.map((c) => c[1])));
    return {
      minLon: minLon,
      maxLon: maxLon,
      minLat: minLat,
      maxLat: maxLat,
    };
  }

  getX(x, bounds) {
    let boxsize = this.dimensions.width;
    let multiplier = boxsize / (bounds.maxLon - bounds.minLon) * 2

    if (bounds.maxLat - bounds.minLat > (bounds.maxLon - bounds.minLon) * 0.5) {
      multiplier = boxsize / (bounds.maxLat - bounds.minLat)
    }

    let position = (x - bounds.minLon);
    return (multiplier * 0.5 * position + ((boxsize - multiplier / 2 * (bounds.maxLon - bounds.minLon)) / 2));
  }
  getY(y, bounds) {
    let boxsize = this.dimensions.height;
    let multiplier = boxsize / (bounds.maxLon - bounds.minLon) * 2

    if (bounds.maxLat - bounds.minLat > (bounds.maxLon - bounds.minLon) * 0.5) {
      multiplier = boxsize / (bounds.maxLat - bounds.minLat)
    }

    let position = (y - bounds.minLat);
    let result = (multiplier * position) - ((this.dimensions.height - multiplier * (bounds.maxLat - bounds.minLat)) / 2 + multiplier * (bounds.maxLat - bounds.minLat));
    return (-result);
  }
  getPoints(field) {
    let temp = [];
    field.boundary.coordinates[0].forEach((element) => {
      let bounds = this.getFieldMinMaxLonLat(element);
      for (let i = 0; i < element.map((c) => c).length; i++) {
        let x = this.getX(element.map((c) => c[0])[i], bounds);
        let y = this.getY(element.map((c) => c[1])[i], bounds);
        temp.push({ x, y });
      }
    });
    let xpoint = temp.map((item) => {
      return item['x']
    })
    let ypoint = temp.map((item) => {
      return item['y']
    })
    let attr = "";
    let result = "";
    for (let i = 0; i < xpoint.length; i++) {
      result = attr.concat(result, xpoint[i] + "," + ypoint[i] + " ")
    }
    return result;
  }

  archiveField(field: any) {
    this.fieldService.archive_field(field).subscribe(d => {
      this.getFields(0);
    });
  }

  editField(field: any) {
    //Functionallity to edit a field
  }

  go_to_field(fieldId, fieldCenter){
    localStorage.setItem('center', JSON.stringify(fieldCenter.coordinates))
    const route = "apps/" + this.activeAppCode + '/fields/fieldmap';
    this.router.navigate([route], {queryParams: {field_id: fieldId }});
  }
}
