<mgl-map [style]="'mapbox://styles/mapbox/light-v9'" [zoom]="[num]" [center]="center" (load)="map = $event; mapLoad()">
</mgl-map>

<div class="floating_btn_container">
  <button class="floating_btn" (click)="zoomToField()">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000" height="20px"
      width="20px" version="1.1" id="Capa_1" viewBox="0 0 297 297" xml:space="preserve" style="    stroke: black;
    stroke-width: 3px;">
    <title>{{'i18n.FIELD_PAGE.GO_TO_FIELD' | translate}}</title>
      <g>
        <path
          d="M148.5,0C87.43,0,37.747,49.703,37.747,110.797c0,91.026,99.729,179.905,103.976,183.645   c1.936,1.705,4.356,2.559,6.777,2.559c2.421,0,4.841-0.853,6.778-2.559c4.245-3.739,103.975-92.618,103.975-183.645   C259.253,49.703,209.57,0,148.5,0z M148.5,272.689c-22.049-21.366-90.243-93.029-90.243-161.892   c0-49.784,40.483-90.287,90.243-90.287s90.243,40.503,90.243,90.287C238.743,179.659,170.549,251.322,148.5,272.689z" />
        <path
          d="M148.5,59.183c-28.273,0-51.274,23.154-51.274,51.614c0,28.461,23.001,51.614,51.274,51.614   c28.273,0,51.274-23.153,51.274-51.614C199.774,82.337,176.773,59.183,148.5,59.183z M148.5,141.901   c-16.964,0-30.765-13.953-30.765-31.104c0-17.15,13.801-31.104,30.765-31.104c16.964,0,30.765,13.953,30.765,31.104   C179.265,127.948,165.464,141.901,148.5,141.901z" />
      </g>
    </svg>
  </button>
  <button class="floating_btn" (click)="toggle_legend()">
    <svg stroke="currentColor" style="height: 20px; width: 20px" xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 64 64"
      enable-background="new 0 0 64 64" xml:space="preserve" style="height: 20px;
    width: 20px;
    stroke: black;
    stroke-width: 3px;">
      <title>{{'i18n.FIELD_PAGE.LEGEND' | translate}}</title>
      <g id="Bullet-list" stroke="currentColor">
        <path stroke="currentColor"
          d="M22.9840508,12.7494497h40c0.5522995,0,0.9995995-0.4471998,0.9995995-0.9994993   c0-0.5522003-0.4473-0.9995003-0.9995995-0.9995003h-40c-0.5522003,0-0.9995003,0.4473-0.9995003,0.9995003   C21.9845505,12.3022499,22.4318504,12.7494497,22.9840508,12.7494497z" />
        <path stroke="currentColor"
          d="M62.9840508,31.2963505h-40c-0.5522003,0-0.9995003,0.4473-0.9995003,0.9994984   c0,0.5522995,0.4473,0.9995003,0.9995003,0.9995003h40c0.5522995,0,0.9995995-0.4472008,0.9995995-0.9995003   C63.9836502,31.7436504,63.5363503,31.2963505,62.9840508,31.2963505z" />
        <path stroke="currentColor"
          d="M62.9840508,51.2504501h-40c-0.5522003,0-0.9995003,0.4473-0.9995003,0.9995003   c0,0.5522995,0.4473,0.9995003,0.9995003,0.9995003h40c0.5522995,0,0.9995995-0.4472008,0.9995995-0.9995003   C63.9836502,51.6977501,63.5363503,51.2504501,62.9840508,51.2504501z" />
        <path stroke="currentColor"
          d="M5.9840508,5.7822499c-3.2904999,0-5.9677,2.6771998-5.9677,5.9677005c0,3.2905998,2.6772001,5.9678001,5.9677,5.9678001   c3.2905998,0,5.9678001-2.6772003,5.9678001-5.9678001C11.9518509,8.4594498,9.2746506,5.7822499,5.9840508,5.7822499z    M5.9840508,15.7822504c-2.2235854,0-4.0321999-1.8086004-4.0321999-4.0323c0-2.2236004,1.8086146-4.0322003,4.0321999-4.0322003   c2.2236996,0,4.0323,1.8085999,4.0323,4.0322003C10.0163507,13.97365,8.2077503,15.7822504,5.9840508,15.7822504z"
          stroke="currentColor" />
        <path stroke="currentColor"
          d="M5.9840508,26.3281498c-3.2904999,0-5.9677,2.6772003-5.9677,5.9676991c0,3.2905998,2.6772001,5.9678001,5.9677,5.9678001   c3.2905998,0,5.9678001-2.6772003,5.9678001-5.9678001C11.9518509,29.0053501,9.2746506,26.3281498,5.9840508,26.3281498z    M5.9840508,36.3281517c-2.2235854,0-4.0321999-1.8086014-4.0321999-4.0323029c0-2.2235985,1.8086146-4.032198,4.0321999-4.032198   c2.2236996,0,4.0323,1.8085995,4.0323,4.032198C10.0163507,34.5195503,8.2077503,36.3281517,5.9840508,36.3281517z"
          stroke="currentColor" />
        <path stroke="currentColor"
          d="M5.9840508,46.2822495c-3.2904999,0-5.9677,2.6772003-5.9677,5.967701c0,3.2905998,2.6772001,5.9678001,5.9677,5.9678001   c3.2905998,0,5.9678001-2.6772003,5.9678001-5.9678001C11.9518509,48.9594498,9.2746506,46.2822495,5.9840508,46.2822495z    M5.9840508,56.2822495c-2.2235854,0-4.0321999-1.8085976-4.0321999-4.032299c0-2.2236023,1.8086146-4.0321999,4.0321999-4.0321999   c2.2236996,0,4.0323,1.8085976,4.0323,4.0321999C10.0163507,54.4736519,8.2077503,56.2822495,5.9840508,56.2822495z"
          stroke="currentColor" />
      </g>
    </svg>
  </button>
</div>



<div class="legend_container">
  <div class="smalldesc">
    <div class="global_tile legend" *ngIf="enableBaseData">
      <h3>{{'i18n.FIELD_PAGE.TREE_WIDTH' | translate}}</h3>
      <div class="legend_item" fxLayout="row" fxLayoutGap="10px">
        <svg style="height: 12px; width: 14px;" id="eJAIm6vMlbZ1" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 52 52" shape-rendering="geometricPrecision"
          text-rendering="geometricPrecision">
          <ellipse rx="25" ry="25" transform="translate(26 26)" fill="#FFEB01" stroke="#000" stroke-width="1" />
        </svg>
        <p class="value">8-10, 16-18, 30-35, 50-60</p>
      </div>
      <div class="legend_item" fxLayout="row" fxLayoutGap="10px">
        <svg style="height: 12px; width: 14px; " id="eJAIm6vMlbZ1" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 52 52" shape-rendering="geometricPrecision"
          text-rendering="geometricPrecision">
          <ellipse rx="25" ry="25" transform="translate(26 26)" fill="#ED2938" stroke="#000" stroke-width="1" />
        </svg>
        <p class="value">10-12, 18-20, 35-40, 60-70</p>
      </div>
      <div class="legend_item" fxLayout="row" fxLayoutGap="10px">
        <svg style="height: 12px; width: 14px;" id="eJAIm6vMlbZ1" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 52 52" shape-rendering="geometricPrecision"
          text-rendering="geometricPrecision">
          <ellipse rx="25" ry="25" transform="translate(26 26)" fill="#FFFFFF" stroke="#000" stroke-width="1" />
        </svg>
        <p class="value">12-14, 20-25, 40-45, 70-80</p>
      </div>
      <div class="legend_item" fxLayout="row" fxLayoutGap="10px">
        <svg style="height: 12px; width: 14px;" id="eJAIm6vMlbZ1" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 52 52" shape-rendering="geometricPrecision"
          text-rendering="geometricPrecision">
          <ellipse rx="25" ry="25" transform="translate(26 26)" fill="#0088DD" stroke="#000" stroke-width="1" />
        </svg>
        <p class="value">14-16, 25-30, 45-50, 80-90</p>
      </div>
    </div>
  </div>

</div>


<div #menu_btn class="right_menu_btn change" (click)="toggle_element('right_menu', menu_btn)">
  <div class="bar1"></div>
  <div class="bar2"></div>
  <div class="bar3"></div>
</div>

<div id="right_menu" class="global_tile right_menu scrollable" fxLayout="column" fxLayoutAlign="space-between stretch"
  fxLayoutGap="20px" #rightMenu>
  <p class="field_name">{{fieldName}}‎</p>

  <mat-label>{{'i18n.FIELD_PAGE.DATASET' | translate}}</mat-label>

  <div class="date_slector">
    <input class="global_input_field" placeholder="{{'i18n.GLOBAL.SEARCH' | translate}}" (keyup)="searchedDate = $event.target.value">
    <div class="date_list">
      <div *ngFor="let date of datesToHighlight">
        <p *ngIf="date.includes(searchedDate)" (click)="dateChange($event, date)"
          [ngStyle]="{'background-color': (date.includes(currentSelectedDate)) ? '#e2dff0' : '#fff'}">
          {{date.substring(0, 10)}}
        </p>
      </div>
    </div>
  </div>
  <div class="global_devider"></div>
  <!-- <button (click)="generateColorsTMP()">test gen</button> -->

  <mat-label>{{'i18n.FIELD_PAGE.MAP_STYLE' | translate}}</mat-label>
  <mat-slide-toggle (change)="change_style('satellite')">{{'i18n.FIELD_PAGE.SATELLITE' |
    translate}}</mat-slide-toggle>
    <div class="global_devider"></div>

  <mat-label>{{'i18n.FIELD_PAGE.VISUALIZATION' | translate}}</mat-label>
  <mat-slide-toggle (change)="toggleBaseData()" [(ngModel)]="enableBaseData">{{'i18n.FIELD_PAGE.MEASUREMENTS' |
    translate}}</mat-slide-toggle>
  <mat-slide-toggle (change)="toggleMetaData()" [(ngModel)]="enableMetaData">{{'i18n.FIELD_PAGE.META' |
    translate}}</mat-slide-toggle>
  <mat-slide-toggle (change)="layerVisibility('selected_brp_zone')" [(ngModel)]="enableField">{{'i18n.FIELD_PAGE.FIELD'
    | translate}}</mat-slide-toggle>

    <button class="global_btn export_btn" (click)="exportTableToExcel()" [ngStyle]="{'display': (this.enableBaseData && this.data_storage.length > 0) ? 'block' : 'none'}">{{'i18n.FIELD_PAGE.EXPORT_EXCEL' | translate}}</button>


  <!-- LATER IMPLEMENTATION V2 -->
  <!-- <mat-label>data color</mat-label>
  <div class="date_slector" *ngIf="enableBaseData">
    <input class="global_input_field" placeholder="search" (keyup)="searchedDate = $event.target.value">
    <div class="date_list">
      <div *ngFor="let date of datesToHighlight">
        <p *ngIf="date.includes(searchedDate)" (click)="dateChange($event, date)"
          [ngStyle]="{'background-color': (currentSelectedDate==date) ? '#e2dff0' : '#fff'}">{{date.substring(0, 10)}}
        </p>
      </div>
    </div>
  </div>

  <div class="date_slector" *ngIf="enableMetaData">
    <input class="global_input_field" placeholder="search" (keyup)="searchedDate = $event.target.value">
    <div class="date_list">
      <div *ngFor="let date of datesToHighlight">
        <p *ngIf="date.includes(searchedDate)" (click)="dateChange($event, date)"
          [ngStyle]="{'background-color': (currentSelectedDate==date) ? '#e2dff0' : '#fff'}">{{date.substring(0, 10)}}
        </p>
      </div>
    </div>
  </div> -->
</div>