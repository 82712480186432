import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import * as mapActions from '../../dashboard/map/store/map.action';
import { GetBrpZonesViewModel } from './viewmodels/getBrpZonesViewModel';
import { GetDatasetViewModel } from './viewmodels/getDatasetViewModel';
import * as mapStore from './store/map.reducer';
@Injectable()
export class MapFacade {
  error$ = this.store.pipe(select(mapStore.getError));
  $getSatelliteStatus = this.store.pipe(
    select(mapStore.getSatelliteStatus),
    filter(Boolean)
  );
  $getBrpZoneStatus = this.store.pipe(
    select(mapStore.getBrpZoneStatus),
    filter(Boolean)
  );
  BrpZones$ = this.store.pipe(select(mapStore.getBrpZones));
  // TreeWidth$ = this.store.pipe(select(mapStore.getTreeWidth));
  // SoilScan$ = this.store.pipe(select(mapStore.getSoilScan));
  Bbox$ = this.store.pipe(select(mapStore.getBbox));
  SelectedBrpZone$ = this.store.pipe(select(mapStore.getSelectedBrpZone));

  constructor(private store: Store<mapStore.AppState>) {}

  enableSatellite() {
    this.store.dispatch(new mapActions.EnableSatellite());
  }

  enableBrpZone() {
    this.store.dispatch(new mapActions.EnableBrpZone());
  }

  updateBbox(bbox: number[][]) {
    try {
      this.store.dispatch(new mapActions.UpdateBbox(bbox));
    } catch (ex) {
      console.log('facade update error');
    }
  }

  getBrpZone(bbox: number[][]) {
    this.store.dispatch(
      new mapActions.GetBrpZones(new GetBrpZonesViewModel(bbox, 'poop'))
    );
    // this.guard.getToken().subscribe(
    //   (res) => {
    //     this.store.dispatch(
    //       new mapActions.GetBrpZones(new GetBrpZonesViewModel(bbox, res.token))
    //     );
    //   },
    //   (error) => {
    //     throw new Error();
    //   }
    // );
  }

  updateSelectedBrpZone(geom: number[][]) {
    try {
      this.store.dispatch(new mapActions.UpdateSelectedBrpZone(geom));
    } catch (ex) {
      console.log('facade update error');
    }
  }

  // getTreeWidth(
  //   bbox: number[][],
  //   timeFrameStart?: Date,
  //   timeFrameEnd?: Date,
  //   timestamp?: Date
  // ) {
  //   this.guard.getToken().subscribe(
  //     (res) => {
        
  //       this.store.dispatch(
  //         new mapActions.GetTreeWidth(
  //           new GetDatasetViewModel(
  //             res.token,
  //             'tree_width',
  //             bbox,
  //             timeFrameStart,
  //             timeFrameEnd,
  //             timestamp
  //           )
  //         )
  //       );
  //     },
  //     (error) => {
  //       throw new Error();
  //     }
  //   );
  // }
  // // @ts-ignore
  // // tslint:disable-next-line:adjacent-overload-signatures
  // catch(ex) {
  //   throw new Error();
  // }

  // getSoilScan(
  //   bbox: number[][],
  //   timeFrameStart?: Date,
  //   timeFrameEnd?: Date,
  //   timestamp?: Date
  // ) {
  //   this.guard.getToken().subscribe(
  //     (res) => {
  //       this.store.dispatch(
  //         new mapActions.GetSoilScan(
  //           new GetDatasetViewModel(
  //             res.token,
  //             'soil_scan',
  //             bbox,
  //             timeFrameStart,
  //             timeFrameEnd,
  //             timestamp
  //           )
  //         )
  //       );
  //     },
  //     (error) => {
  //       throw new Error();
  //     }
  //   );
  // }
  // // @ts-ignore
  // // tslint:disable-next-line:adjacent-overload-signatures
  // catch(ex) {
  //   throw new Error();
  // }
}
