import { AfterViewInit, Injectable, OnInit } from '@angular/core';
import mapboxgl, { AnyLayer, AnySourceData, Map, Source } from 'mapbox-gl';
import { DatasetService } from './dataset.service';
import { MapFacade } from '../dashboard/map/MapFacade';
import { environment as env } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class FieldMapLayerService {
  fieldZone;
  popup: mapboxgl.Popup;
  enableTreeWidth: boolean;
  // enableSoilScan: boolean;
  enableBrpZones: boolean;
  enableSatellite: boolean;
  enableFieldZone: boolean;
  bbox: number[][];
  zIndex1;
  zIndex2;
  zIndex3;
  zIndex4;
  loadedDataSets = [];
  metaDataLoaded;
  darkGreen = '#0b4000'
  red = '#ED2938';
  white = '#FFFFFF';
  blue = '#0088DD';
  yellow = '#FFEB01';
  lightGreen = '#74ff58'


  constructor(private mapFacade: MapFacade, public map: Map, private datasetService: DatasetService) {
  }

  setField(field_boundary: number[][][][]) {
    this.loadedDataSets = [];
    this.metaDataLoaded = false;
    this.fieldZone = field_boundary[0];
    this.enableTreeWidth = false;
    // this.enableSoilScan = false;
    this.enableFieldZone = true;

    this.setZIndexes();
    this.setLayers();
    this.setCompanyLogo();
  }

  setZIndexes() {
    this.map.addSource('empty', {
      type: 'geojson',
      data: { type: 'FeatureCollection', features: [] }
    });
    this.zIndex1 = this.map.addLayer({
      id: 'z-index-1',
      type: 'symbol',
      source: 'empty'
    }); // place this layer on top

    this.zIndex2 = this.map.addLayer({
      id: 'z-index-2',
      type: 'symbol',
      source: 'empty'
    }, 'z-index-1'); // place this layer below zIndex1

    this.zIndex3 = this.map.addLayer({
      id: 'z-index-3',
      type: 'symbol',
      source: 'empty'
    }, 'z-index-2'); // place this layer below zIndex2

    this.zIndex4 = this.map.addLayer({
      id: 'z-index-4',
      type: 'symbol',
      source: 'empty'
    }, 'z-index-3'); // place this layer below zIndex3

    this.zIndex4 = this.map.addLayer({
      id: 'z-index-5',
      type: 'symbol',
      source: 'empty'
    }, 'z-index-4'); // place this layer below zIndex4
  }

  setLayers() {
    // set selected polygon zone
    const field = {
      type: 'Feature' as const,
      properties: {},
      geometry: {
        type: 'Polygon' as const,
        coordinates: [this.fieldZone]
      }
    };

    // Add a geojson point source.
    this.map.addSource('selected_brp_zone', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [
          field
        ]

      }
    });
    this.map.addLayer({
      id: 'selected_brp_zone',
      type: 'fill',
      source: 'selected_brp_zone',
      layout: {},
      paint: {
        'fill-color': '#ee2308',
        'fill-opacity': 0.4
      }
    }
      , 'z-index-4');
    if (!this.enableFieldZone) {
      this.setVisibility('selected_brp_zone', this.enableFieldZone);
    }

    this.popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false
    });


    // set metadata layer
    // dots, just bigger displaying the search radious! genious



    // set soil scan layer
    // this.datasetService.getDataset('soil_scan', this.fieldZone).subscribe((res) => {
    //     if (res !== undefined) {
    //       if (res.dataset.length > 0) {

    //         // if (this.map.getSource('soil_scan')) {
    //         //   this.map.removeLayer('soil_scan');
    //         //   this.map.removeSource('soil_scan');
    //         // }

    //         // this.map.addSource('soil_scan', {
    //         //   type: 'geojson',
    //         //   data: {
    //         //     type: 'FeatureCollection',
    //         //     features: res.dataset
    //         //   }
    //         // });
    //         this.map.addLayer({
    //           id: 'selected_brp_zone',
    //           type: 'fill',
    //           source: 'selected_brp_zone',
    //           layout: {},
    //           paint: {
    //             'fill-color': '#c62424',
    //             'fill-opacity': 0.4
    //           }
    //         }, 'z-index-1');
    //       }
    //       if (!this.enableBrpZones) {
    //         this.setVisibility('brp', this.enableBrpZones);
    //       }
    //     }
    //   }
    // );
    // set satellite layer

    // this.map.addSource(
    //   'mapbox-satellite', {
    //   type: 'raster',
    //   url: 'https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v12?access_token=pk.eyJ1IjoiZmxvcmlzcGV0ZXJzIiwiYSI6ImNrcjM2MzU2NjB3aWYycnFtbno2ampqYzgifQ.A-51Hqh4pULfmnMntVst7Q'
    // });

    // this.map.addLayer({
    //   id: 'satellite',
    //   type: 'raster',
    //   source: 'mapbox-satellite',
    //   "source-layer": "road",

    // }, 'z-index-3');
    // this.map.setLayoutProperty('satellite', 'visibility', 'none');

    let imgDot = new Image(12, 12)
    imgDot.onload = () => this.map.addImage('dot', imgDot, { sdf: true })
    imgDot.src = '../assets/map_dots/AW_dot.svg'

    let imgRuit = new Image(14, 14)
    imgRuit.onload = () => this.map.addImage('ruit', imgRuit, { sdf: true })
    imgRuit.src = '../assets/map_dots/AW_ruit.svg'

    let imgSquare = new Image(10, 10)
    imgSquare.onload = () => this.map.addImage('square', imgSquare, { sdf: true })
    imgSquare.src = '../assets/map_dots/AW_square.svg'

    let imgStar = new Image(14, 14)
    imgStar.onload = () => this.map.addImage('star', imgStar, { sdf: true })
    imgStar.src = '../assets/map_dots/AW_star.svg'
  }

  updateVisibility(layer: string) {
    switch (layer) {
      // case 'soil_scan': {
      //   this.enableSoilScan = !this.enableSoilScan;
      //   this.setVisibility(layer, this.enableSoilScan);
      //   // this.mapFacade.getSoilScan(this.bbox);
      //   break;
      // }
      case 'satellite': {
        this.enableSatellite = !this.enableSatellite;
        this.setVisibility(layer, this.enableSatellite);
        break;
      }
      case 'selected_brp_zone': {
        this.enableFieldZone = !this.enableFieldZone;
        this.setVisibility(layer, this.enableFieldZone);
        break;
      }
    }
  }

  setVisibility(layer: string, visibility: boolean) {
    if (this.map.getLayer(layer) !== undefined) {
      if (visibility) {
        this.map.setLayoutProperty(layer, 'visibility', 'visible');
      } else {
        this.map.setLayoutProperty(layer, 'visibility', 'none');
      }
    }
  }

  createTreeWidthHTML(circumference: number, coordindates: number[], tree_type: string, bucket: string) {
    return `<p><b>Tree circumference:</b> <br> ${circumference.toFixed(1)} cm </p>
    <p><b>Maatlabel:</b> <br> ${bucket} cm </p>
      <p><b>Tree type:</b> <br> ${tree_type}</p>
      <p><b>Location:</b> <br> ${coordindates[0]}, ${coordindates[1]} </p>`;
  }

  createMetaDataHTML(treeType: string, note: string, row: string, lifetime: string, extra_metadata?: object) {
    let name_map = {
      'eng': {
        'plot': 'Plot',
        'block': 'Block',
        'block_row': 'Block Row',
        'tree_id': 'Tree ID',
        'sub_field': 'Sub Field',
      },
      'nld': {
        'plot': 'Plot',
        'block': 'Blok',
        'block_row': 'Blok Rij',
        'tree_id': 'Boom ID',
        'sub_field': 'Sub Veld',
      }
    };
    let result = `<p><b>Tree type:</b> ${treeType} </p>
    <p><b>Note:</b> ${note} </p>
    <p><b>Row:</b> ${row} </p>
    <p><b>Lifetime:</b> ${lifetime} </p>`;
    if (extra_metadata) {
      for (const [key, value] of Object.entries(extra_metadata)) {
        if (name_map[localStorage.getItem('lang') || 'eng'][key]){
          result += `<p><b>${name_map[localStorage.getItem('lang') || 'eng'][key]}:</b> ${value} </p>`;
        }
        else {
          result += `<p><b>${key}:</b> ${value} </p>`;
        }
        
      }
    }
    return result;
  }

  updateDatasetLayer(datasetDate: string, visibility: boolean, property: string = 'base_data', fieldId?: string) {
    const layerId = datasetDate + property;

    let visibilityString = 'none';
    if (visibility) {
      visibilityString = 'visible';
    }
    for (const d of this.loadedDataSets) {
      this.map.setLayoutProperty(d.id, 'visibility', 'none');
      d.status = false;
    }
    const datasetStatus = this.loadedDataSets.find(e => e.id === layerId);
    if (this.map.getLayer(layerId) !== undefined) {
      if (datasetStatus.status) {
        for (const d of this.loadedDataSets) {
          if (d.id === layerId) {
            d.status = false;
            break;
          }
        }
        this.map.setLayoutProperty(layerId, 'visibility', 'none');
      } else {
        for (const d of this.loadedDataSets) {
          if (d.id === layerId) {
            d.status = true;
            break;
          }
        }
        this.map.setLayoutProperty(layerId, 'visibility', visibilityString);

      }
    } else {
      this.loadedDataSets.push({
        id: layerId,
        status: true,
      });

      if (this.map.getSource(layerId)) {
        this.map.removeLayer(layerId);
        this.map.removeSource(layerId);
      }
      let orgId = JSON.parse(localStorage.getItem('org_id'))[0].id;

      var es = new EventSource(`${env.api_gateway_url}/v2/field/${fieldId}/data?date=${datasetDate}&organization_id=${orgId}&include_raw_data=true`);

      var my_data: any = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [] = [],
            },
            properties: {
              width: '10'
            }
          },

        ],
      }

      this.map.addSource(layerId, {
        type: 'geojson',
        data: my_data
      });

      this.map.addLayer({
        id: layerId,
        type: 'circle',
        source: layerId,
        layout: {


        },
        paint: {
          'circle-color': [
            'case',
            ['<', ['get', 'circumference'], 8],
            this.darkGreen,
            ['all', ['>=', ['get', 'circumference'], 8], ['<', ['get', 'circumference'], 10]],
            this.yellow,
            ['all', ['>=', ['get', 'circumference'], 10], ['<', ['get', 'circumference'], 12]],
            this.red,
            ['all', ['>=', ['get', 'circumference'], 12], ['<', ['get', 'circumference'], 14]],
            this.white,
            ['all', ['>=', ['get', 'circumference'], 14], ['<', ['get', 'circumference'], 16]],
            this.blue,
            ['all', ['>=', ['get', 'circumference'], 16], ['<', ['get', 'circumference'], 18]],
            this.yellow,
            ['all', ['>=', ['get', 'circumference'], 18], ['<', ['get', 'circumference'], 20]],
            this.red,
            ['all', ['>=', ['get', 'circumference'], 20], ['<', ['get', 'circumference'], 25]],
            this.white,
            ['all', ['>=', ['get', 'circumference'], 25], ['<', ['get', 'circumference'], 30]],
            this.blue,
            ['all', ['>=', ['get', 'circumference'], 30], ['<', ['get', 'circumference'], 35]],
            this.yellow,
            ['all', ['>=', ['get', 'circumference'], 35], ['<', ['get', 'circumference'], 40]],
            this.red,
            ['all', ['>=', ['get', 'circumference'], 40], ['<', ['get', 'circumference'], 45]],
            this.white,
            ['all', ['>=', ['get', 'circumference'], 45], ['<', ['get', 'circumference'], 50]],
            this.blue,
            ['all', ['>=', ['get', 'circumference'], 50], ['<', ['get', 'circumference'], 60]],
            this.yellow,
            ['all', ['>=', ['get', 'circumference'], 60], ['<', ['get', 'circumference'], 70]],
            this.red,
            ['all', ['>=', ['get', 'circumference'], 70], ['<', ['get', 'circumference'], 80]],
            this.white,
            ['all', ['>=', ['get', 'circumference'], 80], ['<', ['get', 'circumference'], 90]],
            this.blue,
            this.lightGreen
          ]
        }
      }, 'z-index-1');

      let map = this.map;

      var tmp_my_data = []
      var tmp_all_data = []

      es.onmessage = function (event) {
        let some = JSON.parse(event.data)
        tmp_all_data.push(some)

        tmp_my_data.push({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: some.location.coordinates
          },
          properties: {
            circumference: some.data.circumference,
            treeType: some.metadata.tree_type,
            bucket: some.data.size_bucket_circumference
          }
        },);

        if (tmp_my_data.length > 40) {
          my_data.features = my_data.features.concat(tmp_my_data);
          tmp_my_data = []
          let source: any = map.getSource(layerId)
          source.setData(my_data);
        }
      }

      es.onerror = function (event) {
        my_data.features = my_data.features.concat(tmp_my_data);
        let source: any = map.getSource(layerId)
        source.setData(my_data);
        es.close();
      }


      // removes existing layer to make room for layer with new data

      // Add a geojson point source.

      this.popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false
      });

      this.map.on('mouseenter', layerId, (e) => {
        this.map.getCanvas().style.cursor = 'pointer';

        // @ts-ignore
        const coordinates = e.features[0].geometry.coordinates.slice();
        const circumference = e.features[0].properties.circumference;
        const treeType = e.features[0].properties.treeType;
        const bucket = e.features[0].properties.bucket;
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        // Populate the popup and set its coordinates
        // based on the feature found.
        this.popup.setLngLat(coordinates).setHTML(this.createTreeWidthHTML(circumference, coordinates,treeType ,bucket)).addTo(this.map);
      });

      this.map.on('mouseleave', layerId, () => {
        this.map.getCanvas().style.cursor = '';
        this.popup.remove();
      });
    }

    return([layerId, tmp_all_data]);
  }

  createMetaDataLayer(datasetDate: string, visibility: boolean, fieldId: string) {
    const layerId = 'meta_data';

    if (this.metaDataLoaded) {
      this.map.setLayoutProperty(layerId, 'visibility', 'visible');
    } else {
      this.metaDataLoaded = true;
      if (this.map.getSource(layerId)) {
        this.map.removeLayer(layerId);
        this.map.removeSource(layerId);
      }
      let orgId = JSON.parse(localStorage.getItem('org_id'))[0].id;

      var es = new EventSource(`${env.api_gateway_url}/v2/field/${fieldId}/data?date=${datasetDate}&organization_id=${orgId}&include_raw_data=true`);

      var my_data: any = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [] = [],
            },
            properties: {
              width: '10'
            }
          },

        ],
      }

      this.map.addSource(layerId, {
        type: 'geojson',
        data: my_data
      });

      this.map.addLayer({
        id: layerId,
        type: 'circle',
        source: layerId,
        'paint': {
          // Make circles larger as the user zooms from z12 to z22.
          'circle-radius': {
            stops: [
              [0, 0],
              [22, 0.8 / 0.022 / Math.cos(JSON.parse(localStorage.getItem('center'))[0] * Math.PI / 180)]
            ],
            base: 2
          },
          // Color circles by ethnicity, using a `match` expression.
          'circle-color': '#fff',
          'circle-opacity': .6
        }
      }, 'z-index-2');

      let map = this.map;

      var tmp_my_data = []

      es.onmessage = function (event) {
        let some = JSON.parse(event.data)

        tmp_my_data.push({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: some.location.coordinates
          },
          properties: {
            treeType: some.metadata.tree_type,
            note: some.metadata.note.toString(),
            // hasPole: some.metadata.circumference.toString(),
            // damaged: some.metadata.circumference.toString(),
            row: some.metadata.row_index.toString(),
            lifetime: some.lifetime.toString(),
            extra_metadata: some.metadata.metadata,
          }
        },);

        if (tmp_my_data.length > 40) {
          my_data.features = my_data.features.concat(tmp_my_data);
          tmp_my_data = []
          let source: any = map.getSource(layerId)
          source.setData(my_data);
        }
      }

      es.onerror = function (event) {
        my_data.features = my_data.features.concat(tmp_my_data);
        let source: any = map.getSource(layerId)
        source.setData(my_data);
        es.close();
      }


      // removes existing layer to make room for layer with new data

      // Add a geojson point source.

      this.popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false
      });

      this.map.on('mouseenter', layerId, (e) => {
        this.map.getCanvas().style.cursor = 'pointer';

        // @ts-ignore
        const coordinates = e.features[0].geometry.coordinates.slice();
        const treeType = e.features[0].properties.treeType;
        const note = e.features[0].properties.note;
        // const damaged = e.features[0].properties.treeType;
        // const hasPole = e.features[0].properties.treeType;
        const row = e.features[0].properties.row;
        const lifetime = e.features[0].properties.lifetime;
        const extra_metadata = JSON.parse(e.features[0].properties.extra_metadata);
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        // Populate the popup and set its coordinates
        // based on the feature found.
        this.popup.setLngLat(coordinates).setHTML(this.createMetaDataHTML(treeType, note, row, lifetime, extra_metadata)).addTo(this.map);
      });

      this.map.on('mouseleave', layerId, () => {
        this.map.getCanvas().style.cursor = '';
        this.popup.remove();
      });
    }
  }

  // updateDatasetLayer_OLD(datasetDate: Date, visibility: boolean, property: string = 'circumference') {
  //   const layerId = datasetDate.toString() + property;

  //   let visibilityString = 'none';
  //   if (visibility) {
  //     visibilityString = 'visible';
  //   }
  //   for (const d of this.loadedDataSets) {
  //     this.map.setLayoutProperty(d.id, 'visibility', 'none');
  //     d.status = false;
  //   }
  //   const datasetStatus = this.loadedDataSets.find(e => e.id === layerId);
  //   if (this.map.getLayer(layerId) !== undefined) {
  //     if (datasetStatus.status) {
  //       for (const d of this.loadedDataSets) {
  //         if (d.id === layerId) {
  //           d.status = false;
  //           break;
  //         }
  //       }
  //       this.map.setLayoutProperty(layerId, 'visibility', 'none');
  //     } else {
  //       for (const d of this.loadedDataSets) {
  //         if (d.id === layerId) {
  //           d.status = true;
  //           break;
  //         }
  //       }
  //       this.map.setLayoutProperty(layerId, 'visibility', visibilityString);

  //     }
  //   } else {
  //     this.loadedDataSets.push({
  //       id: layerId,
  //       status: true,
  //     });
  //     this.datasetService.getDataset('tree_width', this.fieldZone, undefined, undefined, datasetDate, property).subscribe((res) => {
  //       if (res !== undefined) {
  //         if (res.dataset.length > 0) {
  //           // removes existing layer to make room for layer with new data
  //           if (this.map.getSource(layerId)) {
  //             this.map.removeLayer(layerId);
  //             this.map.removeSource(layerId);
  //           }
  //           // Add a geojson point source.
  //           this.map.addSource(layerId, {
  //             type: 'geojson',
  //             data: {
  //               type: 'FeatureCollection',
  //               features: res.dataset
  //             }
  //           });
  //           this.map.addLayer({
  //             id: layerId,
  //             type: 'circle',
  //             source: layerId,
  //             paint: {
  //               'circle-radius': [
  //                 'case',
  //                 ['<', ['get', 'width'], 6],
  //                 5,
  //                 ['all', ['>=', ['get', 'width'], 6], ['<', ['get', 'width'], 8]],
  //                 5,
  //                 ['all', ['>=', ['get', 'width'], 8], ['<', ['get', 'width'], 10]],
  //                 5,
  //                 ['all', ['>=', ['get', 'width'], 10], ['<', ['get', 'width'], 12]],
  //                 5,
  //                 ['all', ['>=', ['get', 'width'], 12], ['<', ['get', 'width'], 14]],
  //                 6,
  //                 ['all', ['>=', ['get', 'width'], 14], ['<', ['get', 'width'], 16]],
  //                 6,
  //                 ['all', ['>=', ['get', 'width'], 16], ['<', ['get', 'width'], 18]],
  //                 6,
  //                 ['all', ['>=', ['get', 'width'], 18], ['<', ['get', 'width'], 20]],
  //                 6,
  //                 7
  //               ],
  //               'circle-color': [
  //                 'case',
  //                 ['<', ['get', 'width'], 6],
  //                 this.white,
  //                 ['all', ['>=', ['get', 'width'], 6], ['<', ['get', 'width'], 8]],
  //                 this.blue,
  //                 ['all', ['>=', ['get', 'width'], 8], ['<', ['get', 'width'], 10]],
  //                 this.yellow,
  //                 ['all', ['>=', ['get', 'width'], 10], ['<', ['get', 'width'], 12]],
  //                 this.red,
  //                 ['all', ['>=', ['get', 'width'], 12], ['<', ['get', 'width'], 14]],
  //                 this.white,
  //                 ['all', ['>=', ['get', 'width'], 14], ['<', ['get', 'width'], 16]],
  //                 this.blue,
  //                 ['all', ['>=', ['get', 'width'], 16], ['<', ['get', 'width'], 18]],
  //                 this.yellow,
  //                 ['all', ['>=', ['get', 'width'], 18], ['<', ['get', 'width'], 20]],
  //                 this.red,
  //                 ['all', ['>=', ['get', 'width'], 20], ['<', ['get', 'width'], 25]],
  //                 this.white,
  //                 ['all', ['>=', ['get', 'width'], 25], ['<', ['get', 'width'], 30]],
  //                 this.blue,
  //                 ['all', ['>=', ['get', 'width'], 30], ['<', ['get', 'width'], 35]],
  //                 this.yellow,
  //                 ['all', ['>=', ['get', 'width'], 35], ['<', ['get', 'width'], 40]],
  //                 this.red,
  //                 this.white
  //               ]
  //             }
  //           });
  //         }
  //       }
  //       this.popup = new mapboxgl.Popup({
  //         closeButton: false,
  //         closeOnClick: false
  //       });

  //       this.map.on('mouseenter', layerId, (e) => {
  //         this.map.getCanvas().style.cursor = 'pointer';

  //         // @ts-ignore
  //         const coordinates = e.features[0].geometry.coordinates.slice();
  //         const width = e.features[0].properties.width;
  //         while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
  //           coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
  //         }

  //         // Populate the popup and set its coordinates
  //         // based on the feature found.
  //         this.popup.setLngLat(coordinates).setHTML(this.createTreeWidthHTML(width, coordinates)).addTo(this.map);
  //       });

  //       this.map.on('mouseleave', layerId, () => {
  //         this.map.getCanvas().style.cursor = '';
  //         this.popup.remove();
  //       });
  //     }
  //     );
  //   }
  // }


  // following method will be neded in the future for changeing the color of the map without relaoding anything

  colorChange(layerId) {
    this.map.setPaintProperty(layerId, 'icon-color', [
      'case',
      ['<', ['get', 'width'], 8],
      '#0088DD',
      ['all', ['>=', ['get', 'width'], 8], ['<', ['get', 'width'], 10]],
      '#0088DD',
      ['all', ['>=', ['get', 'width'], 10], ['<', ['get', 'width'], 12]],
      '#0088DD',
      ['all', ['>=', ['get', 'width'], 12], ['<', ['get', 'width'], 14]],
      '#0088DD',
      ['all', ['>=', ['get', 'width'], 14], ['<', ['get', 'width'], 16]],
      '#0088DD',
      ['all', ['>=', ['get', 'width'], 16], ['<', ['get', 'width'], 18]],
      '#0088DD',
      ['all', ['>=', ['get', 'width'], 18], ['<', ['get', 'width'], 20]],
      '#FFFFFF',
      ['all', ['>=', ['get', 'width'], 20], ['<', ['get', 'width'], 25]],
      '#FFFFFF',
      ['all', ['>=', ['get', 'width'], 25], ['<', ['get', 'width'], 30]],
      '#FFFFFF',
      ['all', ['>=', ['get', 'width'], 30], ['<', ['get', 'width'], 35]],
      '#FFFFFF',
      ['all', ['>=', ['get', 'width'], 35], ['<', ['get', 'width'], 40]],
      '#FFFFFF',
      ['all', ['>=', ['get', 'width'], 40], ['<', ['get', 'width'], 45]],
      '#FFFFFF',
      ['all', ['>=', ['get', 'width'], 45], ['<', ['get', 'width'], 50]],
      '#FFFFFF',
      ['all', ['>=', ['get', 'width'], 50], ['<', ['get', 'width'], 60]],
      '#FFFFFF',
      ['all', ['>=', ['get', 'width'], 60], ['<', ['get', 'width'], 70]],
      '#FFFFFF',
      ['all', ['>=', ['get', 'width'], 70], ['<', ['get', 'width'], 80]],
      '#FFFFFF',
      ['all', ['>=', ['get', 'width'], 80], ['<', ['get', 'width'], 90]],
      '#FFFFFF',
      '#FFFFFF'
    ]);
  }

  // following method is needed to filter based on size or tree type

  filterData(layerId) {
    this.map.setFilter(layerId, ["all", ['>', 'width', 50], ['<', 'width', 60]]);
  }

  // following method is needed to generate colors matching tree types, damaged things, meta data features etc.

  generateColors(layerId, dataFeature) {
    let colorRules = []
    let values = []
    var source: any = this.map.getSource('2022-05-18meta_data')
    for (let item of source._options.data.features) {
      values.push(item.properties.width)
    }

    values = values.filter(function (item, pos) {
      return values.indexOf(item) == pos;
    })

    colorRules.push('match', ['get', 'width'])

    for (let item of values) {
      var rgb = [];

      for (var i = 0; i < 3; i++)
        rgb.push(Math.floor(Math.random() * 255));

      colorRules.push(item.toString(), 'rgb(' + rgb + ')')
    }
    colorRules.push('rgb(0,0,0)')

    // following line is tmp
    this.map.setPaintProperty('2022-05-18meta_data', 'circle-color', colorRules)

    return (colorRules)
  }

  setCompanyLogo() {
    this.map.loadImage(
      '../assets/map-logo.png',
      (error, image) => {
        if (error) throw error;
        this.map.addImage('logo', image);
        this.map.addSource('point', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [6.1231334, 51.4063859],
                },
                properties: {},
              },
            ],
          },
        });
        this.map.addLayer({
          id: 'points',
          type: 'symbol',
          source: 'point', // reference the data source
          layout: {
            'icon-image': 'logo', // reference the image
            'icon-size': 0.2,
          },
        }, 'z-index-4');
      }
    );
  }
}
