<mgl-map
  [style]="'mapbox://styles/mapbox/light-v9'"
  [zoom]="[num]"
  [center]="center"
  (load)="map = $event; loadMap()"
>
</mgl-map>

<div #menu_btn class="right_menu_btn change" (click)="toggle_element('right_menu', menu_btn)">
  <div class="bar1"></div>
  <div class="bar2"></div>
  <div class="bar3"></div>
</div>


<div id="right_menu" class="right_menu scrollable global_tile" fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="20px">
  <mat-label>Map Style</mat-label>
  <mat-slide-toggle (change)="layerVisibility($event, 'satellite')" ngModel name="satelliteToggle">satellite </mat-slide-toggle>
  <div class="global_devider"></div>
  <mat-label>Datasets</mat-label>
  <div *ngIf="treeWidthDatasets.length == 0" id="loader"></div>
  <div *ngFor="let dataset of treeWidthDatasets" class="dataset-checkbox">
    <mat-checkbox id="{{dataset.id}}" (change)="updateDatasetLayer($event, dataset.id)"></mat-checkbox>
    <label for="{{dataset.id}}" class="checkbox-label"> {{dataset.date}} {{dataset.id}}</label>
  </div>
</div>

<div class="action_btns" >
      <button class="global_btn btn" (click)="mergeDataSets()">Samenvoegen</button>
      <button class="global_btn btn red-button" (click)="deleteDataSets()" [disabled]="(activeDatasets.length == 0)">Verwijderen</button>
</div>

