<div class="tree-measurement-data-component">
    <h3>{{'i18n.APP_SPECIFIC.STM.CREATION' | translate}}</h3>

    <div class="tree-measurement-data-overview">
        <table>
            <tr>
                <th>
                    {{'i18n.APP_SPECIFIC.STM.MAIN_DATA_MEASUREMENTS.PROPERTY' | translate}}
                </th>
                <th>
                    {{'i18n.APP_SPECIFIC.STM.MAIN_DATA_MEASUREMENTS.VALUE' | translate}}
                </th>
            </tr>
            <tr style="position: relative;">
                <td>{{'i18n.APP_SPECIFIC.STM.META_DATA_MEASUREMENTS.ROW' | translate}}</td>
                <td>
                    <div class="choose-row-container">
                        <button [ngClass]="{'selected' : this.mapStrategy.isRowSelectionOn}" (click)="getRowIndexFromPoint()">
                            <span *ngIf="this.displayData.value.rowIndex == null">{{'i18n.APP_SPECIFIC.STM.CHOOSE_ROW' | translate}}</span>
                            <span *ngIf="this.displayData.value.rowIndex != null">{{displayData.value.rowIndex}}</span>
                        </button>
                        <div class="row-selection-message" *ngIf="this.mapStrategy.isRowSelectionOn">
                            <span class="title">{{'i18n.APP_SPECIFIC.STM.ROW_SELECTION_ENABLED' | translate}}</span> 
                            <span>{{'i18n.APP_SPECIFIC.STM.ROW_SELCTION_MESSAGE' | translate}}</span>
                            <span class="specify-text">{{'i18n.APP_SPECIFIC.STM.SPECIFY_MANUALLY' | translate}}</span>
                            <div class="manual-row-creation">
                                <input id="manual_row_input" [(ngModel)]="manualRowInput" type="number" min="0">
                                <button (click)="applyManualRowSelection()">{{'i18n.GLOBAL.APPLY' | translate}}</button>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td>{{'i18n.APP_SPECIFIC.STM.MAIN_DATA_MEASUREMENTS.TREE_CIRCUMFERENCE' | translate}}</td>
                <td><input [(ngModel)]="circumference"  placeholder="0.0"  min="0" type="number" step="0.1" ></td>
            </tr>
            <tr>
                <td>{{'i18n.APP_SPECIFIC.STM.MAIN_DATA_MEASUREMENTS.TREE_TYPE' | translate}}</td>
                <td>
                    <div class="tree-type-selector">
                        <div class="tree-type-input">
                            <button (click)="showTreeTypeDropDown()">
                                <span *ngIf="selectedTreeType == ''">{{'i18n.GLOBAL.SELECT' | translate}}</span>
                                <span *ngIf="selectedTreeType != ''">{{selectedTreeType}}</span>
                                
                            </button>
                        </div>
                        <div class="tree-type-dropdown" *ngIf="showTreeTypeDropdown">
                            <ul>
                                <li>
                                    <input [(ngModel)]="treeTypeInputFilter" 
                            (input)="onTypeTreeTypeInput($event)"
                            placeholder="{{'i18n.APP_SPECIFIC.STM.TREE_TYPE_SEARCH' | translate}}"
                            type="text">
                                </li>
                                <li *ngFor="let treeType of this.filteredTreeTypes" (click)="onTreeTypeSelect(treeType)">
                                    <span class="tree-name">
                                        {{treeType.name}}
                                    </span>
                                </li>
                            </ul>
                        </div>

                    </div>
                </td>
            </tr>
            <tr>
                <td>{{'i18n.APP_SPECIFIC.STM.META_DATA_MEASUREMENTS.DAMAGED' | translate}}</td>
                <td>
                    <div class="bool-option-container">
                        <label>
                            <input name="damaged" type="radio" [(ngModel)]="damaged" [value]="true">
                            {{'i18n.STATISTIC_PAGE.STM_STATISTICS.TRUE' | translate}}
                        </label>
                        <label>
                            <input name="damaged" type="radio" [(ngModel)]="damaged" [value]="false">
                            {{'i18n.STATISTIC_PAGE.STM_STATISTICS.FALSE' | translate}}
                        </label>
                    </div>
                   
                </td>
            </tr>
            <tr>
                <td>{{'i18n.APP_SPECIFIC.STM.META_DATA_MEASUREMENTS.NOTE' | translate}}</td>
                <td><input [(ngModel)]="note" placeholder="" type="text"></td>
            </tr>
            <tr>
                <td>{{'i18n.APP_SPECIFIC.STM.META_DATA_MEASUREMENTS.AB_QUALITY' | translate}}</td>
                <td>
                    <div class="ab-quality-dropdown">
                        <button (click)="handleChooseABQualityButton()">
                            <span *ngIf="abQualitySelected == ''">{{'i18n.APP_SPECIFIC.STM.CHOOSE_AB_QUALITY' | translate}}</span>
                            <span *ngIf="abQualitySelected != ''">{{abQualitySelected}}</span>
                        </button>
                        <div class="ab-quality-dropdown-menu" *ngIf="showABQualiltyDropdown">
                            <ul>
                                <li (click)="handleSelectABQuality(item)" *ngFor="let item of abQualityList">{{item}}</li>
                            </ul>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td>{{'i18n.APP_SPECIFIC.STM.META_DATA_MEASUREMENTS.HAS_POLE' | translate}}</td>
                <td>
                    <div class="bool-option-container">
                        <label>
                            <input name="hasPole" type="radio" [(ngModel)]="hasPole" [value]="true">
                            {{'i18n.STATISTIC_PAGE.STM_STATISTICS.TRUE' | translate}}
                        </label>
                        <label>
                            <input name="hasPole" type="radio" [(ngModel)]="hasPole" [value]="false">
                            {{'i18n.STATISTIC_PAGE.STM_STATISTICS.FALSE' | translate}}
                        </label>
                    </div>
                    
                </td>
            </tr>
        </table>
    </div>

    <div *ngIf="showSuccessMessage" class="success-messaage">
        <div class="message">
            {{'i18n.APP_SPECIFIC.STM.CREATION_SUCCESS_MESSAGE' | translate}}
        </div>
        <div class="progress-bar">
            <div class="progress-bar-filler"></div>
        </div>
    </div>

    <div *ngIf="showErrorMessage.show" class="error-messaage">
        <div class="message">
            {{showErrorMessage.message}}
        </div>
    </div>

    <div  *ngIf="!showSuccessMessage" class="bottom-btns">
        <button (click)="createTreePoint()">{{'i18n.GLOBAL.CREATE' | translate}}</button>
        <button class="cancel" (click)="cancelCreation()">{{'i18n.GLOBAL.CANCEL' | translate}}</button>
    </div>
    
   
</div>