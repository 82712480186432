import { Component, Input } from '@angular/core';

@Component({
  selector: 'stem-thickness-meta-data-component',
  templateUrl: './stem-thickness-meta-data.component.html',
  styleUrls: ['./stem-thickness-meta-data.component.css']
})

export class StemThicknessMetaData  {
    @Input() isVisible: any;
    @Input() displayData: any;
}