import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { IField } from 'src/app/models/field.model';
import { IListResponse } from 'src/app/models/global.model';
import { FieldService } from '../../services/field.service';
import { PortalAppsService } from 'src/app/services/portal-apps/portal-apps.service';
import { expand, of, takeWhile } from 'rxjs';
import { CacheService } from 'src/app/services/cache.service';

@Component({
  selector: 'app-fields-page',
  templateUrl: './fields-page.component.html',
  styleUrls: ['./fields-page.component.css'],
})
export class FieldsPageComponent implements AfterViewInit, OnInit {
  constructor(private fieldService: FieldService, private changeDetector: ChangeDetectorRef, private router: Router, private route: ActivatedRoute, private portalAppsService: PortalAppsService, private cacheService: CacheService) { }

  @ViewChildren('test') test: QueryList<ElementRef<HTMLLIElement>>;

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {

    // Check if the click target is outside the component's element
    const sortOptions = document.getElementsByClassName("sort-options")[0];
    const optionButton = document.getElementsByClassName("toggle-sort-btn")[0];
    if(sortOptions && optionButton){
      if(!sortOptions.contains(event.target as Node) && !optionButton.contains(event.target as Node)){
        this.sortingMenuOpen = false;
      }
    }
  }


  activeAppCode;
  fields = [];
  showingFields = [];

  activePage = 1;

  pages = [];
  amountPerPage = 9;

  selectedField;
  dimensions = { width: null, height: null };

  totalFieldAmount;

  fieldList: IListResponse<IField>;
  fieldsToDisplay;

  filteredFields;

  isLoading = false;

  sortingMenuOpen: boolean = false;
  sortingOptions = [
    "i18n.FIELDS_PAGE.SORT_NAME_AZ",
    "i18n.FIELDS_PAGE.SORT_NAME_ZA",
    "i18n.FIELDS_PAGE.SORT_DATE_CREATED_LATEST",
    "i18n.FIELDS_PAGE.SORT_DATE_CREATED_OLDEST",
    "i18n.FIELDS_PAGE.SORT_DATE_UPDATED_LATEST",
    "i18n.FIELDS_PAGE.SORT_DATE_UPDATED_OLDEST"
  ]
  selectedSort: string;
  nameFilterInput: string = "";

  queryParams;

  ngOnInit(): void {
    this.activeAppCode = localStorage.getItem("active_app_code_name");
    this.getFields(0);
    this.totalFieldAmount = null;
    this.getQueryParams();
  }

  async ngAfterViewInit() {
    this.test.changes.subscribe((list) => {
      list.forEach((element) => {
        this.dimensions.width = element.nativeElement.offsetWidth;
        this.dimensions.height = element.nativeElement.offsetHeight;
      });
    });


  }

  ngAfterContentChecked(): void {

    this.changeDetector.detectChanges();
  }

  getQueryParams(){
    this.route.queryParams.subscribe(params => {
      this.queryParams = {};
      for (let paramKey in params){
        this.queryParams[paramKey] = decodeURIComponent(params[paramKey])
      }
    });
  }

  async applyParams(){

    if(this.queryParams["name-filter"]){
      this.nameFilterInput = this.queryParams["name-filter"];
      this.handleNameFilterInput(this.nameFilterInput);
    }

    if(this.queryParams["sort-by"]){
      this.selectedSort = this.queryParams["sort-by"];
      this.applyFieldSorting(this.selectedSort);
    }

    if(this.queryParams.page){
      this.showFieldsForPage(Number(this.queryParams.page));
    }
    else{
      this.showFieldsForPage(1);
    }
  }

  setQueryParam(key, value){
      // Retrieve the current query params
  const queryParams = { ...this.route.snapshot.queryParams };



  // If the value is null or undefined, remove the parameter (no need to encode when removing)
  if (value == null || value === '') {
    queryParams[key] = null;
  } else {
    // Encode the value only when adding to the URL
    queryParams[key] = encodeURIComponent(value);
  }

  this.router.navigate([], {
    queryParams: queryParams, // Empty query params object
    queryParamsHandling: 'merge', // Use 'merge' initially to ensure it tries merging with no query params
    replaceUrl: false // Optional: Replace current history entry to avoid adding a new one
  })


  }

  getFields(offset = 0) {
    const measurementAppIds = Object.values(this.portalAppsService.activeApp.measurementAppsMap);
    const amountPerPage = this.amountPerPage;
    const fieldsSet = new Set();
    this.fieldList = {
      items: [],
      limit: amountPerPage,
      offset: 0,
      total: 0
    };
    this.pages = [];

    const fetchFieldsForApp = (appId, offset) => {
      return this.fieldService.get_fields_for_app(appId, offset, amountPerPage);
    };

    const fetchAllFields = async () => {

      this.fieldsToDisplay = [];

      if(this.cacheService.hasData("fields-overview-data")){
        this.fieldList = this.cacheService.getData("fields-overview-data");
        await this.calculatePages();
        this.applyParams();
      }
      else{
        this.isLoading = true;
        for (const appId of measurementAppIds) {
          let currentOffset = offset;
          let moreData = true;

          // new
          fetchFieldsForApp(appId, currentOffset).pipe(
            expand(data => {
              data.items.forEach(item => {
                if (!fieldsSet.has(item.id)) {
                  fieldsSet.add(item.id);
                  this.fieldList.items.push(item);
                }
              });


              // Update currentOffset for the next loop iteration
              currentOffset += data.items.length;
              this.updateLoaderFiller(currentOffset, data.total);

              // Determine if there's more data to fetch
              const moreData = currentOffset < data.total;


              if(!moreData){
                this.cacheService.setData("fields-overview-data", this.fieldList)
                this.updateLoaderFiller(data.total, data.total);
                setTimeout(async () => {
                  // add handle for nameFilter and Sort...
                  await this.calculatePages();
                  this.applyParams();
                  this.isLoading = false;

                }, 200)
              }

              // Return a new Observable for the next iteration or a completed Observable
              return moreData ? fetchFieldsForApp(appId, currentOffset) : of(null);
            }),
            takeWhile(data => data !== null)
          ).subscribe(
            {
              next: () => {
                // Additional actions after each successful fetch can be placed here
              },
              error: error => {
                console.error(`Failed to fetch fields for appId ${appId}:`, error);
              }
            }
          )
        }
      }

    };


    fetchAllFields();
  }

  async calculatePages(): Promise<void>{
    this.pages = [];
    let page = 1;

    return new Promise((resolve) => {
      if (this.filteredFields != null) {
        while (page <= Math.ceil(this.filteredFields.length / this.amountPerPage)) {
          this.pages.push(page);
          page++;
        }
      } else {
        while (page <= Math.ceil(this.fieldList.items.length / this.amountPerPage)) {
          this.pages.push(page);
          page++;
        }
      }

      resolve(); // Resolve the promise once the logic is completed
    });
  };

  toggleSortingMenu(){
    this.sortingMenuOpen = !this.sortingMenuOpen;
  }

  applyFieldSorting(type){
    const allFields = JSON.parse(JSON.stringify(this.filteredFields ? this.filteredFields : this.fieldList.items));
    // handle cases
    switch (type) {
      case this.sortingOptions[0]:
        this.filteredFields = allFields.sort((a,b) => {
          return a.name.localeCompare(b.name);
        })
        break;

      case this.sortingOptions[1]:
        this.filteredFields = allFields.sort((a,b) => {
          return b.name.localeCompare(a.name);
        })
        break;

      case this.sortingOptions[2]:
        this.filteredFields = allFields.sort((a,b) => {
          const aDate = new Date(a.created_at);
          const bDate = new Date(b.created_at);
          return bDate.getTime() - aDate.getTime();
        })
        break;

      case this.sortingOptions[3]:
        this.filteredFields = allFields.sort((a,b) => {
          const aDate = new Date(a.created_at);
          const bDate = new Date(b.created_at);
          return aDate.getTime() - bDate.getTime();
        })
        break;

      case this.sortingOptions[4]:
        this.filteredFields = allFields.sort((a,b) => {
          const aDate = new Date(a.updated_at);
          const bDate = new Date(b.updated_at);
          return bDate.getTime() - aDate.getTime();
        })
        break;

      case this.sortingOptions[5]:
        this.filteredFields = allFields.sort((a,b) => {
          const aDate = new Date(a.updated_at);
          const bDate = new Date(b.updated_at);
          return aDate.getTime() - bDate.getTime();
        })
        break;
    }
  }

  async selectSortingItem(item){
    if(this.selectedSort != item){
      this.selectedSort = item;
      this.applyFieldSorting(item);
      await this.calculatePages();
      this.showFieldsForPage(this.activePage);
      this.setQueryParam("sort-by", item)
    }
    else{
      this.selectedSort = null;
      this.setQueryParam("sort-by", null)
      setTimeout(() => {
        this.handleNameFilterInput(this.nameFilterInput);
      })

    }
  }

  async handleNameFilterInput(text){
    const allFields = JSON.parse(JSON.stringify(this.fieldList.items));
    this.filteredFields = allFields.filter((field) => {
      return field.name.toLowerCase().includes(text);
    })

    if(text != ""){
      this.setQueryParam("name-filter", text);
    }
    else{
      this.setQueryParam("name-filter", null);
    }

    if(this.selectedSort != null){
      this.applyFieldSorting(this.selectedSort)
    }
    await this.calculatePages();
    if(this.queryParams['page'] && this.pages.includes(Number(this.queryParams['page']))){
      this.showFieldsForPage(Number(this.queryParams['page']));
    }
    else{
      this.showFieldsForPage(1);
    }
  }

  showFieldsForPage(pageNumber : number = 1){
    if(this.pages.includes(pageNumber)){
      this.activePage = pageNumber;
      const startingIndex = (pageNumber - 1)*this.amountPerPage;

      if(this.filteredFields != null){
        this.fieldsToDisplay = this.filteredFields.slice(startingIndex, startingIndex + this.amountPerPage);
      }
      else{
        this.fieldsToDisplay = this.fieldList.items.slice(startingIndex, startingIndex + this.amountPerPage);
      }
      this.setQueryParam("page", pageNumber);
    }
    else{
      if(this.pages.length == 0){
        this.fieldsToDisplay = [];
      }
    }
  }

  updateLoaderFiller(offset, total){
    const filler = document.getElementById("loader_filler");
    if(filler){
      const percentage = offset*100/total;
      filler.style.width = percentage + "%";
    }
  }


  changePage(page: number){
    if(this.pages.includes(page)){
      this.activePage = page;
      this.getFields((page - 1)*this.amountPerPage)
    }
  }

  deleteFieldButton(field: any, event: any) {
    event.stopPropagation(); //this can be achieved in the html, so rewriting this method could potentially result in lower code
    this.selectedField = field;
  }

  deleteSelectedField() {
    this.fieldService
      .delete_field(this.selectedField.field_id)
      .subscribe((res) => {
        this.getAccessibleFields();
        this.selectedField = undefined;
      });
  }

  getAccessibleFields() {
    // this.fieldService.get_accessible_fields(this.page, 100).subscribe((d) => {
    //   this.fields = d.fields;
    //   this.pages = [];
    //   this.paginate(this.page);
    //   let i = 1;
    //   while (Math.ceil((this.fields.length + 1) / 9) >= i) {
    //     this.pages.push(i);
    //     i++;
    //   }
    // });
  }

  getFieldMinMaxLonLat(coordinates) {
    const maxLon = Math.max(...[].concat(...coordinates.map((c) => c[0])));
    const minLon = Math.min(...[].concat(...coordinates.map((c) => c[0])));
    const maxLat = Math.max(...[].concat(...coordinates.map((c) => c[1])));
    const minLat = Math.min(...[].concat(...coordinates.map((c) => c[1])));
    return {
      minLon: minLon,
      maxLon: maxLon,
      minLat: minLat,
      maxLat: maxLat,
    };
  }

  getX(x, bounds) {
    let boxsize = this.dimensions.width;
    let multiplier = boxsize / (bounds.maxLon - bounds.minLon) * 2

    if (bounds.maxLat - bounds.minLat > (bounds.maxLon - bounds.minLon) * 0.5) {
      multiplier = boxsize / (bounds.maxLat - bounds.minLat)
    }

    let position = (x - bounds.minLon);
    return (multiplier * 0.5 * position + ((boxsize - multiplier / 2 * (bounds.maxLon - bounds.minLon)) / 2));
  }
  getY(y, bounds) {
    let boxsize = this.dimensions.height;
    let multiplier = boxsize / (bounds.maxLon - bounds.minLon) * 2

    if (bounds.maxLat - bounds.minLat > (bounds.maxLon - bounds.minLon) * 0.5) {
      multiplier = boxsize / (bounds.maxLat - bounds.minLat)
    }

    let position = (y - bounds.minLat);
    let result = (multiplier * position) - ((this.dimensions.height - multiplier * (bounds.maxLat - bounds.minLat)) / 2 + multiplier * (bounds.maxLat - bounds.minLat));
    return (-result);
  }
  getPoints(field) {
    let temp = [];
    field.boundary.coordinates[0].forEach((element) => {
      let bounds = this.getFieldMinMaxLonLat(element);
      for (let i = 0; i < element.map((c) => c).length; i++) {
        let x = this.getX(element.map((c) => c[0])[i], bounds);
        let y = this.getY(element.map((c) => c[1])[i], bounds);
        temp.push({ x, y });
      }
    });
    let xpoint = temp.map((item) => {
      return item['x']
    })
    let ypoint = temp.map((item) => {
      return item['y']
    })
    let attr = "";
    let result = "";
    for (let i = 0; i < xpoint.length; i++) {
      result = attr.concat(result, xpoint[i] + "," + ypoint[i] + " ")
    }
    return result;
  }

  archiveField(field: any) {
    this.fieldService.archive_field(field).subscribe(d => {
      this.getFields(0);
    });
  }

  editField(field: any) {
    //Functionallity to edit a field
  }

  go_to_field(fieldId, fieldCenter){
    localStorage.setItem('center', JSON.stringify(fieldCenter.coordinates))
    const route = "apps/" + this.activeAppCode + '/fields/fieldmap';
    this.router.navigate([route], {queryParams: {field_id: fieldId }});
  }

  navigateToFieldsMapOverview(){
    const route = "apps/" + this.activeAppCode + '/fields-map-overview';
    this.router.navigate([route], {queryParams: {}});
  }
}
