import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule, AuthService } from '@auth0/auth0-angular';
import { StoreModule } from '@ngrx/store';

import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { environment as env } from '../environments/environment';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {CommonModule} from '@angular/common';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';


import { MapFacade } from './dashboard/map/MapFacade';
import { mapReducer } from './dashboard/map/store/map.reducer';
import { DashboardComponent } from './dashboard/dashboard-mainLayout/dashboard.component';
import { FieldPageComponent } from './pages/field-page/field-page.component';
import { FieldsPageComponent } from './pages/fields-overview-page/fields-page.component';
import { AddFieldPageComponent } from './pages/add-field-page/add-field-page.component';
import { DataManagementPageComponent } from './pages/data-management-page/data-management-page.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions-page/terms-and-conditions.component';

import { StatisticsPageComponent } from './pages/statistics-page/statistics-page.component';

import { ProfilePageComponent } from './pages/profile-page/profile-page.component';

import { ChangePasswordDialogComponent } from './dialogs/change-password-dialog/change-password-dialog.component';

import { FooterComponent } from './shared/layout/footer/footer.component';
import { NavbarComponent } from './shared/layout/navbar/navbar.component';
import { ButtonComponent } from './shared/layout/button/button.component';
import { LogoComponent } from './shared/layout/Logo/logo.component';
import { InfoBtnComponent } from './shared/layout/info-btn/info-btn.component';

import { DevicesListComponent } from './device/devices-list/devices-list.component';
import { DevicePageComponent } from './pages/devices-page/device-page.component';
import { DeviceCardComponent } from './device/device-card/device-card.component';

import { CookieService } from 'ngx-cookie-service';
import { UntypedFormBuilder } from '@angular/forms';
import { SuperuserPageComponent } from './pages/superuser-page/superuser-page.component';
import { CompanyManagementPageComponent } from './pages/company-management-page/company-management-page.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatNativeDateModule } from '@angular/material/core';
import { FieldMapPageComponent } from './pages/field-map-page/field-map-page.component';
import { ApplicationsPageComponent } from './pages/applications-page/applications-page.component';
import { FieldsNavItem } from './shared/layout/navbar_components/fields-nav-item/fields-nav-item.component';
import { DynamicNavbarContentComponent } from './services/dynamic-navbar-component.service';
import { AppRouteResolver } from './services/portal-apps/appRouteResolver';
import { StatisticsNavItem } from './shared/layout/navbar_components/statistics-nav-item/statistics-nav-item.component';
import { DevicesNavItem } from './shared/layout/navbar_components/devices-nav-item/devices-nav-item.component';
import { StemThicknessMeasurementData } from './services/portal-apps/map-strategies/map-info-components/stem-thickness-measurement-data/stem-thickness-measurement-data.component';
import { DynamicMapEntityInfoContentComponent } from './services/dynamic-map-entity-info-component.service';
import { StemThicknessMetaData } from './services/portal-apps/map-strategies/map-info-components/stem-thickness-meta-data/stem-thickness-meta-data.component';
import { DynamicMapActionComponent } from './services/dynamic-map-action-component.service';
import { GoToFieldActionComponent } from './services/portal-apps/map-strategies/map-actions/go-to-field/go-to-field.components';
import { ToggleSTMLegendActionComponent } from './services/portal-apps/map-strategies/map-actions/toggle-legend/toggle-stm-legend.components';
import { StemThicknessLegend } from './services/portal-apps/map-strategies/map-info-components/stem-thickness-legend/stem-thickness-legend.component';
import { STMStatisticsPageComponent } from './services/portal-apps/app-statistics/stm-statistics-page/stm-statistics-page.component';
import { DynamicMapPointControlComponent } from './services/dynamic-map-point-control';
import { MapRowSelectionComponent } from './services/portal-apps/map-strategies/map-points-selection/row-selection/map-row-selection.component';
import { DynamicMapPopupComponent } from './services/dynamic-map-popup-component';
import { MapTreeSelectionComponent } from './services/portal-apps/map-strategies/map-points-selection/tree-selection/map-tree-selection.component';
import { DynamicMapFloatingContainerComponent } from './services/dynamic-map-floating-container-component';
import { SelectedPointsComponent } from './services/portal-apps/map-strategies/floating-container-components/selected-points-component/selected-points.component';
import { MapLassoSelectionComponent } from './services/portal-apps/map-strategies/map-points-selection/lasso-selection/map-lasso-selection.component';
import { StemThicknessMeasurementMutation } from './services/portal-apps/map-strategies/map-info-components/stem-thickness-mutation/stem-thickness-measurement-mutation';
import { StmTreePointCreationComponent } from './services/portal-apps/map-strategies/map-points-creation/stm-tree-point-creation/stm-tree-point-creation.component';
import { StemThicknessMeasurementCreation } from './services/portal-apps/map-strategies/map-info-components/stem-thickness-creation/stem-thickness-measurement-creation';
import { EventBusService } from './services/event-bus.service';
import { StmTreeRowFillCreationComponent } from './services/portal-apps/map-strategies/map-points-creation/stm-tree-row-fill-creation/stm-tree-row-fill-creation.component';
import { StemThicknessMeasurementRowFillCreation } from './services/portal-apps/map-strategies/map-info-components/stem-thickness-row-fill-creation/stem-thickness-measurement-row-fill-creation';
import { HelpPageComponent } from './pages/help-page/help-page.component';
import { take } from 'rxjs';
import { FieldsOverviewMapPageComponent } from './pages/fields-overview-map-page/fields-overview-map-page.component';




export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function initializeApp(auth: AuthService) {
  return () => auth.isAuthenticated$.pipe(take(1)).toPromise();
}


@NgModule({
  declarations: [
    AppComponent,
    StatisticsPageComponent,
    DashboardComponent,
    FieldsPageComponent,
    FieldPageComponent,
    AddFieldPageComponent,
    DataManagementPageComponent,
    TermsAndConditionsComponent,
    DynamicNavbarContentComponent,
    DynamicMapEntityInfoContentComponent,
    DynamicMapActionComponent,
    DynamicMapPointControlComponent,
    DynamicMapPopupComponent,
    DynamicMapFloatingContainerComponent,
    ProfilePageComponent,
    ChangePasswordDialogComponent,

    FooterComponent,
    NavbarComponent,
    ButtonComponent,
    LogoComponent,
    InfoBtnComponent,

    LandingPageComponent,

    DevicesListComponent,
    DevicePageComponent,
    DeviceCardComponent,
    SuperuserPageComponent,
    CompanyManagementPageComponent,
    FieldMapPageComponent,
    ApplicationsPageComponent,
    FieldsNavItem,
    StatisticsNavItem,
    DevicesNavItem,
    StemThicknessMeasurementData,
    StemThicknessMetaData,
    GoToFieldActionComponent,
    ToggleSTMLegendActionComponent,
    StemThicknessLegend,
    STMStatisticsPageComponent,
    MapRowSelectionComponent,
    MapTreeSelectionComponent,
    SelectedPointsComponent,
    MapLassoSelectionComponent,
    StemThicknessMeasurementMutation,
    StmTreePointCreationComponent,
    StemThicknessMeasurementCreation,
    StmTreeRowFillCreationComponent,
    StemThicknessMeasurementRowFillCreation,
    HelpPageComponent,
    FieldsOverviewMapPageComponent
  ],
  imports:  [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    MatDialogModule,
    // AuthModule.forRoot({
    //   ...env.auth,
    //   httpInterceptor: {
    //     ...env.httpInterceptor,
    //   },
    // }),

    AuthModule.forRoot({
      ...env.auth,

      httpInterceptor: {
        ...env.httpInterceptor,
      },
      authorizationParams: {
        audience: "https://agrowizard.eu.auth0.com/api/v2/",
        redirect_uri: window.location.origin,
        scope: 'openid profile email'
      }
    }),
    BrowserAnimationsModule,
    NoopAnimationsModule,
    CommonModule,
    FormsModule,
    MatSelectModule,
    MatCheckboxModule,
    MatMenuModule,
    MatIconModule,
    NgxMapboxGLModule,
    NgxMapboxGLModule.withConfig({
      accessToken:
        'pk.eyJ1IjoiZmxvcmlzcGV0ZXJzIiwiYSI6ImNrcjM2MzU2NjB3aWYycnFtbno2ampqYzgifQ.A-51Hqh4pULfmnMntVst7Q',
    }),
    MatDatepickerModule,
    MatNativeDateModule,
    MatDividerModule,
    MatSlideToggleModule,
    MatSidenavModule,
    MatCardModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    StoreModule.forRoot({}),
    StoreModule.forFeature('mapReducer', mapReducer),
    RouterModule,
  ],
  providers: [
    AppRouteResolver,
    MapFacade,
    HttpClient,
    ButtonComponent, LogoComponent, NavbarComponent, InfoBtnComponent, HttpClientModule, UntypedFormBuilder, CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AuthService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
