import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import mapboxgl from 'mapbox-gl';
import { IField } from 'src/app/models/field.model';
import { FieldService } from 'src/app/services/field.service';
import { IPortalApp } from 'src/app/services/portal-apps/iportal-app';
import { IMapStrategy } from 'src/app/services/portal-apps/map-strategies/iMapStrategy';
import { companyLogoLayer } from 'src/app/services/portal-apps/map-strategies/map-layers/layers/company-logo-layer.function';
import { fieldBoundaryLayer } from 'src/app/services/portal-apps/map-strategies/map-layers/layers/field-boundery-layer.function';
import { companyLogoSource } from 'src/app/services/portal-apps/map-strategies/map-layers/sources/company-logo-source.function';
import { fieldBoundarySource } from 'src/app/services/portal-apps/map-strategies/map-layers/sources/field-boundary-source.function';
import { PortalAppsService } from 'src/app/services/portal-apps/portal-apps.service';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-field-map-page',
  templateUrl: './field-map-page.component.html',
  styleUrls: ['./field-map-page.component.css']
})
export class FieldMapPageComponent {
  map: mapboxgl.Map;

  constructor(private activatedRoute: ActivatedRoute, private fieldService: FieldService, private portalAppsService: PortalAppsService) { }
  portalAppMapStrategy: IMapStrategy;

  // MAP VARIABLES
  startZoom = 16

  // right menu close prop
  rightMenuScroll = 0;


  // tree measurment data layer storage
  dataStore_TreeMeasurmentData: { [date: string]: {} } = {}; // for excel export


  //Field data
  selectedField: IField
  availibleDates: string[] = []
  selectedDate: string;
  orgId: string;
  searchedDate = ''


  // HOVER DATA INFO
  metaHoverData: any = {
    value: {}
  };
  mainHoverData: any = {
    value: {
    }
  } 
  customerAddedTreeData = []

  // Side info menu toggle
  showEntityInfo = {value: false};

  ngOnInit(): void {
    this.portalAppMapStrategy = this.portalAppsService.activeApp.mapStrategy;
    mapboxgl.accessToken = environment.mapbox.accessToken;
    this.map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/outdoors-v12',
      center: JSON.parse(localStorage.getItem('center')),
      zoom: this.startZoom
    });

    this.portalAppMapStrategy.setMapInstance(this.map);
    

    this.portalAppMapStrategy.bindHoverData(this.mainHoverData, this.metaHoverData);
    this.portalAppMapStrategy.bindShowLeftMenu(this.showEntityInfo);
    // Now, the variables in the parent component will reflect the changes made in the child component
    

    
    this.orgId = JSON.parse(localStorage.getItem('org_id'))[0].id;
    // getting the current field
    this.activatedRoute.queryParams.subscribe(params => {
      this.fieldService.get_field(params.field_id).subscribe(result => {
        this.selectedField = result as IField;
        this.portalAppMapStrategy.setField(result);
        let sorted_dates = []
        for (let date of result.layers.tree_measurement_processed.available_dates) {
          this.availibleDates.push(date);
        }

        this.availibleDates = this.availibleDates.sort().reverse();

        if(this.availibleDates.length > 0){
          this.selectedDate = this.availibleDates[0]
        }
        else{
          const today = new Date();

          const year = today.getFullYear();
          const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
          const day = String(today.getDate()).padStart(2, '0');
          this.selectedDate = `${year}-${month}-${day}`;
        }
        
        

        this.dateChange(this.selectedDate).then(() => {
          // add all layers with their source 
          this.map.on('load', () => {
            this.addSourcesAndLayers();
            window.mapInstance = this.map;
          })
        })
      })
    })




  }

  addSourcesAndLayers() {  
    this.portalAppMapStrategy.addAppSpecificSources()
    .then(() => {
      this.portalAppMapStrategy.addAppSpecificLayers();
      this.portalAppMapStrategy.handleAppSpecificEvents();  
    })
      
  }

  changeMapStyle(e: any) {

    let style = 'mapbox://styles/mapbox/outdoors-v12'
    if (e.currentTarget.checked) {
      style = 'mapbox://styles/mapbox/satellite-streets-v12'
    }

    let data_loaded = false;

    // Update the map style URL with the desired style
    this.map.setStyle(style);

    //re-add all layers with previously loaded data
    this.map.on('style.load', () => {
      if (!data_loaded) {
        data_loaded = true //needed or it gets called twice
        this.portalAppMapStrategy.addAppSpecificSources().then(() => {
          this.portalAppMapStrategy.addAppSpecificLayers();
        });

      }
    })
  }

  async dateChange(date) {
    this.selectedDate = date;
    await this.portalAppMapStrategy.loadData(this.selectedDate,this.orgId);
  }


  showhideLayer(checkbox: any) {
    // Toggle layer visibility by changing the layout object's visibility property.    
    if (checkbox.currentTarget.checked) {
      this.portalAppMapStrategy.changeLayers("add", checkbox.currentTarget.value)
      // this.visibleLayers.push(checkbox.currentTarget.value);
    } else {
      this.portalAppMapStrategy.changeLayers("remove", checkbox.currentTarget.value)
      // this.visibleLayers = this.visibleLayers.filter((item) => item !== checkbox.currentTarget.value);
    }

    this.map.setLayoutProperty(checkbox.currentTarget.value, 'visibility', (checkbox.currentTarget.checked ? 'visible' : 'none'));
  }




  toggle_element(element_id: string, e: HTMLElement) {
    var el = document.getElementById(element_id)
    const floatingContainer = document.getElementsByClassName("floating-container")[0] as HTMLElement;
    let mobileIcon = document.getElementsByClassName("open-info-menu-container")[0] as HTMLElement;
    mobileIcon = mobileIcon.querySelector(".desktop");
    const computedStyle = window.getComputedStyle(mobileIcon);
    const isMobile = computedStyle.display == "none"
    if (el.style.visibility == 'hidden') {
      el.style.visibility = 'visible';
      if(floatingContainer != null && !isMobile){
        
        floatingContainer.style.right = "303px";
      }
    }
    else {
      el.style.visibility = 'hidden';
      if(floatingContainer != null){
        floatingContainer.style.right = "5vw";
      }
    }
    e.classList.toggle("change")
  }


  exportTableToExcel() {
    this.portalAppMapStrategy.exportTableToExcel(this.selectedDate);
  }



  isSwitchedOn: boolean = false;

  toggleSwitch() {
    this.isSwitchedOn = !this.isSwitchedOn;

    // You can add logic here to perform actions based on the switch's state.
    if (this.isSwitchedOn) {
      // Perform actions when the switch is turned on.
    } else {
      // Perform actions when the switch is turned off.
    }
  }

  toggleEntityInfoBox(){
    this.showEntityInfo.value = !this.showEntityInfo.value;
  }

  onRightMenuScroll(event: any): void{
    this.rightMenuScroll =  event.target.scrollTop;
  }
}