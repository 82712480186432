import { Component, Input } from '@angular/core';
import { PortalAppsService } from '../../../portal-apps.service';

@Component({
  selector: 'map-lasso-selection-component',
  templateUrl: './map-lasso-selection.component.html',
  styleUrls: ['./map-lasso-selection.component.css']
})

export class MapLassoSelectionComponent  {

    mapStrategy;
    constructor(private portalAppsService: PortalAppsService){
        this.mapStrategy = this.portalAppsService.activeApp.mapStrategy;
    }


    openLassoSelection(){
        this.mapStrategy.changePointSelectionMode("lasso");
    }


}