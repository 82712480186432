import { Component, Input, OnInit , HostListener, OnDestroy} from '@angular/core';
import { PortalAppsService } from '../../../portal-apps.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { EventBusService } from 'src/app/services/event-bus.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'stem-thickness-measurement-row-fill-creation-component',
  templateUrl: './stem-thickness-measurement-row-fill-creation.component.html',
  styleUrls: ['./stem-thickness-measurement-row-fill-creation.component.css']
})

export class StemThicknessMeasurementRowFillCreation implements OnInit {
    @Input() isVisible: any = true;
    @Input() displayData: any;

    // tree generation
    plantingDistance: any = "";

    // tree params
    circumference: number = null;;
    bucketList = ["0-8","8-10", "10-12", "12-14", "14-16", "16-18", "18-20", "20-25", "25-30", "30-35", "35-40", "40-45", "45-50", "50-60", "60-70", "70-80", "80-90", "90-175", "175-200", "200-250", "250-300", "300-350", "350-400", "400-450", "450-500", "500-550", "550-600", "600-700", "700-800", "800-900", "900-*"];
    treeTypeInputFilter = "";
    selectedTreeType = "";
    damaged: boolean | null = null;
    hasPole: boolean | null = null;
    note = "";

    // row
    manualRowInput: number = 0;

    // ab
    abQualitySelected = "";
    abQualityList = ["a", "b", "c", "d", "e", "f", "NULL"];
    showABQualiltyDropdown = false;

    // tree types
    availableTreeTypes = [];
    filteredTreeTypes = [];
    showTreeTypeDropdown = false;

    // messages
    showSuccessMessage = false;
    showErrorMessage = {show: false, message: ""};

    mapStrategy;
    constructor(private portalAppsService: PortalAppsService, private organizationService: OrganizationService, private eventBusService: EventBusService, private translate: TranslateService){
        this.mapStrategy = this.portalAppsService.activeApp.mapStrategy;
    }

    // to receive events
    private subscription: Subscription = new Subscription();


    ngOnInit(): void {
      this.subscription.add(
        this.eventBusService.events$.subscribe(data => {
          // Handle the event
          if(data.target == "creationComponent"){
            if(data.action == "error"){
              this.showErrorMessage = {show: true, message: this.translate.instant("i18n.APP_SPECIFIC.STM.CREATION_ERROR")};
            }
            if(data.action == "success"){
              this.showSuccessMessage = true;
              this.mapStrategy.removePlantingLine();
            }
          }
        })
      );
      this.renderData();

      this.treeTypeInputFilter = "";
      this.showSuccessMessage = false;
    }

    renderData(){
      if(this.displayData.value != null){
        this.circumference = this.displayData.value.circumference || null;
        this.selectedTreeType = this.displayData.value.treeType || "";
        this.damaged = this.displayData.value.damaged;
        this.hasPole = this.displayData.value.hasPole;
        this.note = this.displayData.value.note || "";
        this.abQualitySelected = this.displayData.value.abQuality || "";
      }
      else{
        this.circumference = null;
        this.selectedTreeType = "";
        this.damaged = null;
        this.hasPole = null;
        this.note = "";
        this.abQualitySelected = "";
      }
      this.fetchTreeTypes();
    }

    ngOnDestroy() {
      this.showErrorMessage.show = false;
      this.showErrorMessage.message = "";
      this.subscription.unsubscribe();
    }

    // TREE TYPES SELECTION
    fetchTreeTypes(): void{
      this.organizationService.get_tree_types().subscribe(data => {
        
        this.availableTreeTypes = data.collection.sort(function (a, b) { return (a.name > b.name) ? 1 : -1 });
        this.filterTreeTypes();
      });
    }

    showTreeTypeDropDown(): void{
      this.showTreeTypeDropdown = true;
      this.treeTypeInputFilter = "";
    }

    formatPlantingDistance(): void {
      if (this.plantingDistance !== null && this.plantingDistance !== undefined) {
        // Convert the number to a string, replace dot with comma
        let formattedValue = this.plantingDistance.toString().replace('.', ',');
  
        // Update the plantingDistance with the formatted value
        this.plantingDistance = formattedValue as unknown as number;
      }
    }
    

    getRowIndexFromPoint(){
      this.mapStrategy.isRowSelectionOn = true;

    }

    private isDropdownClicked: boolean = false;

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: MouseEvent) {
      // Reset the flag when focus is in the document
      

      const target = event.target as HTMLElement;
  
      let containerTreeType = document.getElementsByClassName("tree-type-selector")[0];
      let containerABQulaity = document.getElementsByClassName("ab-quality-dropdown")[0];
      
      // Check if the click was inside the tree type dropdown
      if(!containerTreeType.contains(target)){
        this.showTreeTypeDropdown = false;
      }

      // Check if the click was inside the AB dropdown
      if(!containerABQulaity.contains(target)){
        this.showABQualiltyDropdown = false;
      }
      
    }

    filterTreeTypes(): void{
      let tempTreeTypes = JSON.parse(JSON.stringify(this.availableTreeTypes)); // makeing a deep copy to avoid referencing. 
      if(this.treeTypeInputFilter != ""){
        tempTreeTypes = tempTreeTypes.filter((item) => {
          return item.name.includes(this.treeTypeInputFilter)
        })
      }
      this.filteredTreeTypes = tempTreeTypes;
    }

    onFocusTreeTypeInput(): void{
      this.showTreeTypeDropdown = true;
    }

    onFocusOutTreeTypeInput(event): void{
      const dropdown = document.getElementsByClassName("tree-type-dropdown")[0];
      if(dropdown){
        if(!this.isDropdownClicked){
          this.showTreeTypeDropdown = false;
        }
      }
      
    }

    onTypeTreeTypeInput(event): void{
      this.showTreeTypeDropdown = true;
      this.filterTreeTypes();
    }

    onTreeTypeSelect(treeTypeSelected){
      this.selectedTreeType = treeTypeSelected.name;
      this.showTreeTypeDropdown = false;
      this.filteredTreeTypes = JSON.parse(JSON.stringify(this.availableTreeTypes));
    }

    formatToSingleDecimal(value: any): string {
      // Ensure the value is a number and format it with one decimal place
      return parseFloat(value).toFixed(1);
    }

    findBucket(value: number): string | null {
      if(value){
        for (let i = 0; i < this.bucketList.length; i++) {
          const [min, max] = this.bucketList[i].split('-');
    
          if (max === '*') {
            // Handle the special case where max is '*', meaning any value >= min
            if (value >= Number(min)) {
              return this.bucketList[i];
            }
          } else {
            // Standard bucket check
            const minNum = Number(min);
            const maxNum = Number(max);
    
    
            if (value >= minNum && value < maxNum) {
              return this.bucketList[i];
            }
          }
        }
    
        // If no bucket is found, return null or an appropriate message
        return null;
      }
    }

    // ab quality
    handleChooseABQualityButton(): void{
      this.showABQualiltyDropdown = true;
    }

    handleSelectABQuality(item): void{
      if(item != "NULL"){
        this.abQualitySelected = item;
      }
      else{
        this.abQualitySelected = "";
      }
      
      this.showABQualiltyDropdown = false;
    }


    async createTreePoints(){

      if(this.displayData.value != null && this.displayData.value.rowIndex != null){
        const inputs = {
          rowIndex: this.displayData.value.rowIndex,
          circumference : this.circumference,
          bucket : this.findBucket(this.circumference),
          treeType : this.selectedTreeType != "" ? this.selectedTreeType : null,
          hasPole : this.hasPole,
          damaged : this.damaged,
          note : this.note != "" ? this.note : false,
          abQuality : this.abQualitySelected != "" ? this.abQualitySelected : null,
        } 

        this.mapStrategy.createNewStmTrees(inputs);
      }
      else{
        this.showErrorMessage.show = true;
        this.showErrorMessage.message = this.translate.instant('i18n.APP_SPECIFIC.STM.SELECT_ROW_ERROR'); // translate
      }   
    }

    // Point generation
    selectPointA(){
      this.mapStrategy.selectPointAForTreeGen();
    }

    selectPointB(){
      this.mapStrategy.selectPointBForTreeGen();
    }

    generatePointsOnPlantingLine(){
      this.mapStrategy.generateTreesOnPlantingLine(this.plantingDistance);
    }

    clearPlantingRow(){
      this.mapStrategy.clearNewTreePoints();
      this.mapStrategy.removePlantingLine();
    }

    cancelCreation(){
      this.mapStrategy.hanldeChangePointCreationMode(this.mapStrategy.pointCreationMode.value);
    }

    applyManualRowSelection(){
      if(this.manualRowInput > 0 && Number.isInteger(this.manualRowInput)){
        this.displayData.value.rowIndex = this.manualRowInput;
        this.mapStrategy.isRowSelectionOn = false;
        this.manualRowInput = 0;
      }
      else{
        const inputElement = document.getElementById("manual_row_input");
        if(inputElement){
          inputElement.style.border = "solid red 2px";
        }
      }
    }
}