import { Component, Input } from '@angular/core';

@Component({
  selector: 'stem-thickness-measurement-data-component',
  templateUrl: './stem-thickness-measurement-data.component.html',
  styleUrls: ['./stem-thickness-measurement-data.component.css']
})

export class StemThicknessMeasurementData  {
    @Input() isVisible: any;
    @Input() displayData: any;


    getFormatedCircumference(){
      const value = this.displayData.value.circumference;

      if(localStorage.getItem('lang')){
        const language = localStorage.getItem('lang');
        if(language == "nld"){
          let decimalValue = value.toFixed(1);
          return decimalValue.replace('.', ',');
        }
        else{
          return value.toFixed(1);
        }
      }
      return value;
    }
}