<div class="spacing_non_map_pages">
  <h1 class="global_title">{{'i18n.PROFILE_PAGE.TITLE' | translate}}</h1>

  <div class="global_tile tile">
    <div class="user_welcome" *ngIf="userInfo">
      <svg class="profile_svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="62"
        height="62" viewBox="0 0 62 62">
        <path fill-rule="evenodd" fill="#6356A1"
          d="M48.691,21.938 C48.691,31.836 40.664,39.865 30.763,39.865 C20.863,39.865 12.836,31.836 12.836,21.938 C12.836,12.035 20.863,4.008 30.763,4.008 C40.664,4.008 48.691,12.035 48.691,21.938 ZM44.598,40.326 C40.741,43.238 35.957,44.987 30.763,44.987 C25.565,44.987 20.778,43.233 16.916,40.321 C6.488,44.913 0.031,59.211 0.031,65.473 L61.496,65.473 C61.496,59.267 54.837,44.969 44.598,40.326 Z" />
      </svg>

      <div class="user_welcome_txt">
        <p>{{'i18n.PROFILE_PAGE.WELCOME' |
          translate}}</p>
        <p>{{userInfo.first_name}}</p>
      </div>
    </div>

    <form class="profile_form" *ngIf="userInfo">
      <label class="text input-label">{{'i18n.PROFILE_PAGE.EMAIL' | translate}}</label>
      <input matInput type="email" class="global_input_field" placeholder="{{'i18n.PROFILE_PAGE.EMAIL' | translate}}"
        [readonly]=true value="{{userInfo.email}}">

      <label class="text input-label">{{'i18n.PROFILE_PAGE.DISPLAY_NAME' | translate}}</label>
      <div class="name_container">
        <input matInput type="text" class="global_input_field"
          placeholder="{{'i18n.PROFILE_PAGE.FIRST_NAME' | translate}}" [readonly]="!editingProfile"
          value="{{userInfo.first_name}}" id="firstName">
        <span style="width: 0.5em;"></span>
        <input matInput type="text" class="global_input_field"
          placeholder="{{'i18n.PROFILE_PAGE.LAST_NAME' | translate}}" [readonly]="!editingProfile"
          value="{{userInfo.last_name}}" id="lastName">
      </div>

    </form>
  </div>

  <div class="edit_btns">
    <button class="global_btn purple_btn" *ngIf="!editingProfile"
      (click)="editingProfile = true">{{'i18n.PROFILE_PAGE.UPDATE_PROFILE' | translate}}</button>
    <div class="edit_btns_duo" *ngIf="editingProfile">
      <button class="global_btn purple_btn" (click)="updateProfile(); editingProfile = false">{{'i18n.GLOBAL.SAVE' | translate}}</button>
      <button class="global_btn" (click)="editingProfile = false">{{'i18n.GLOBAL.CANCEL' | translate}}</button>
    </div>
  </div>
</div>