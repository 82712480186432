import * as mapActions from './map.action';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MapActionTypes } from './map.action';
import * as fromRoot from '../../../app';
export interface MapState {
  enableSatellite: boolean;
  enableBrpZone: boolean;
  brpZones?: [];
  treeWidth?: [];
  // soilScan?: [];
  bbox?: number[][] | null;
  error: string;
  selectedBrpZone?: number[][];
}
export interface AppState extends fromRoot.AppState {
  mapState: MapState;
}

export const initialState: MapState = {
  enableSatellite: true,
  enableBrpZone: false,
  bbox: null,
  error: '',
};
export function mapReducer(
  state = initialState,
  action: mapActions.Action
): MapState {
  switch (action.type) {
    case MapActionTypes.Enable_satellite: {
      return {
        ...state,
        enableSatellite: !this.enableSatellite,
      };
    }
    case MapActionTypes.Enable_Brp_Zone: {
      return {
        ...state,
        enableBrpZone: !this.enableBrpZone,
      };
    }
    case MapActionTypes.Get_brp_zones: {
      return {
        ...state,
      };
    }
    case MapActionTypes.Get_brp_zones_Fail: {
      return {
        ...state,
      };
    }
    case MapActionTypes.Get_brp_zones_Success: {
      return {
        ...state,
        brpZones: action.payload,
      };
    }
    case MapActionTypes.Get_tree_width: {
      return {
        ...state,
      };
    }
    case MapActionTypes.Get_tree_width_Fail: {
      return {
        ...state,
      };
    }
    case MapActionTypes.Get_tree_width_Success: {
      return {
        ...state,
        treeWidth: action.payload,
      };
    }
    // case MapActionTypes.Get_soil_scan: {
    //   return {
    //     ...state,
    //   };
    // }
    // case MapActionTypes.Get_soil_scan_Fail: {
    //   return {
    //     ...state,
    //   };
    // }
    // case MapActionTypes.Get_soil_scan_Success: {
    //   return {
    //     ...state,
    //     soilScan: action.payload,
    //   };
    // }
    case MapActionTypes.Update_bbox: {
      return {
        ...state,
        bbox: action.bbox,
      };
    }
    case MapActionTypes.Get_bbox: {
      return {
        ...state,
        bbox: action.payload,
      };
    }
    case MapActionTypes.Update_selected_brp_zone: {
      return {
        ...state,
        selectedBrpZone: action.payload,
      };
    }
    case MapActionTypes.Get_selected_brp_zone: {
      return {
        ...state,
        selectedBrpZone: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

const getMapFeatureState = createFeatureSelector<MapState>('mapReducer');

export const getError = createSelector(
  getMapFeatureState,
  (state: MapState) => state.error
);

export const getSatelliteStatus = createSelector(
  getMapFeatureState,
  (state: MapState) => state.enableSatellite
);
export const getBrpZoneStatus = createSelector(
  getMapFeatureState,
  (state: MapState) => state.enableBrpZone
);
export const getBrpZones = createSelector(
  getMapFeatureState,
  (state: MapState) => state.brpZones
);
export const getTreeWidth = createSelector(
  getMapFeatureState,
  (state: MapState) => state.treeWidth
);
// export const getSoilScan = createSelector(
//   getMapFeatureState,
//   (state: MapState) => state.soilScan
// );
export const getBbox = createSelector(
  getMapFeatureState,
  (state: MapState) => state.bbox
);
export const getSelectedBrpZone = createSelector(
  getMapFeatureState,
  (state: MapState) => state.selectedBrpZone
);
