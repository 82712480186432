// @ts-ignore
import { Action } from '@ngrx/store';
import { GetDatasetViewModel } from '../viewmodels/getDatasetViewModel';
import { GetBrpZonesViewModel } from '../viewmodels/getBrpZonesViewModel';
export enum MapActionTypes {
  Enable_satellite = '[Map] enable satellite',
  Enable_Brp_Zone = '[Map] Enable Brp Zone',
  Get_brp_zones = '[Map] get Brp zones',
  Get_brp_zones_Success = '[Map] get Brp zones Success',
  Get_brp_zones_Fail = '[Map] get Brp zones Fail',
  Get_tree_width = '[Map] get Tree width',
  Get_tree_width_Success = '[Map] get Tree width Success',
  Get_tree_width_Fail = '[Map] get Tree width Fail',
  // Get_soil_scan = '[Map] get Soil scan',
  // Get_soil_scan_Success = '[Map] get Soil scan Success',
  // Get_soil_scan_Fail = '[Map] get Soil scan Fail',
  Update_bbox = '[Map] update bbox',
  Get_bbox = '[Map] get bbox',
  Update_selected_brp_zone = '[Map] update selected brp zone',
  Get_selected_brp_zone = '[Map] get selected brp zone',
}

export class EnableSatellite implements Action {
  readonly type = MapActionTypes.Enable_satellite;
  constructor() {}
}
export class EnableBrpZone implements Action {
  readonly type = MapActionTypes.Enable_Brp_Zone;
  constructor() {}
}
export class GetBrpZones implements Action {
  readonly type = MapActionTypes.Get_brp_zones;
  constructor(public payload: GetBrpZonesViewModel) {}
}
export class GetBrpZonesFail implements Action {
  readonly type = MapActionTypes.Get_brp_zones_Fail;
  constructor(public payload: string) {}
}
export class GetBrpZonesSuccess implements Action {
  readonly type = MapActionTypes.Get_brp_zones_Success;
  constructor(public payload: []) {}
}
export class GetTreeWidth implements Action {
  readonly type = MapActionTypes.Get_tree_width;
  constructor(public payload: GetDatasetViewModel) {}
}
export class GetTreeWidthFail implements Action {
  readonly type = MapActionTypes.Get_tree_width_Fail;
  constructor(public payload: string) {}
}
export class GetTreeWidthSuccess implements Action {
  readonly type = MapActionTypes.Get_tree_width_Success;
  constructor(public payload: []) {}
}
// export class GetSoilScan implements Action {
//   readonly type = MapActionTypes.Get_soil_scan;
//   constructor(public payload: GetDatasetViewModel) {}
// }
// export class GetSoilScanFail implements Action {
//   readonly type = MapActionTypes.Get_soil_scan_Fail;
//   constructor(public payload: string) {}
// }
// export class GetSoilScanSuccess implements Action {
//   readonly type = MapActionTypes.Get_soil_scan_Success;
//   constructor(public payload: []) {}
// }
export class UpdateBbox implements Action {
  readonly type = MapActionTypes.Update_bbox;
  constructor(public bbox: number[][]) {}
}
export class GetBbox implements Action {
  readonly type = MapActionTypes.Get_bbox;
  constructor(public payload: number[][]) {}
}
export class UpdateSelectedBrpZone implements Action {
  readonly type = MapActionTypes.Update_selected_brp_zone;
  constructor(public payload: number[][]) {}
}
export class GetSelectedBrpZone implements Action {
  readonly type = MapActionTypes.Get_selected_brp_zone;
  constructor(public payload: number[][]) {}
}

export type Action =
  | EnableSatellite
  | EnableBrpZone
  | GetBrpZones
  | GetBrpZonesFail
  | GetBrpZonesSuccess
  | GetTreeWidth
  | GetTreeWidthFail
  | GetTreeWidthSuccess
  | UpdateBbox
  | GetBbox
  | UpdateSelectedBrpZone
  | GetSelectedBrpZone;
