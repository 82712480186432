import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';
import * as global from '../../globals';

@Injectable({
  providedIn: 'root',
})
export class SuperuserService {
  httpOptions = {
    headers: new HttpHeaders({
      'Cache-Control': 'no-cache',
    }),
  };
  constructor(private http: HttpClient, private auth: AuthService) {}

  // Creating an organization
  // createOrganization(name): Observable<any> {
  //   try {
  //     return this.http.post<any>(
  //       global.apiUrl + global.organization + '/create',
  //       {
  //         name: name,
  //       }
  //     );
  //   } catch (err) {
  //     return err;
  //   }
  // }

  // Creating and admin account for organization
  createOrgAdmin(username, email, orgId): Observable<any> {
    try {
      return this.http.post<any>(global.apiUrl + global.account + '/register', {
        username: username,
        email: email,
        password: 'organizationAdminAcount',
        orgId: orgId,
      });
    } catch (err) {
      return err;
    }
  }

  // Add new connectivity device
  createConDevice(name, user): Observable <any> {
    try{
      return this.http.post<any>(global.apiUrl + global.device + '/register/con', {
        name: name,
        xuser: user,
      });
    } catch (err) {
      return err;
    }
  }

  // Add new camera device
  createCameraDevice(name, parentDevice): Observable <any> {
    try{
      return this.http.post<any>(global.apiUrl + global.device + '/register', {
        parent_device_id: parentDevice,
        name: name,
      })
    } catch (err) {
      return err;
    }
  }
}
