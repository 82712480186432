import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MapFacade } from '../../dashboard/map/MapFacade';
import { AnyLayer, AnySourceData, LngLatLike, Map } from 'mapbox-gl';
import { FieldService } from '../../services/field.service';
import { ActivatedRoute } from '@angular/router';
import { FieldMapLayerService } from '../../services/field-map-layer.service';
import { DatasetService } from '../../services/dataset.service';
import { MatCalendar, MatCalendarCellCssClasses, MatDatepicker } from '@angular/material/datepicker';
import moment from 'moment';
import { IField } from 'src/app/models/field.model';
import { AuthService } from '@auth0/auth0-angular';
import mapboxgl from 'mapbox-gl';
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-field-page',
  templateUrl: './field-page.component.html',
  styleUrls: ['./field-page.component.css']
})
export class FieldPageComponent implements OnInit {

  constructor(private mapFacade: MapFacade,
    private fieldService: FieldService,
    private activatedRoute: ActivatedRoute,
    private datasetService: DatasetService,
    private auth: AuthService) {
  }

  selectedDate = new Date();
  @ViewChild('calendar', { static: false }) calendar: MatCalendar<Date>;
  @ViewChild('rightMenu') rightMenu: ElementRef;

  bbox: number[][];
  field;
  fieldName = '';
  layers: FieldMapLayerService;
  datasets = [];
  selectedDatasetId: number;
  map: Map;
  disabled = false;
  invert = false;
  max = 100;
  min = 0;
  step = 1;

  data_storage = [];

  enableField = false;
  enableBaseData = false;
  enableMetaData = false;
  // enableSoilScan = false;
  satellite = false;

  currentSelectedDate = '';

  num = 17;

  center: LngLatLike;
  fieldZoomLabel: 'Zoom to field';
  @ViewChild(MatDatepicker) datepicker: MatDatepicker<Date>;
  selected: Date | null;
  datesToHighlight = [];
  searchedDate = '';
  measurement = 'off';

  ngOnInit(): void {
    this.center = [6.1231334, 51.4063859];
    if (localStorage.getItem('measurement_type') !== null) {
      this.measurement = localStorage.getItem('measurement_type');
    }
    if (localStorage.getItem('center') !== null) {
      this.center = JSON.parse(localStorage.getItem('center'));
    }
  }

  ngAfterViewInit() {
    this.rightMenu.nativeElement.scrollTop = 0;
  }

  mapLoad() {
    console.log('hello')
    this.map.on('style.load', () => {
      this.layers.setField(this.field);
      this.enableField = true
      this.enableMetaData = false
      if (this.currentSelectedDate != '') {
        this.dateChange('', this.currentSelectedDate)
      }
    });

    this.map.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true,
        showUserHeading: true
      }), 'top-left'
    );

    this.activatedRoute.queryParams.subscribe(params => {
      this.fieldService.get_field(params.field_id).subscribe(result => {
        let res = result as IField;
        this.fieldName = res.name
        this.field = res.boundary.coordinates[0];
        this.enableField = true
        this.center = res.center.coordinates;
        this.layers = new FieldMapLayerService(this.mapFacade, this.map, this.datasetService);
        this.layers.setField(this.field);

        for (let date of res.layers.tree_measurement_processed.available_dates) {
          this.datesToHighlight.push(date + 'T00:00:00.000Z');
        }
        if (this.datesToHighlight.length > 0) {
          this.datesToHighlight = this.datesToHighlight.sort().reverse();
          this.currentSelectedDate = this.datesToHighlight[0].toString()
          this.dateChange('', this.currentSelectedDate)
        }
      });
    });
  }

  colorChange() {
    this.layers.colorChange(this.currentSelectedDate + 'base_data');
  }

  filterData() {
    this.layers.filterData(this.currentSelectedDate + 'base_data');
  }

  generateColorsTMP() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.layers.generateColors(params.field_id, 'width')
    })
  }

  layerVisibility(layer: string) {
    this.layers.updateVisibility(layer);
  }

  toggleBaseData() {
    this.layers.updateDatasetLayer(this.currentSelectedDate, this.enableBaseData)
  }

  toggleMetaData() {
    if (!this.enableMetaData) {
      this.layers.setVisibility('meta_data', false)
      this.enableMetaData = false

    } else {
      this.activatedRoute.queryParams.subscribe(params => {
        this.layers.createMetaDataLayer(this.currentSelectedDate, this.enableMetaData, params.field_id)
        this.enableMetaData = true
      })
    }
  }

  zoomToField() {
    this.map.setZoom(this.num);
    this.map.flyTo({ center: this.center, essential: true });
  }

  dateClass() {
    return (date: Date): MatCalendarCellCssClasses => {
      const highlightDate = this.datesToHighlight
        .map(strDate => new Date(strDate))
        .some(d => d.getDate() === date.getDate() && d.getMonth() === date.getMonth() && d.getFullYear() === date.getFullYear());
      return highlightDate ? 'special-date' : '';
    };
  }

  getSelectedDate() {
    return moment(this.selectedDate).format();
  }

  dateChange(event: any, date: any) {
    // this.updateDataset();
    let target_date = moment(date).format('YYYY-MM-DD')
    this.currentSelectedDate = target_date.toString()
    this.activatedRoute.queryParams.subscribe(params => {
      let some = this.layers.updateDatasetLayer(this.currentSelectedDate, true, 'base_data', params.field_id)
      this.enableBaseData = true
      if(some[1] != undefined){
        this.data_storage.push(some)
      }
    })
  }


  export_translations = {
    eng: {
      tree_id: "Tree ID",
      time: "Time",
      latitude: "Latitude",
      longitude: "Longitude",
      vehicle_speed_kmh: "Vehicle Speed (km/h)",
      row_number: "Row Number",
      tree_type: "Tree Type",
      tree_circumference_cm: "Tree Circumference (cm)",
      tree_size_bucket: "Tree Size Bucket",
      damaged: "Damaged",
      note: "Note",
    },
    nld: {
      tree_id: "Boom ID",
      time: "Tijd",
      latitude: "Breedtegraad",
      longitude: "Lengtegraad",
      vehicle_speed_kmh: "Voertuigsnelheid (km/u)",
      row_number: "Rijnummer",
      tree_type: "Boomtype",
      tree_circumference_cm: "Boomomtrek (cm)",
      tree_size_bucket: "Boomgrootte Categorie",
      damaged: "Beschadigd",
      note: "Opmerking",
    }
  };

  createTranslatedJSON(json) {
    const translatedJson = {};

    let language = 'eng'

    if(localStorage.getItem('lang')){
      language = localStorage.getItem('lang')
    }
  
    for (const key in json) {
      // Use the translation from the dictionary if available, otherwise use the original key
      const translatedKey = this.export_translations[language][key] || key;
  
      // Add the translated key and the corresponding value to the new JSON
      translatedJson[translatedKey] = json[key];
    }
  
    return translatedJson;
  }

  exportTableToExcel() {
    if (this.enableBaseData && this.data_storage.length > 0) {
      let data = []
      let tmp_data = this.data_storage.find(subArr => subArr[0] === this.currentSelectedDate + 'base_data');

      for (let item of tmp_data[1]) {
        let raw_json = { "tree_id": item.id, "time": item.time, "latitude": item.location.coordinates[1],
        "longitude": item.location.coordinates[0], "vehicle_speed_kmh": Number((item.data.speed * 3.6).toFixed(1)), "row_number": item.metadata.row_index, "tree_type": item.metadata.tree_type,
        "tree_circumference_cm": Number(item.data.circumference.toFixed(1)), "tree_size_bucket": item.data.size_bucket_circumference,
        "damaged": item.metadata.damaged, "note": item.metadata.note}
        let json = this.createTranslatedJSON(raw_json)
        if (item.metadata.metadata) {
          for (const [key, value] of Object.entries(item.metadata.metadata)) {
            if (key == "tree_id") {
              json["meta_tree_id"] = value;
            } else {
              json[key] = value;
            }
          }
        }
        data.push(json)
      }

      const ws = XLSX.utils.json_to_sheet(data);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, ws, 'Aantallen');
      XLSX.writeFile(workBook, `${this.fieldName}_AgroWizard_Export.xlsx`);
    }
  }

  toggle_element(element_id: string, e: HTMLElement) {
    var el = document.getElementById(element_id)
    if (el.style.visibility == 'hidden') {
      el.style.visibility = 'visible'
    }
    else {
      el.style.visibility = 'hidden'
    }
    e.classList.toggle("change")
  }

  toggle_legend() {
    document.querySelector('.smalldesc').classList.toggle('expand');
  }

  last_style = ''
  change_style(style: string) {
    if (style != this.last_style && style == 'satellite') {
      this.map.setStyle('https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v12?access_token=pk.eyJ1IjoiZmxvcmlzcGV0ZXJzIiwiYSI6ImNrcjM2MzU2NjB3aWYycnFtbno2ampqYzgifQ.A-51Hqh4pULfmnMntVst7Q')
    } else {
      this.map.setStyle('https://api.mapbox.com/styles/v1/mapbox/light-v11?access_token=pk.eyJ1IjoiZmxvcmlzcGV0ZXJzIiwiYSI6ImNrcjM2MzU2NjB3aWYycnFtbno2ampqYzgifQ.A-51Hqh4pULfmnMntVst7Q')
      this.last_style = ''
      return
    }
    this.last_style = style
  }

}
