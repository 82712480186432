import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { concatMap, take, tap, map } from 'rxjs/operators';
import { AuthService } from '@auth0/auth0-angular';
import { IListResponse } from '../models/global.model';
import { IField } from '../models/field.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class FieldService {

  // private apiUrl = 'https://api-gateway-new-stag-t7qk35obsa-ez.a.run.app/field';
  private apiUrl = 'https://api-gateway-new-stag-t7qk35obsa-ez.a.run.app/v2/field'

  constructor(private http: HttpClient, private auth: AuthService, private api: ApiService) {
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Cache-Control': 'no-cache',
    })
  };

  public saveField_OLD(name: string, geometry: number[][]): Observable<any> {
    return this.auth.user$
      .pipe(
        take(1),
        concatMap((user) => {
            return this.http.post<any>(this.apiUrl + '/create', {
                name,
                geometry: {
                  coordinates: geometry,
                  type: 'Polygon'
                }
              }
            );
          }
        )
      );
  }

  public save_field(app_id: string, name: string, geometry: number[][]): Observable<any> {
    return this.api.post_with_auth(`/field/create`, {
      name,
      geometry: {
        coordinates: geometry,
        type: 'Polygon'
      },
      app_id
    })
  }



  public delete_field(fieldId: string): Observable<any> {
    return this.auth.user$
      .pipe(
        take(1),
        concatMap((user) => {
            return this.http.delete<any>(this.apiUrl + '/delete/' + fieldId);
          }
        )
      );
  }

  public get_accessible_fields(page: number, limit: number): Observable<any> {
    return this.auth.user$
      .pipe(
        take(1),
        concatMap((user) => {
            return this.http.get<any>(this.apiUrl + '/list/accessible_fields?page=' + page + '&limit=' + limit, this.httpOptions);
          }
        )
      );
  }
  

  public search_map_box(term: string): Observable<any> {
    const encodedTerm = encodeURIComponent(term);
    return this.auth.user$
      .pipe(
        take(1),
        concatMap((user) => {
            return this.http.get<any>('https://api.mapbox.com/geocoding/v5/mapbox.places/' + encodedTerm + '.json?access_token=pk.eyJ1IjoiZmxvcmlzcGV0ZXJzIiwiYSI6ImNrcjM2MzU2NjB3aWYycnFtbno2ampqYzgifQ.A-51Hqh4pULfmnMntVst7Q');

          }
        )
      );
  }


  

  // new methods replacing floris his jokes

  public get_fields(offset = 0, limit = 9, include_archived = false): Observable<any> {
    return this.api.get_with_auth(`/field/list?offset=${offset}&limit=${limit}&archived=${include_archived}`);
  }

  public get_fields_for_app(app_id, offset = 0, limit = 9, include_archived = false): Observable<any>{
    return this.api.get_with_auth(`/field/list?offset=${offset}&limit=${limit}&archived=${include_archived}&app_id=${app_id}`);
  }

  public get_field(fieldId: string): Observable<any> {
    return this.api.get_with_auth(`/field/${fieldId}`)
  }

  public archive_field(fieldId: string): Observable<any> {
    return this.api.get_with_auth(`/field/${fieldId}/archive`)
  }

  public unarchive_field(fieldId: string): Observable<any> {
    return this.api.get_with_auth(`/field/${fieldId}/unarchive`)
  }
}

