import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-device-card',
  templateUrl: './device-card.component.html',
  styleUrls: ['./device-card.component.css']
})
export class DeviceCardComponent implements OnInit {
  @Input() device: any;
  @Input() isCamera = false;

  constructor() {

  }

  ngOnInit(): void {
  }

}
