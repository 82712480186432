export function treeMetaDataLayer(map: mapboxgl.Map, visible: boolean){
    map.addLayer({
        id: 'treeData-layer',
        type: 'circle',
        source: 'treeData-source',
        layout: {
          visibility: (visible ? 'visible' : 'none')
        },
        paint: {
          // Make circles larger as the user zooms from z12 to z22.
          'circle-radius': {
            stops: [
              [0, 0],
              [22, 0.8 / 0.022 / Math.cos(JSON.parse(localStorage.getItem('center'))[0] * Math.PI / 180)]
            ],
            base: 2
          },
          'circle-color': '#fff',
          'circle-opacity': .6
        }
      });
}