import MapboxDraw from "@mapbox/mapbox-gl-draw";
import FreehandMode from "mapbox-gl-draw-freehand-mode";
import * as turf from "@turf/turf";

let map;
let returnCoordinatesFunction;


export function TreeGetClickCoordinates(mapObj, functionCallBack){
    map = mapObj;
    returnCoordinatesFunction = functionCallBack;

    map.on('click', (e) => {
        returnCoordinatesFunction(e);
    })
}



