import { Component, Input, OnInit } from '@angular/core';
import { Router, Routes } from '@angular/router';
import { FieldsPageComponent } from '../fields-overview-page/fields-page.component';
import { PortalAppsService } from 'src/app/services/portal-apps/portal-apps.service';
import { IPortalApp } from 'src/app/services/portal-apps/iportal-app';



@Component({
  selector: 'app-applications-page',
  templateUrl: './applications-page.component.html',
  styleUrls: ['./applications-page.component.css']
})


export class ApplicationsPageComponent implements  OnInit{
  @Input() !apps: IPortalApp[];

  constructor(private router: Router, private portalAppsService: PortalAppsService) { }

  ngOnInit(): void {
    const url = this.router.url;
    this.getAllAppsAndSelectIf1();
  }

  async getAllAppsAndSelectIf1(): Promise<void>{
    this.apps = await this.portalAppsService.getAllAvaliableApps(); // may change to show unavailabe apps too
    // if(this.apps.length == 1){
    //   this.onAppSelect(this.apps[0]);
    // }
  }

  onAppSelect(app: IPortalApp){
    this.portalAppsService.setActiveApp(app);
    localStorage.setItem("active_app_code_name", app.codeName);
    this.router.navigateByUrl("apps/" + app.codeName);
    
  }





}
