<div class="spacing_non_map_pages">
  <h1 class="unselectable global_title">{{'i18n.DEVICES_PAGE.TITLE' | translate}}</h1>

  <!-- List of connectivity devices -->
  <h2 class="type_title">{{'i18n.DEVICES_PAGE.CONNECTED_CON_DEVICES' | translate}}</h2>
  <div class="devices_container">
    <div class="global_tile device_card" *ngFor="let device of connectivity">
      <div class="device_icon">
        <svg viewBox="0 0 86 86">
          <path
            d="M21.767,67.026 L16.484,72.390 C6.536,64.545 0.227,53.096 0.227,40.307 C0.227,27.517 6.536,16.068 16.484,8.227 L21.767,13.588 C13.476,20.128 8.207,29.670 8.207,40.307 C8.207,50.943 13.476,60.485 21.767,67.026 ZM53.693,40.307 C53.693,34.400 48.903,29.613 43.000,29.613 C37.097,29.613 32.307,34.400 32.307,40.307 C32.307,44.955 35.294,48.872 39.436,50.344 L39.436,93.773 L46.564,93.773 L46.564,50.344 C50.706,48.872 53.693,44.955 53.693,40.307 ZM24.173,40.307 C24.173,33.923 27.334,28.198 32.307,24.274 L27.028,18.920 C20.398,24.149 16.192,31.780 16.192,40.307 C16.192,48.833 20.398,56.464 27.028,61.693 L32.307,56.340 C27.331,52.415 24.173,46.691 24.173,40.307 ZM69.516,8.227 L64.233,13.588 C72.524,20.128 77.793,29.670 77.793,40.307 C77.793,50.943 72.524,60.485 64.233,67.026 L69.516,72.390 C79.464,64.545 85.773,53.096 85.773,40.307 C85.773,27.517 79.464,16.068 69.516,8.227 ZM58.972,18.920 L53.693,24.274 C58.669,28.198 61.827,33.923 61.827,40.307 C61.827,46.691 58.669,52.415 53.693,56.340 L58.972,61.693 C65.602,56.464 69.808,48.833 69.808,40.307 C69.808,31.780 65.606,24.149 58.972,18.920 Z" />
        </svg>
      </div>
      <div class="global_devider_vertical"></div>
      <div class="device_details">
        <p>{{'i18n.DEVICES_PAGE.DEVICE_NAME' | translate}}: <b>{{device.name}}</b></p>
        <p>{{'i18n.DEVICES_PAGE.DEVICE_TYPE' | translate}}: <b>{{device.type}}</b></p>
        <p>{{'i18n.DEVICES_PAGE.DEVICE_ACTIVE_SINCE' | translate}}: <b>{{device.created_at.slice(0, 10)}}</b></p>
      </div>
    </div>
  </div>


   <!-- List of camera/sensor devices -->
  <h2 class="type_title">{{'i18n.DEVICES_PAGE.CONNECTED_CAM_DEVICES' | translate}}</h2>
  <div class="devices_container">
    <div class="global_tile device_card" *ngFor="let device of camera">
      <div class="device_icon">
        <svg viewBox="0 0 103 103">
          <path
            d="M61.573,79.945 L76.836,103.000 L0.797,103.000 L16.060,79.945 C22.793,83.769 30.545,85.982 38.816,85.982 C47.088,85.982 54.840,83.769 61.573,79.945 ZM38.816,30.672 C34.157,30.672 30.368,34.489 30.368,39.181 C30.368,43.874 34.157,47.691 38.816,47.691 C43.476,47.691 47.265,43.874 47.265,39.181 C47.265,34.489 43.476,30.672 38.816,30.672 ZM76.836,39.181 C76.836,60.331 59.816,77.473 38.816,77.473 C17.817,77.473 0.797,60.327 0.797,39.181 C0.797,18.036 17.817,0.890 38.816,0.890 C59.816,0.890 76.836,18.036 76.836,39.181 ZM55.714,39.181 C55.714,29.783 48.148,22.163 38.816,22.163 C29.485,22.163 21.919,29.783 21.919,39.181 C21.919,48.580 29.485,56.200 38.816,56.200 C48.148,56.200 55.714,48.580 55.714,39.181 Z" />
        </svg>
      </div>
      <div class="devider_div"></div>
      <div class="device_details">
        <p>{{'i18n.DEVICES_PAGE.DEVICE_NAME' | translate}}: <b>{{device.name}}</b></p>
        <p>{{'i18n.DEVICES_PAGE.DEVICE_TYPE' | translate}}: <b>{{device.type}}</b></p>
        <p>{{'i18n.DEVICES_PAGE.DEVICE_ACTIVE_SINCE' | translate}}: <b>{{device.created_at.slice(0, 10)}}</b></p>
      </div>
    </div>
  </div>
</div>