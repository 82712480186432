  <li [routerLinkActive]="['nav-item-selected']" [routerLink]="'apps/' + codeName + '/fields'"  class="nav-item mouse-pointer-click">  
    <div class="nav-list-item">
      <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="26.313" height="24.125"
        viewBox="0 0 26.313 24.125">
        <defs>
          
        </defs>
        <path id="Vector" class="svg-icon"
          d="M73.307,319.787c-5.6,3.053-13.945,10.061-17.307,16.958h3.222a23.277,23.277,0,0,1,4.222-6.47c9.193,4.108,18.867-3.042,18.867-12.435a14.617,14.617,0,0,0-1.009-5.213c-2.573,3.42-6.191,1.154-11.9,1.782-8.394.925-12.236,7.451-9.608,12.651C63.642,323.316,70.238,320.066,73.307,319.787Z"
          transform="translate(-56 -312.625)" />
      </svg>
      <p class="link-text text unselectable">{{'i18n.MENU.ITEMS.FIELDS' | translate}}</p>
    </div>
  </li>