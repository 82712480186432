import { Injectable, Input } from '@angular/core';
import mapboxgl, { Map } from 'mapbox-gl';
import { interval, Observable } from 'rxjs';
import { MapFacade } from '../dashboard/map/MapFacade';
import MapboxDraw from '@mapbox/mapbox-gl-draw';

@Injectable({
  providedIn: 'root'
})
export class LayerService {
  treeWidth$: Observable<[]>;
  // soilScan$: Observable<[]>;
  brpZones$: Observable<[]>;
  selectedBrpZone$: Observable<number[][]>;
  popup: mapboxgl.Popup;
  enableTreeWidth: boolean;
  // enableSoilScan: boolean;
  enableBrpZones: boolean;
  enableSatellite = false;
  enableRoads = false;
  enableSelectedBrpZone: boolean;
  bbox: number[][];
  bbox2: number[][];
  zIndex1;
  zIndex2;
  zIndex3;
  zIndex4;

  draw: MapboxDraw;

  constructor(private mapFacade: MapFacade, private map: Map) {
    // this.enableTreeWidth = false;
    // this.enableSoilScan = false;
    this.enableBrpZones = false;
    this.enableSelectedBrpZone = true;

    this.setZIndexes();
    this.setDatasets();
    this.setLayers();
    this.setDrawMapbox();

    this.mapFacade.Bbox$.subscribe((res: number[][]) => {
      if (this.updateBboxNeeded(res)) {
        this.bbox = res;
      }
    });
    interval(1000).subscribe(x => {
      if (this.bbox !== this.bbox2 && this.map.getZoom() > 14) {
        this.updateDatasets(this.bbox);
        this.bbox2 = this.bbox;
      }
    });

  }

  setZIndexes() {
    this.map.addSource('empty', {
      type: 'geojson',
      data: { type: 'FeatureCollection', features: [] }
    });
    this.zIndex1 = this.map.addLayer({
      id: 'z-index-1',
      type: 'symbol',
      source: 'empty'
    }); // place this layer on top

    this.zIndex2 = this.map.addLayer({
      id: 'z-index-2',
      type: 'symbol',
      source: 'empty'
    }, 'z-index-1'); // place this layer below zIndex1

    this.zIndex3 = this.map.addLayer({
      id: 'z-index-3',
      type: 'symbol',
      source: 'empty'
    }, 'z-index-2'); // place this layer below zIndex2

    this.zIndex4 = this.map.addLayer({
      id: 'z-index-4',
      type: 'symbol',
      source: 'empty'
    }, 'z-index-3'); // place this layer below zIndex3
  }

  setDatasets() {
    // this.soilScan$ = this.mapFacade.SoilScan$;
    this.brpZones$ = this.mapFacade.BrpZones$;
    this.selectedBrpZone$ = this.mapFacade.SelectedBrpZone$;
  }

  setLayers() {
    // OUR COMPANY LOGO
    this.map.loadImage(
      '../assets/map-logo.png',
      (error, image) => {
        if (error) throw error;
        this.map.addImage('logo', image);
        this.map.addSource('point', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [6.1231334, 51.4063859],
                },
                properties: {},
              },
            ],
          },
        });
        this.map.addLayer({
          id: 'points',
          type: 'symbol',
          source: 'point', // reference the data source
          layout: {
            'icon-image': 'logo', // reference the image
            'icon-size': 0.2,
          },
        });
      }
    );

    // set selected brp zone
    this.selectedBrpZone$.subscribe((res) => {
      if (res !== undefined) {
        if (res.length > 0) {
          // removes existing layer to make room for layer with new data
          if (this.map.getSource('selected_brp_zone')) {
            this.map.removeLayer('selected_brp_zone');
            this.map.removeSource('selected_brp_zone');
          }

          const field = {
            type: 'Feature' as const,
            properties: {},
            geometry: {
              type: 'Polygon' as const,
              coordinates: res
            }
          };

          // Add a geojson point source.
          this.map.addSource('selected_brp_zone', {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: [
                // @ts-ignore
                field
              ]

            }
          });
          this.map.addLayer({
            id: 'selected_brp_zone',
            type: 'fill',
            source: 'selected_brp_zone',
            layout: {},
            paint: {
              'fill-color': '#ee2308',
              'fill-opacity': 0.4
            }
          }
            , 'z-index-2');
        }
        if (!this.enableSelectedBrpZone) {
          this.setVisibility('selected_brp_zone', this.enableSelectedBrpZone);
        }
      }
    }
    );

    // set tree width layer
    this.popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false
    });

    this.map.on('mouseenter', 'tree_width', (e) => {
      this.map.getCanvas().style.cursor = 'pointer';

      // @ts-ignore
      const coordinates = e.features[0].geometry.coordinates.slice();
      const width = e.features[0].properties.width;

      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      // Populate the popup and set its coordinates
      // based on the feature found.
      this.popup.setLngLat(coordinates).setHTML(this.createTreeWidthHTML(width, coordinates)).addTo(this.map);
    });

    this.map.on('mouseleave', 'tree_width', () => {
      this.map.getCanvas().style.cursor = '';
      this.popup.remove();
    });

    this.map.addSource(
      'sadness', {
      type: 'vector',
      url: 'mapbox://mapbox.mapbox-streets-v8'
    });

    this.map.addLayer({
      id: 'roads',
      type: 'line',
      source: 'sadness',
      "source-layer": "road",
      paint: {
        'line-color': '#fff',
        'line-opacity': 0.6,
        'line-width': 5
      }

    }, 'z-index-3');
    this.map.setLayoutProperty('roads', 'visibility', 'none');

    // set soil scan layer
    // this.soilScan$.subscribe((res: []) => {
    //   if (res !== undefined) {
    //     if (res.length > 0) {

    //       // if (this.map.getSource('soil_scan')) {
    //       //   this.map.removeLayer('soil_scan');
    //       //   this.map.removeSource('soil_scan');
    //       // }

    //       // this.map.addSource('soil_scan', {
    //       //   type: 'geojson',
    //       //   data: {
    //       //     type: 'FeatureCollection',
    //       //     features: res
    //       //   }
    //       // });
    //       this.map.addLayer({
    //         id: 'selected_brp_zone',
    //         type: 'fill',
    //         source: 'selected_brp_zone',
    //         layout: {},
    //         paint: {
    //           'fill-color': '#c62424',
    //           'fill-opacity': 0.4
    //         }
    //       }, 'z-index-1');
    //     }
    //     if (!this.enableBrpZones) {
    //       this.setVisibility('brp', this.enableBrpZones);
    //     }
    //   }
    // }
    // );
    // set brp zones layer
    this.brpZones$.subscribe((res: []) => {
      if (res !== undefined) {
        if (res.length > 0) {

          if (this.map.getSource('brp')) {
            this.map.removeLayer('brp');
            this.map.removeSource('brp');
          }
          // Add a geojson point source.
          // Heatmap layers also work with a vector tile source.
          this.map.addSource('brp', {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: res
            }
          });
          this.map.addLayer({
            id: 'brp',
            type: 'fill',
            source: 'brp',
            layout: {},
            paint: {
              'fill-color': '#088',
              'fill-opacity': 0.4
            }
          }, 'z-index-3');
        }
        if (!this.enableBrpZones) {
          this.setVisibility('brp', this.enableBrpZones);
        }
      }
    }
    );

    this.map.on('click', 'brp', (e) => {
      this.map.getCanvas().style.cursor = 'pointer';
      this.draw.deleteAll();
      // @ts-ignore
      const geometry = e.features[0].geometry.coordinates;
      this.mapFacade.updateSelectedBrpZone(geometry);
    });

    this.map.on('mouseleave', 'tree_width', () => {
      this.map.getCanvas().style.cursor = '';
      this.popup.remove();
    });
    // set satellite layer
    this.map.addSource(
      'mapbox-satellite', {
      type: 'raster',
      url: 'mapbox://mapbox.satellite',
      
    });
    this.map.addLayer(
      {
        type: 'raster',
        id: 'satellite',
        source: 'mapbox-satellite'
      }, 'z-index-4');
    this.map.setLayoutProperty('satellite', 'visibility', 'none');

    //correct url but doesnt work yet
    //       url: 'https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v12?access_token=pk.eyJ1IjoiZmxvcmlzcGV0ZXJzIiwiYSI6ImNrcjM2MzU2NjB3aWYycnFtbno2ampqYzgifQ.A-51Hqh4pULfmnMntVst7Q',


  }

  setDrawMapbox() {
    this.draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true
      },
      defaultMode: 'draw_polygon'
    });

    this.map.addControl(this.draw, 'top-left');
    this.map.on('draw.create', d => {
      const geometry = this.draw.getAll().features[0].geometry.coordinates;
      this.mapFacade.updateSelectedBrpZone(geometry);
    });
    this.map.on('draw.delete', d => {
      this.mapFacade.updateSelectedBrpZone([[0], [0]]);
    });
    this.map.on('draw.update', d => {
      const geometry = this.draw.getAll().features[0].geometry.coordinates;
      this.mapFacade.updateSelectedBrpZone(geometry);
    });
    this.map.on('draw.modechange', (e) => {
      const data = this.draw.getAll();
      if (this.draw.getMode() === 'draw_polygon') {
        const pids = [];

        // ID of the added template empty feature
        const lid = data.features[data.features.length - 1].id;

        data.features.forEach((f) => {
          if (f.geometry.type === 'Polygon' && f.id !== lid) {
            pids.push(f.id);
          }
        });
        this.draw.delete(pids);
      }
    });
  }

  flyToPoint(coordinates: number[]) {
    this.map.flyTo({
      center: [
        coordinates[0],
        coordinates[1]
      ],
      essential: true // this animation is considered essential with respect to prefers-reduced-motion
    });
  }

  updateBboxNeeded(boundaryBox: number[][]) {
    return (!this.bbox || !(this.bbox[0][0] > boundaryBox[0][0]
      && this.bbox[0][1] > boundaryBox[0][1]
      && this.bbox[2][0] < boundaryBox[2][0]
      && this.bbox[2][1] < boundaryBox[2][1]));
  }

  updateDatasets(boundaryBox: number[][]) {

    // if (this.enableSoilScan) {
    //   // this.mapFacade.getSoilScan(boundaryBox);
    // }
    if (this.enableBrpZones) {
      this.mapFacade.getBrpZone(boundaryBox);
    }
  }

  updateVisibility(layer: string) {
    switch (layer) {
      case 'tree_width': {
        this.enableTreeWidth = !this.enableTreeWidth;
        this.setVisibility(layer, this.enableTreeWidth);
        break;
      }
      // case 'soil_scan': {
      //   this.enableSoilScan = !this.enableSoilScan;
      //   this.setVisibility(layer, this.enableSoilScan);
      //   // this.mapFacade.getSoilScan(this.bbox);
      //   break;
      // }
      case 'brp': {
        this.enableBrpZones = !this.enableBrpZones;
        this.setVisibility(layer, this.enableBrpZones);
        this.mapFacade.getBrpZone(this.bbox);
        break;
      }
      case 'satellite': {

        this.enableSatellite = !this.enableSatellite;
        this.setVisibility(layer, this.enableSatellite);
        break;
      }
      case 'roads': {
        this.enableRoads = !this.enableRoads;
        this.setVisibility(layer, this.enableRoads);
        break;
      }
      case 'selected_brp_zone': {
        this.enableSelectedBrpZone = !this.enableSelectedBrpZone;
        this.setVisibility(layer, this.enableSelectedBrpZone);
        break;
      }
    }
  }

  setVisibility(layer: string, visibility: boolean) {
    if (this.map.getLayer(layer) !== undefined) {
      if (visibility) {
        this.map.setLayoutProperty(layer, 'visibility', 'visible');
      } else {
        this.map.setLayoutProperty(layer, 'visibility', 'none');
      }
    }
  }

  createTreeWidthHTML(width: string, coordindates: number[]) {
    return '<p>Tree Width: ' + width + 'mm.</p>' +
      '<p>Location: ' + coordindates[0] + ', ' + coordindates[1] + '</p>';
  }

}
