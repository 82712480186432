<div class="field_map_page" fxFlexFill="">

    <div class="pop-up-container" *ngIf="portalAppMapStrategy.popUpComponentToShow != null">
        <div class="dark-overlay" (click)="portalAppMapStrategy.popUpComponentToShow = null"></div>
        <app-dynamic-map-popup-component [component]="portalAppMapStrategy.popUpComponentToShow"></app-dynamic-map-popup-component>
    </div>

    <div class="floating-container" *ngIf="portalAppMapStrategy.floatingContainerComponent != null">
        <app-dynamic-map-floating-container-component [component]="portalAppMapStrategy.floatingContainerComponent" [data]="portalAppMapStrategy.floatingContainerData"></app-dynamic-map-floating-container-component>
    </div>

    <div id="map"></div>


    <div  class="map_info_entity_menu" [ngClass]="{'collapsed' : !showEntityInfo.value}">
        <div class="top-header">
            <h3>{{'i18n.FIELD_PAGE.INFORMATION_TITLE' | translate}}</h3>
            <div class="close-icon" (click)="toggleEntityInfoBox()">
                <span class="close_cross">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
                </span>
            </div>
        </div>
        <div *ngIf="!portalAppMapStrategy.isMutationOn && !portalAppMapStrategy.isCreationOn" class="info-components">
            
            <app-dynamic-map-info-content *ngFor="let infoComponent of portalAppMapStrategy.entityInfoComponents" [component]="infoComponent.component" [isVisible]="infoComponent.isVisible" [displayData]="infoComponent.data"></app-dynamic-map-info-content>
        </div>

        <div *ngIf="portalAppMapStrategy.isMutationOn" class="info-components">
            
            <app-dynamic-map-info-content *ngFor="let mutationComponent of portalAppMapStrategy.mutationComponents" [component]="mutationComponent.component" [isVisible]="{value: true}" [displayData]="mutationComponent.data"></app-dynamic-map-info-content>
        </div>
        <div *ngIf="portalAppMapStrategy.isCreationOn && portalAppMapStrategy.pointsCreationInputComponent.component != null" class="info-components">
            
            <app-dynamic-map-info-content [component]="portalAppMapStrategy.pointsCreationInputComponent.component" [isVisible]="{value: true}" [displayData]="portalAppMapStrategy.pointsCreationInputComponent.data"></app-dynamic-map-info-content>
        </div>
        <div class="open-info-menu-container" (click)="toggleEntityInfoBox()">
            <div class="desktop">
                <span class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M48 80a48 48 0 1 1 96 0A48 48 0 1 1 48 80zM0 224c0-17.7 14.3-32 32-32H96c17.7 0 32 14.3 32 32V448h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H64V256H32c-17.7 0-32-14.3-32-32z"/></svg>
                </span>
            </div>
            <div class="mobile">
                <span class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M470.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 256 265.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160zm-352 160l160-160c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L210.7 256 73.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0z"/></svg>
                </span>
            </div>
        </div>
    </div>


    <div #menu_btn [style.transform]="'translateY(-' + rightMenuScroll + 'px)'" class="right_menu_btn change" (click)="toggle_element('right_menu', menu_btn)">
        <div class="desktop-layout">
            <span class="gear-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"/></svg>
            </span>
            <span class="close_cross">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
            </span>
        </div>
        <div class="mobile-layout" >
            <span class="settings_btn"><span class="gear-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"/></svg>
            </span></span>
            <span class="close_cross">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
            </span>
        </div>
    </div>

    <div id="right_menu" (scroll)="onRightMenuScroll($event)" class="global_tile right_menu scrollable" fxLayout="column"
        fxLayoutAlign="space-between stretch" fxLayoutGap="20px" #rightMenu>
        <p class="field_name" *ngIf="selectedField">{{selectedField.name}}‎</p>

        <mat-label>{{'i18n.FIELD_PAGE.DATASET' | translate}}</mat-label>

        <div class="date_slector">
            <input style="border: solid #3D9B47 1px" class="global_input_field" placeholder="{{'i18n.GLOBAL.SEARCH' | translate}}"
                (keyup)="searchedDate = $event.target.value">
            <div class="date_list" *ngIf="selectedField">
                <div *ngIf="searchedDate == ''">
                    <p  (click)="dateChange('all')" [ngStyle]="{'background-color': (selectedDate == 'all') ? '#daffde' : '#fff'}"><b>{{'i18n.FIELD_PAGE.ALL_TIME_OVERVIEW' | translate}}</b></p>
                </div>
                <div *ngFor="let date of availibleDates">
                    <p *ngIf="date.includes(searchedDate)" (click)="dateChange(date)"
                        [ngStyle]="{'background-color': (date.includes(selectedDate)) ? '#daffde' : '#fff'}">
                        {{date.substring(0, 10)}}
                    </p>
                </div>
            </div>
        </div>
        <div class="global_devider"></div>
        <!-- <button (click)="generateColorsTMP()">test gen</button> -->

        <mat-label>{{'i18n.FIELD_PAGE.MAP_STYLE' | translate}}</mat-label>

        <div class="option_container">
            <label class="switch">
                <input type="checkbox" value="treeMeasurementData-layer" (change)="changeMapStyle($event)">
                <span class="slider"></span>
            </label>
            <p class="option_label">{{'i18n.FIELD_PAGE.SATELLITE' |
                translate}}</p>
        </div>

        <div class="global_devider"></div>
        <mat-label>{{'i18n.FIELD_PAGE.MAP_ACTIONS' | translate}}</mat-label>
        <div class="option_container map_actions">
            <app-dynamic-map-action *ngFor="let action of portalAppMapStrategy.mapActions" [component]="action"></app-dynamic-map-action>
            
        </div>

        <div class="global_devider"></div>
        <mat-label>{{'i18n.FIELD_PAGE.POINTS_SELECTION' | translate}}</mat-label>
        <div class="option_container point-selection">
            <app-dynamic-map-points-control *ngFor="let selection of portalAppMapStrategy.pointsSelections" [component]="selection"></app-dynamic-map-points-control>
            
        </div>

        <div *ngIf="!portalAppMapStrategy.showArchivedData" class="global_devider"></div>
        <mat-label *ngIf="!portalAppMapStrategy.showArchivedData">{{'i18n.FIELD_PAGE.POINTS_CREATION' | translate}}</mat-label>
        <div *ngIf="!portalAppMapStrategy.showArchivedData" class="option_container point-creation">
            <app-dynamic-map-points-control *ngFor="let creation of portalAppMapStrategy.pointsCreation" [component]="creation"></app-dynamic-map-points-control>
            
        </div>

        <div class="global_devider"></div>
        <mat-label>{{'i18n.FIELD_PAGE.VISUALIZATION' | translate}}</mat-label>
        <!-- Field is availbale by default -->

        <div class="option_container">
            <label class="switch">
                <input type="checkbox" value="fieldBoundery-layer"
                    [ngModel]="portalAppMapStrategy.visibleLayers.includes('fieldBoundery-layer')" (change)="showhideLayer($event)">
                <span class="slider"></span>
            </label>
            <p class="option_label">{{'i18n.FIELD_PAGE.FIELD'
                | translate}}</p>
        </div>

        <!-- Portal defined toggles -->
        <div *ngFor="let layer of portalAppMapStrategy.toggleLayers" class="option_container">
            <label class="switch">
                <input type="checkbox" value="{{layer.layerName}}" [ngModel]="portalAppMapStrategy.visibleLayers.includes(layer.layerName)" (change)="showhideLayer($event)">
                <span class="slider"></span>
            </label>
            <p class="option_label">{{layer.title  | translate}}</p>
        </div>

        <div class="option_container">
            <label class="switch">
                <input type="checkbox" value="archived-data"
                    [ngModel]="portalAppMapStrategy.showArchivedData" (change)="portalAppMapStrategy.toggleArchivedPoints()">
                <span class="slider"></span>
            </label>
            <p class="option_label">{{'i18n.FIELD_PAGE.SHOW_ARCHIVED' | translate}}</p>
        </div>

        <button class="global_btn export_btn" (click)="exportTableToExcel()"
            [ngStyle]="{'display': (portalAppMapStrategy.visibleLayers.includes('treeMeasurementData-layer')) ? 'block' : 'none'}">{{'i18n.FIELD_PAGE.EXPORT_EXCEL'
            | translate}}</button>


        <!-- LATER IMPLEMENTATION V2 -->
        <!-- <mat-label>data color</mat-label>
        <div class="date_slector" *ngIf="enableBaseData">
          <input class="global_input_field" placeholder="search" (keyup)="searchedDate = $event.target.value">
          <div class="date_list">
            <div *ngFor="let date of datesToHighlight">
              <p *ngIf="date.includes(searchedDate)" (click)="dateChange($event, date)"
                [ngStyle]="{'background-color': (currentSelectedDate==date) ? '#e2dff0' : '#fff'}">{{date.substring(0, 10)}}
              </p>
            </div>
          </div>
        </div>
      
        <div class="date_slector" *ngIf="enableMetaData">
          <input class="global_input_field" placeholder="search" (keyup)="searchedDate = $event.target.value">
          <div class="date_list">
            <div *ngFor="let date of datesToHighlight">
              <p *ngIf="date.includes(searchedDate)" (click)="dateChange($event, date)"
                [ngStyle]="{'background-color': (currentSelectedDate==date) ? '#e2dff0' : '#fff'}">{{date.substring(0, 10)}}
              </p>
            </div>
          </div>
        </div> -->
    </div>

</div>