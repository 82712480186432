import { Component, Input } from '@angular/core';
import { PortalAppsService } from '../../../portal-apps.service';

@Component({
  selector: 'selected-points-component',
  templateUrl: './selected-points.component.html',
  styleUrls: ['./selected-points.component.css']
})

export class SelectedPointsComponent  {
    @Input() data: any;


    mapStrategy;
    constructor(private portalAppsService: PortalAppsService){
        this.mapStrategy = this.portalAppsService.activeApp.mapStrategy;
    }

    clearPoints(){
      this.mapStrategy.unselectAllPoints();
    }

    mutateSelectedPoints(){
      this.mapStrategy.handleMutateClickButton();
    }

    archiveSelectedPoints(){
      this.mapStrategy.archiveSelectedPoints();
    }

    unarchiveSelectedpoints(){
      this.mapStrategy.unarchiveSelectedPoints();
    }

    closeSelection(){
      const selectedMode = this.mapStrategy.pointSelectionMode.value;
      this.mapStrategy.changePointSelectionMode(selectedMode);
    }
}