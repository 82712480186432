export function companyLogoSource(map: mapboxgl.Map, image) {
    map.addImage('logo', image);
    map.addSource('logo-point', {
        type: 'geojson',
        data: {
            type: 'FeatureCollection',
            features: [
                {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [6.1231334, 51.4063859],
                    },
                    properties: {},
                },
            ],
        },
    });
}