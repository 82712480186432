import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthService} from '@auth0/auth0-angular';
import {Observable} from 'rxjs';
import {concatMap, map, take} from 'rxjs/operators';
import * as global from '../../globals';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

  httpOptions = {
    headers: new HttpHeaders({
      'Cache-Control': 'no-cache',
    })
  };

  constructor(private http: HttpClient, private auth: AuthService, private api: ApiService) {
  }

  get_dataset_difference(dataset1, dataset2, polygon): Observable<any> {
    const body = {
      polygon,
      dataset_1: dataset1,
      dataset_2: dataset2
    };

    return this.auth.user$
      .pipe(
        take(1),
        concatMap((user) => {
            return this.http.post<any>(
              global.apiUrl + global.dataset + '/visualization/tree_width/difference/dataset', body, this.httpOptions
            );
          }
        )
      );
  }

  get_dataset_difference_heatmap(dataset1, dataset2, polygon, property: string = 'circumference'): Observable<any> {
    const body = {
      polygon,
      dataset_1: dataset1,
      dataset_2: dataset2
    };

    let url = global.apiUrl + global.dataset + '/visualization/tree_width/difference/heatmap/dataset';

    if (property) {
      url = url + '?property=' + property;
    }

    return this.auth.user$
      .pipe(
        take(1),
        concatMap((user) => {
            return this.http.post<any>(url, body, this.httpOptions);
          }
        )
      );
  }

  get_tree_width_history(treeId, property: string = 'circumference'): Observable<any> {
    let url = global.apiUrl + global.dataset + '/visualization/tree_width/difference/single_tree/' + treeId;

    if (property) {
      url = url + '?property=' + property;
    }
    return this.auth.user$
      .pipe(
        take(1),
        concatMap((user) => {
            return this.http.get<any>(url, this.httpOptions);
          }
        )
      );
  }

  get_dataset_visualization(datasetId, polygon, property: string = 'circumference'): Observable<any> {
    const body = {
      polygon
    };
    let url = global.apiUrl + global.dataset + '/visualization/tree_width/dataset/' + datasetId;

    if (property) {
      url = url + '?property=' + property;
    }

    return this.auth.user$
      .pipe(
        take(1),
        concatMap((user) => {
            return this.http.post<any>(url, body, this.httpOptions);
          }
        )
      );
  }

  get_trees_amount_per_size(datasetId, polygon, property: string = 'circumference'): Observable<any> {
    const body = {
      polygon
    };
    let url = global.apiUrl + global.dataset + '/visualization/tree_width/trees_per_size/' + datasetId;

    if (property) {
      url = url + '?property=' + property;
    }
    return this.auth.user$
      .pipe(
        take(1),
        concatMap((user) => {
            return this.http.post<any>(url, body, this.httpOptions);
          }
        )
      );
  }

  get_trees_average_growth(polygon, property: string = 'circumference'): Observable<any> {
    const body = {
      polygon
    };

    let url = global.apiUrl + global.dataset + '/visualization/tree_width/growth/dataset';

    if (property) {
      url = url + '?property=' + property;
    }
    return this.auth.user$
      .pipe(
        take(1),
        concatMap((user) => {
            return this.http.post<any>(url, body, this.httpOptions);
          }
        )
      );
  }

  get_export_data(datasetId, polygon, property: string = 'circumference'): Promise<ArrayBuffer> {
    const body = {
      geo_frame: {
        polygon
      },
      dataset_id_1: datasetId,
      dataset_id_2: 0,
      min_max_values: true,
      average_growth: true,
      tree_circumference_per_class: true,
      tree_width_difference: true,
      tree_width_circumference: true
    };

    const httpOptions = {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache'
      }),
      responseType: 'arraybuffer'
    };

    let url = global.apiUrl + global.dataset + '/statistics/export';

    if (property) {
      url = url + '?property=' + property;
    }

    return this.auth.user$
      .pipe(
        take(1),
        concatMap((user) => {
            // @ts-ignore
          return this.http.post<any>(url, body, httpOptions);
          }
        ),
        map((file: ArrayBuffer) => {
          return file;
        })
      ).toPromise();
  }

  public get_summary(date, fields): Observable<any> {
    return this.api.post_with_auth(`/statistics?date=${date}`, {"field_ids":fields})
  }
}
