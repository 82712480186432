<div class="add_field_page" fxFlexFill="">

  <!-- Mapbox container -->
  <div id="map"></div>


  <!-- Right menu button -->
  <div #menu_btn class="right_menu_btn change" (click)="toggle_element('right_menu', menu_btn)">
    <div class="bar1"></div>
    <div class="bar2"></div>
    <div class="bar3"></div>
  </div>

  <!-- Right upper menu -->
  <div id="right_menu" class="right_menu scrollable global_tile" fxLayout="column" fxLayoutAlign="space-between stretch"
    fxLayoutGap="20px">
    <mat-label>{{'i18n.FIELD_PAGE.MAP_STYLE' | translate}}</mat-label>

        <div class="option_container">
            <label class="switch">
                <input type="checkbox" value="treeMeasurementData-layer" (change)="changeMapStyle($event)">
                <span class="slider"></span>
            </label>
            <p class="option_label">{{'i18n.FIELD_PAGE.SATELLITE' |
                translate}}</p>
        </div>
        <div class="global_devider"></div>
    <!-- <mat-label>{{'i18n.ADD_FIELD_PAGE.BRP_PLOTS' | translate}}</mat-label>
  <mat-slide-toggle (change)="layerVisibility('brp')" [(ngModel)]="enableBrpPlots">{{'i18n.ADD_FIELD_PAGE.BRP_ZONE' |
    translate}}</mat-slide-toggle>
  <div class="global_devider"></div> -->
    <mat-label>{{'i18n.GLOBAL.SEARCH' | translate}}</mat-label>
    <label>
      <input type="text" class="global_input_field" placeholder="{{'i18n.GLOBAL.SEARCH' | translate}}..." id="searchBar"
        name="searchBar" value="{{searchBar}}" [(ngModel)]="searchBar" (keyup.enter)="searchLocation()">
    </label>
    <button class="global_btn btn" (click)="searchLocation()">{{'i18n.GLOBAL.SEARCH' | translate}}</button>

  </div>

  <!-- Right lower menu (hidden by default) -->
  <div class="global_tile scrollable right_menu_bottom" fxLayout="column" fxLayoutAlign="space-between stretch"
    fxLayoutGap="20px" *ngIf="enableAddField">
    <h2>{{'i18n.ADD_FIELD_PAGE.FIELD' | translate}}</h2>
    <label>
      <input type="text" class="global_input_field" placeholder="{{'i18n.ADD_FIELD_PAGE.ENTER_FIELD_NAME' | translate}}"
        id="fieldName" name="fieldName" value="{{fieldName}}" [(ngModel)]="fieldName">
    </label>
    <button class="global_btn btn" (click)="saveField()">{{'i18n.ADD_FIELD_PAGE.SAVE_FIELD' |
      translate}}</button>
  </div>

</div>