import { Injectable, ViewChild  } from '@angular/core';
import mapboxgl from 'mapbox-gl';
import { environment } from 'src/environments/environment';
import { companyLogoLayer } from './map-layers/layers/company-logo-layer.function';
import { fieldBoundaryLayer } from './map-layers/layers/field-boundery-layer.function';
import { treeMetaDataLayer } from './map-layers/layers/tree-meta-data-layer.function';
import { treeMeasurementDataLayer } from './map-layers/layers/tree-measurement-data-layer.function';
import { companyLogoSource } from './map-layers/sources/company-logo-source.function';
import { fieldBoundarySource } from './map-layers/sources/field-boundary-source.function';
import { treeMetaDataSource } from './map-layers/sources/tree-meta-data-source.function';
import { treeMeasurementDataSource } from './map-layers/sources/tree-measurement-data-source.function';
import { treeMetaDataEvents } from './map-layers/event-handlers/tree-meta-data-events.functions';
import { treeMeasurementDataEvents } from './map-layers/event-handlers/tree-measurement-data-events.function';
import { IMapStrategy } from './iMapStrategy';
import { StemThicknessMeasurementData } from './map-info-components/stem-thickness-measurement-data/stem-thickness-measurement-data.component';
import { StemThicknessMetaData } from './map-info-components/stem-thickness-meta-data/stem-thickness-meta-data.component';
import { GoToFieldActionComponent } from './map-actions/go-to-field/go-to-field.components';
import { ToggleSTMLegendActionComponent } from './map-actions/toggle-legend/toggle-stm-legend.components';
import { StemThicknessLegend } from './map-info-components/stem-thickness-legend/stem-thickness-legend.component';
import * as XLSX from 'xlsx';
import { MapRowSelectionComponent } from './map-points-selection/row-selection/map-row-selection.component';
import { TreeMeasurementDataOnClickEvents } from './map-layers/event-handlers/tree-measurement-data-on-click-events.function';
import { MapTreeSelectionComponent } from './map-points-selection/tree-selection/map-tree-selection.component';
import { SelectedPointsComponent } from './floating-container-components/selected-points-component/selected-points.component';
import { removeDrawControl, TreeMeasurementDataLassoHandler, clearDrawControl } from './map-layers/event-handlers/tree-measurement-data-lasso-handler.function';
import { MapLassoSelectionComponent } from './map-points-selection/lasso-selection/map-lasso-selection.component';
import { StemThicknessMeasurementMutation } from './map-info-components/stem-thickness-mutation/stem-thickness-measurement-mutation';
import { ApiService } from '../../api.service';
import { firstValueFrom } from 'rxjs';
import { StmTreePointCreationComponent } from './map-points-creation/stm-tree-point-creation/stm-tree-point-creation.component';
import { TreeGetClickCoordinates } from './map-layers/event-handlers/tree-get-click-coordinates-event.functions';
import { geometry } from '@turf/turf';
import { FeatureCollection, Point } from 'geojson';
import { StemThicknessMeasurementCreation } from './map-info-components/stem-thickness-creation/stem-thickness-measurement-creation';
import { EventBusService } from '../../event-bus.service';
import { StmTreeRowFillCreationComponent } from './map-points-creation/stm-tree-row-fill-creation/stm-tree-row-fill-creation.component';
import { StemThicknessMeasurementRowFillCreation } from './map-info-components/stem-thickness-row-fill-creation/stem-thickness-measurement-row-fill-creation';
import * as turf from '@turf/turf';

@Injectable({
  providedIn: 'root'
})
export class StmMapStrategy implements IMapStrategy{
  map: mapboxgl.Map;


  // Information menu components
  entityInfoComponents = [                                                                                                    // For map control
    {isVisible: {value: true}, component: StemThicknessMeasurementData, data: {}, layerBinding: "treeMeasurementData-layer"}, // i = 0
    {isVisible: {value: false}, component: StemThicknessMetaData, data: {}, layerBinding: "treeData-layer"},                  // i = 1
    {isVisible: {value: true}, component: StemThicknessLegend, data: {}, layerBInding: ""}                                   // i = 2 
  ];

  // Map layer control and info components
  toggleLayers = [
    {title: "i18n.FIELD_PAGE.MEASUREMENTS", layerName: "treeMeasurementData-layer"},
    {title: "i18n.FIELD_PAGE.META", layerName: "treeData-layer"}
  ]
  visibleLayers = ['fieldBoundery-layer', 'treeMeasurementData-layer']; 

  // Map actions
  mapActions = [
    GoToFieldActionComponent,
  ]

  // In case popup is needed: Assign a component to this variable
  popUpComponentToShow = null;
  showLeftMenu;

  // point selection
  floatingContainerComponent = null;
  floatingContainerData = {value: {count: 0}};

  // Point selection
  pointsSelections = [
    MapTreeSelectionComponent,
    MapRowSelectionComponent,
    MapLassoSelectionComponent
  ];

  // Point creation
  pointsCreation = [
    StmTreePointCreationComponent,
    StmTreeRowFillCreationComponent
  ]
  pointsCreationInputComponent = {component: null, data: { value: null }}

  isCreationOn = false;
  isCreationComplete = false;
  isRowSelectionOn = false;
  isPointGenSelectionOn = false;

  // Archived control
  showArchivedData = false;

  // Point mutation
  mutationComponents = [
    {component: StemThicknessMeasurementMutation, data: {} }
  ]
  isMutationOn = false;
  isMutationComplete = false;


  // On hover data
  hoverMetaData;
  hoverMainData;

  // On click data
  clickData = {value: null};
  
  // Map ESSENTIALS
  selectedDate;
  organizationId;
  selectedField;
  treeMeasurementData = []
  dataStore_TreeMeasurmentData: { [date: string]: {} } = {};


  customerAddedTreeData = []

  // APP SPECIFIC VARS
  pointSelectionMode = { value: null };
  pointCreationMode = { value: null };
  rawMeasurementData = [];

  // map colors
  darkGreen = '#0b4000'
  red = '#ED2938';
  white = '#FFFFFF';
  blue = '#0088DD';
  yellow = '#FFEB01';
  lightGreen = '#74ff58'


  constructor(private apiService : ApiService, private eventBusService: EventBusService) {

   }

  setMapInstance(map: mapboxgl.Map): void {
    this.map = map;
  }

  setField(field){
    this.selectedField = field;
  }


  bindHoverData(hoverMainDataRef, hoverMetaDataRef){
    this.hoverMainData = hoverMainDataRef;
    this.hoverMetaData = hoverMetaDataRef;
    this.entityInfoComponents[0].data = this.hoverMainData;
    this.entityInfoComponents[1].data = this.hoverMetaData;
  }

  // Method to add app-specific layers
  addAppSpecificLayers(): void {
    companyLogoLayer(this.map);
    fieldBoundaryLayer(this.map, this.visibleLayers.includes('fieldBoundery-layer'))
    treeMetaDataLayer(this.map, this.visibleLayers.includes('treeData-layer'))
    treeMeasurementDataLayer(this.map, this.visibleLayers.includes('treeMeasurementData-layer'))
  }

  // Method to add app-specific sources
  addAppSpecificSources(): Promise<void> {
    if(this.pointsCreationInputComponent.data.value != null){
      this.pointsCreationInputComponent.data.value.pointA = null;
      this.pointsCreationInputComponent.data.value.pointB = null;
    }

    return new Promise((resolve, reject) => {
      this.map.loadImage('/assets/map-logo.png', (error, image) => {
        if (error) {
          reject(error);
        } else {
          companyLogoSource(this.map, image);
          fieldBoundarySource(this.map, this.selectedField.boundary.coordinates[0])
          treeMetaDataSource(this.map, this.treeMeasurementData)
          treeMeasurementDataSource(this.map, this.treeMeasurementData)
          resolve();
        } 
      });
    });
    
  }

  bindShowLeftMenu(showMenu){
    this.showLeftMenu = showMenu;
  }


  handleMapTreeClickEvent(data: any): void {
    if (data != null) {
      if (!this.showLeftMenu.value) {
        this.showLeftMenu.value = true;
      }

      if (this.isRowSelectionOn) {
        let rowIndex = JSON.parse(data.properties.meta).row_index;
        this.pointsCreationInputComponent.data.value = { rowIndex: rowIndex };
        this.isRowSelectionOn = false;
      }

      if(this.isAPointSelectionOn){
        this.pointsCreationInputComponent.data.value = {
          ...this.pointsCreationInputComponent.data.value,
          pointA: data
        }
        this.drawPlantingLine()
        this.isAPointSelectionOn = false;
        if(this.pointsCreationInputComponent.data.value.pointB == null){
          setTimeout(() => {
            this.isBPointSelectionOn = true;
          }, 0)
          
        }
      }

      if(this.isBPointSelectionOn){
        this.pointsCreationInputComponent.data.value = {
          ...this.pointsCreationInputComponent.data.value,
          pointB: data
        }
        this.drawPlantingLine()
        this.isBPointSelectionOn = false;
      }

      this.clickData.value = data.properties;
      this.hoverMainData.value = data.properties;
      this.hoverMetaData.value = JSON.parse(data.properties.meta);

      if (this.pointSelectionMode.value != null) {
        switch (this.pointSelectionMode.value) {
          case "row":
            // Row selection
            this.selectRow(data);
            break;

          case "tree":
            this.selectATree(data);
            break;

          default:
          // fallback

        }

        // rerender
        if (this.isMutationOn) {
          if (this.floatingContainerData.value.count != 0) {
            this.handleMutateClickButton();
            this.eventBusService.emit({ target: "mutationComponent", action: "update" })
          }
          else {
            this.isMutationOn = false;
          }

        }
      }
    }
    else {
      // click on the map
      if (this.clickData.value != null) {
        this.clickData.value = null;
        this.hoverMainData.value = {};
        this.hoverMetaData.value = {};
      }
    }

  }

  handleMapClick(object): void{
    if(this.pointCreationMode.value == "tree-creation" && !this.isRowSelectionOn && Object.keys(this.hoverMainData.value).length === 0 && Object.keys(this.hoverMetaData.value).length === 0 ){
      this.showLeftMenu.value = true;
      let newTree = {
        id: "new-point",
        geometry: {
          coordinates: [object.lngLat.lng, object.lngLat.lat],
          type: "Point"
        },
        type: "Feature",
        properties: {
          defaultRadius: 5, // Set the default radius for this feature
          hoverRadius: 10, // Set the hover radius for this feature
          circumference: 0,
          selected: false,
          toCreate: true,
          id: "new-tree"
        }
      }
      
      this.treeMeasurementData.push(newTree);
      const source = this.map.getSource('treeMeasurementData-source') as mapboxgl.GeoJSONSource;
      if (source) {
        source.setData({
          type: 'FeatureCollection',
          features: this.treeMeasurementData
        });
      }
    }

  }

  // Method to handle app-specific events
  handleAppSpecificEvents(): void {
    treeMetaDataEvents(this.map, this.hoverMetaData, this.customerAddedTreeData, this.clickData);
    treeMeasurementDataEvents(this.map, this.hoverMainData, this.pointSelectionMode, this.clickData, this.pointCreationMode);
    TreeMeasurementDataOnClickEvents(this.map, this.handleMapTreeClickEvent.bind(this))
    TreeGetClickCoordinates(this.map, this.handleMapClick.bind(this));
  }


  changeLayers(action: "add" | "remove", layer): void{
    if(action == "add"){
      this.visibleLayers.push(layer);
      this.entityInfoComponents.forEach(component => {
        if(component.layerBinding == layer){
          component.isVisible.value = true;
        }
      })
    }
    else{
      this.visibleLayers = this.visibleLayers.filter(item => item != layer);
      this.entityInfoComponents.forEach(component => {
        if(component.layerBinding == layer){
          component.isVisible.value = false;
        }
      })
    }
  }


  loadData(selectedDate, organizationId, ignoreLocalStorage = false): Promise<void>{

    this.selectedDate = selectedDate;
    this.organizationId= organizationId;
    // clear stored input;
    this.clickData.value = {};
    this.hoverMainData.value = {};
    this.hoverMetaData.value = {};
    this.showArchivedData = false;

    this.flushSelectionModesAndMutation();
    if(this.pointCreationMode.value != null){
      this.hanldeChangePointCreationMode(this.pointCreationMode.value);
    }
    
    return new Promise((resolve, reject) => {
      // Get the data source by ID and update its data
    let data: any = []
    let rawData: any = [];

    const dataSource: any = this.map.getSource('treeMeasurementData-source');
    const metaDataSource: any = this.map.getSource('treeData-source');


    let dataStore = this.dataStore_TreeMeasurmentData;
    if (dataStore[selectedDate] && dataSource != undefined && !ignoreLocalStorage) {
      // if exists in the above dataset, load that data into view
      let storedData = dataStore[selectedDate] as {type: String, features: any[], rawData: any[]};
      this.treeMeasurementData = storedData.features;
      this.rawMeasurementData = storedData.rawData;
      dataSource.setData(dataStore[selectedDate]);
      metaDataSource.setData(dataStore[selectedDate]);
      return
    }

    let message_count = 0
    var es;
    if(selectedDate != 'all'){
      es = new EventSource(`${environment.api_gateway_url}/v2/field/${this.selectedField.id}/data?date=${selectedDate}&organization_id=${organizationId}&include_raw_data=true`);
    }
    else{
      es = new EventSource(`${environment.api_gateway_url}/v2/field/${this.selectedField.id}/data/latest?&organization_id=${organizationId}&include_raw_data=true`);
    }
    es.addEventListener('message', (event) => {
      const newData = JSON.parse(event.data);
      // this.addFeatureToLayer(newData);

      rawData.push(newData)

      if(!newData.archived){
        data.push({
          id: newData.id,
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: newData.location.coordinates
          },
          properties: {
            archived: newData.archived,
            defaultRadius: 5, // Set the default radius for this feature
            hoverRadius: 10, // Set the hover radius for this feature
            id: newData.id,
            time: newData.time,
            circumference: Number(newData.data.circumference.toFixed(1)),
            selected: false,
            toCreate: false,
            treeType: newData.metadata.tree_type,
            bucket: newData.data.size_bucket_circumference,
            meta: newData.metadata,
          }
        });
      }
      
      

      if (dataSource) {

        message_count += 1;

        if (message_count > 50) {
          
          dataSource.setData({
            type: 'FeatureCollection',
            features: data
          })
          metaDataSource.setData({
            type: 'FeatureCollection',
            features: data
          })
          
          message_count = 0
        }
      }
    });

    es.onerror = () => {
      const dataSource2: any = this.map.getSource('treeMeasurementData-source');
      const dataSource3: any = this.map.getSource('treeData-source');

      if (dataSource2) {

        dataSource2.setData({
          type: 'FeatureCollection',
          features: data
        })

        dataSource3.setData({
          type: 'FeatureCollection',
          features: data
        })
      }
      dataStore[selectedDate] = {
        type: 'FeatureCollection',
        features: data,
        rawData: rawData
      }
      es.close();
    }

    this.treeMeasurementData = data;

    this.rawMeasurementData = rawData;
    
    resolve(data)
    })
  }

  exportTableToExcel(selectedDate) {
    if ((this.dataStore_TreeMeasurmentData[selectedDate]['features'] as []).length > 0) {
      let data = []
      let tmp_data = this.dataStore_TreeMeasurmentData[selectedDate]['features']
      for (let item of tmp_data) {
        let raw_json = {
          "tree_id": item.id, "time": item.properties.time, "latitude": item.geometry.coordinates[1],
          "longitude": item.geometry.coordinates[0], "row_number": item.properties.meta.row_index, "tree_type": item.properties.meta.tree_type,
          "tree_circumference_cm": item.properties.circumference, "tree_size_bucket": item.properties.bucket,
          "damaged": item.properties.meta.damaged, "note": item.properties.meta.note, "pole": item.properties.meta.has_pole, "ab_quality": item.properties.meta.abQuality
        }
        let json = this.createTranslatedJSON(raw_json)
        if (item.properties.meta.metadata) {
          for (const [key, value] of Object.entries(item.properties.meta.metadata)) {
            if (key == "tree_id") {
              json["meta_tree_id"] = value;
            } else {
              json[key] = value;
            }
          }
        }
        data.push(json)
      }

      const ws = XLSX.utils.json_to_sheet(data);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, ws, 'Aantallen');
      XLSX.writeFile(workBook, `${this.selectedField.name}_AgroWizard_Export.xlsx`);
    }
  }
  export_translations = {
    eng: {
      tree_id: "Tree ID",
      time: "Time",
      latitude: "Latitude",
      longitude: "Longitude",
      vehicle_speed_kmh: "Vehicle Speed (km/h)",
      row_number: "Row Number",
      tree_type: "Tree Type",
      tree_circumference_cm: "Tree Circumference (cm)",
      tree_size_bucket: "Tree Size Bucket",
      damaged: "Damaged",
      note: "Note",
      pole: "Pole",
      ab_quality: "A/B Quality"
    },
    nld: {
      tree_id: "Boom ID",
      time: "Tijd",
      latitude: "Breedtegraad",
      longitude: "Lengtegraad",
      vehicle_speed_kmh: "Voertuigsnelheid (km/u)",
      row_number: "Rijnummer",
      tree_type: "Boomtype",
      tree_circumference_cm: "Boomomtrek (cm)",
      tree_size_bucket: "Boomgrootte Categorie",
      damaged: "Beschadigd",
      note: "Opmerking",
      pole: "Paal",
      ab_quality: "A/B Kwaliteit"
    }
  };

  createTranslatedJSON(json) {
    const translatedJson = {};

    let language = 'eng'

    if (localStorage.getItem('lang')) {
      language = localStorage.getItem('lang')
    }

    for (const key in json) {
      // Use the translation from the dictionary if available, otherwise use the original key
      const translatedKey = this.export_translations[language][key] || key;

      // Add the translated key and the corresponding value to the new JSON
      translatedJson[translatedKey] = json[key];
    }

    return translatedJson;
  }

  // Custom map actions functions

  handleMutateClickButton(): void{
    this.showLeftMenu.value = true;
    let data = this.mutationComponents[0].data as {value: any};
    data.value = null;

    if(this.floatingContainerData.value.count == 1){
      let selectedTree;
      for(let i=0; i < this.treeMeasurementData.length; i++){
          if(this.treeMeasurementData[i].properties.selected){
            selectedTree = this.treeMeasurementData[i];
            break;
          }  
      }

      data.value = {
          circumference: selectedTree.properties.circumference,
          bucket: selectedTree.properties.bucket,
          treeType: selectedTree.properties.treeType,
          damaged: selectedTree.properties.meta.damaged,
          hasPole: selectedTree.properties.meta.has_pole,
          note: selectedTree.properties.meta.note,
          abQuality: selectedTree.properties.meta.abQuality
        }

    }
    

    this.showLeftMenu.value = true;
    this.isMutationOn = true;
    
  }

  zoomToField() {
    this.map.setZoom(16);
    this.map.flyTo({ center: this.selectedField.center.coordinates, essential: true });
  }

  



  // CUstom selction
  flushSelectionModesAndMutation(){
    this.pointSelectionMode.value = null;
    this.map.getCanvas().style.cursor = "grab";
    this.unselectAllPoints();
    this.floatingContainerComponent = null;
    this.floatingContainerData.value.count = 0;
    this.isMutationOn = false;
    setTimeout(() => {
      removeDrawControl(this.map)
    }, 20);
    
    let data = this.mutationComponents[0].data as {value: any}
    data.value = null;
  }

  changePointSelectionMode(mode){
    if(this.pointSelectionMode.value == mode){
      this.flushSelectionModesAndMutation();
    }
    else{
      if(this.pointCreationMode.value != null){
        this.hanldeChangePointCreationMode(this.pointCreationMode.value);
      }
      this.pointSelectionMode.value = mode;
      this.floatingContainerComponent = SelectedPointsComponent;
      this.map.getCanvas().style.cursor = "crosshair";

      if(mode == "lasso"){
        TreeMeasurementDataLassoHandler(this.map, this.handleNewLassoSelection.bind(this));
      }
      else{
        
        removeDrawControl(this.map)
      }
    }
  }

  unselectAllPoints(){
    this.isMutationOn = false;
    this.floatingContainerData.value.count = 0;
    for(let i=0; i < this.treeMeasurementData.length; i++){
      this.treeMeasurementData[i].properties.selected = false;
    }
    const source = this.map.getSource('treeMeasurementData-source') as mapboxgl.GeoJSONSource;
    if (source) {
      source.setData({
        type: 'FeatureCollection',
        features: this.treeMeasurementData
      });
    }

    try{

      if(this.pointSelectionMode.value == "lasso"){
        clearDrawControl();
      }
      
    }
    catch{}


  }

  unselectRow(dataPoint){
    const row_index_selected = JSON.parse(dataPoint.properties.meta).row_index;

    for(let i=0; i < this.treeMeasurementData.length; i++){
      if(this.treeMeasurementData[i].properties.meta.row_index == row_index_selected){
        if(this.treeMeasurementData[i].properties.selected){
          this.treeMeasurementData[i].properties.selected = false;
          this.floatingContainerData.value.count -= 1;
        }
        
      }
    }
    const source = this.map.getSource('treeMeasurementData-source') as mapboxgl.GeoJSONSource;
    if (source) {
      source.setData({
        type: 'FeatureCollection',
        features: this.treeMeasurementData
      });
    }
  }

  selectRow(dataPoint){
    const row_index_selected = JSON.parse(dataPoint.properties.meta).row_index;

    for(let i=0; i < this.treeMeasurementData.length; i++){
      if(this.treeMeasurementData[i].properties.meta.row_index == row_index_selected){
        if(!this.treeMeasurementData[i].properties.selected){
          this.treeMeasurementData[i].properties.selected = true;
          this.floatingContainerData.value.count += 1;
        }
        else{
          this.unselectRow(dataPoint)
          break;
        }
        
      }
    }
    const source = this.map.getSource('treeMeasurementData-source') as mapboxgl.GeoJSONSource;
    if (source) {
      source.setData({
        type: 'FeatureCollection',
        features: this.treeMeasurementData
      });
    }
  }


  selectATree(dataPoint){
    const selectedId = dataPoint.properties.id;
    
    for(let i=0; i < this.treeMeasurementData.length; i++){
      if(this.treeMeasurementData[i].properties.id == selectedId){
        if(!this.treeMeasurementData[i].properties.selected){
        
          this.treeMeasurementData[i].properties.selected = true;
          this.floatingContainerData.value.count += 1
          break;
        }
        else{
          this.treeMeasurementData[i].properties.selected = false;
          this.floatingContainerData.value.count -= 1;
          break;
        }
        
      }
    }
    
    const source = this.map.getSource('treeMeasurementData-source') as mapboxgl.GeoJSONSource;
    if (source) {
      source.setData({
        type: 'FeatureCollection',
        features: this.treeMeasurementData
      });
    }
  }

  handleNewLassoSelection(data){
    const idsSet = new Set(data.map(item => item.id));
    this.floatingContainerData.value.count = 0;
    for(let i = 0; i < this.treeMeasurementData.length; i++){
      this.treeMeasurementData[i].properties.selected = false;
      if(idsSet.has(this.treeMeasurementData[i].properties.id)){
          this.treeMeasurementData[i].properties.selected = true;
          this.floatingContainerData.value.count += 1         
        
      }
    }

    const source = this.map.getSource('treeMeasurementData-source') as mapboxgl.GeoJSONSource;
    if (source) {
      source.setData({
        type: 'FeatureCollection',
        features: this.treeMeasurementData
      });
    }

    // update info bar
    if(this.isMutationOn){
      if(this.floatingContainerData.value.count != 0){
        this.handleMutateClickButton();
        this.eventBusService.emit({target: "mutationComponent", action: "update"})
      }
      else{
        this.isMutationOn = false;
      }

    }

  }

  async mutateSelectedPoints(inputs){

    const updatedTrees = [];

    for(let i=0; i < this.treeMeasurementData.length; i++){
      if(this.treeMeasurementData[i].properties.selected){
        let tree = this.rawMeasurementData.find(item => item.id == this.treeMeasurementData[i].id);
        if(this.floatingContainerData.value.count > 1){
          tree = {
            ...tree,
            data: {
              ...tree.data,
              circumference: inputs.circumference !== 0 ? (inputs.circumference + tree.data.circumference) : tree.data.circumference,
              size_bucket_circumference: inputs.bucket !== "" && inputs.bucket !== undefined ? inputs.bucket : tree.data.size_bucket_circumference,
            },
            metadata: {
              ...tree.metadata,
              has_pole: inputs.hasPole !== null ? inputs.hasPole : tree.metadata.has_pole,
              damaged: inputs.damaged !== null ? inputs.damaged : tree.metadata.damaged,
              note: !inputs.note ? tree.metadata.note : inputs.note,
              tree_type: inputs.treeType != null ? inputs.treeType : tree.metadata.tree_type,
              abQuality: inputs.abQuality != null ? inputs.abQuality : tree.metadata.abQuality
            },
  
          }
        }
        else{
          tree = {
            ...tree,
            data: {
              ...tree.data,
              circumference: inputs.circumference !== 0 ? inputs.circumference : tree.data.circumference,
              size_bucket_circumference: inputs.bucket !== "" && inputs.bucket !== undefined ? inputs.bucket : tree.data.size_bucket_circumference,
            },
            metadata: {
              ...tree.metadata,
              has_pole: inputs.hasPole !== null ? inputs.hasPole : tree.metadata.has_pole,
              damaged: inputs.damaged !== null ? inputs.damaged : tree.metadata.damaged,
              note: !inputs.note ? tree.metadata.note : inputs.note,
              tree_type: inputs.treeType != null ? inputs.treeType : tree.metadata.tree_type,
              abQuality: inputs.abQuality != null ? inputs.abQuality : tree.metadata.abQuality
            },
  
          }
        }

        updatedTrees.push(tree);
      }
    }

    try{
      
      this.apiService.mutateSTMObject(updatedTrees).subscribe({
        next: (response) => {
          this.eventBusService.emit({target: "mutationComponent", action: "success"})
          // Handle successful response
          setTimeout(() => {
          this.updateMutatedPoints(updatedTrees);
          this.flushSelectionModesAndMutation();
          
          // handle points
      }, 1000)
        },
        error: (error) => {
          // Handle error response
          alert("There was an error updating the objects: " + JSON.stringify(error));
          // You can show an error message to the user here
        },
        complete: () => {

        }
      });

      

    }
    catch(e){
      alert("There was an error updating the objects: " + JSON.stringify(e));
    }
  }
  

  updateMutatedPoints(updatedData){
    // update data to display
    for(let i=0; i < this.treeMeasurementData.length; i++){
      for(let j=0; j<updatedData.length; j++){
        if(updatedData[j].id == this.treeMeasurementData[i].id){
          
          this.treeMeasurementData[i].properties.bucket = updatedData[j].data.size_bucket_circumference;
          this.treeMeasurementData[i].properties.circumference = updatedData[j].data.circumference;
          this.treeMeasurementData[i].properties.meta.has_pole = updatedData[j].metadata.has_pole;
          this.treeMeasurementData[i].properties.meta.damaged = updatedData[j].metadata.damaged;
          this.treeMeasurementData[i].properties.meta.note = updatedData[j].metadata.note;
          this.treeMeasurementData[i].properties.meta.tree_type = updatedData[j].metadata.tree_type;
          this.treeMeasurementData[i].properties.treeType = updatedData[j].metadata.tree_type;
          this.treeMeasurementData[i].properties.meta.abQuality = updatedData[j].metadata.abQuality;
        }
      }
    }

    // update raw references
    for(let i=0; i < this.rawMeasurementData.length; i++){
      for(let j=0; j<updatedData.length; j++){
        if(updatedData[j].id == this.rawMeasurementData[i].id){
          this.rawMeasurementData[i].data.size_bucket_circumference = updatedData[j].data.size_bucket_circumference;
          this.rawMeasurementData[i].data.circumference = updatedData[j].data.circumference;
          this.rawMeasurementData[i].metadata.has_pole = updatedData[j].metadata.has_pole;
          this.rawMeasurementData[i].metadata.damaged = updatedData[j].metadata.damaged;
          this.rawMeasurementData[i].metadata.note = updatedData[j].metadata.note;
          this.rawMeasurementData[i].metadata.tree_type = updatedData[j].metadata.tree_type;
          this.rawMeasurementData[i].metadata.abQuality = updatedData[j].metadata.abQuality;
        }
      }
    }


    this.dataStore_TreeMeasurmentData[this.selectedDate] = {
      type: 'FeatureCollection',
      features: this.treeMeasurementData,
      rawData: this.rawMeasurementData
    }

    const metaDataSource = this.map.getSource("treeData-source") as mapboxgl.GeoJSONSource;
    if(metaDataSource){
      metaDataSource.setData({
        type: 'FeatureCollection',
        features: this.treeMeasurementData
      });
    }

    const mainDatasource = this.map.getSource('treeMeasurementData-source') as mapboxgl.GeoJSONSource;
    if (mainDatasource) {
      mainDatasource.setData({
        type: 'FeatureCollection',
        features: this.treeMeasurementData
      });
    }

    if(this.selectedDate == 'all'){
      updatedData.forEach(updatedData => {
        const date = updatedData.time.split("T")[0];
        let dataset = this.dataStore_TreeMeasurmentData[date] as {type: String, features: any[], rawData: any[]};

        dataset.features.forEach(datasetPoint => {
          if(datasetPoint.id == updatedData.id){
            console.log(datasetPoint);
            console.log(updatedData)

            datasetPoint.properties.bucket = updatedData.data.size_bucket_circumference;
            datasetPoint.properties.circumference = updatedData.data.circumference;
            datasetPoint.properties.meta.has_pole = updatedData.metadata.has_pole;
            datasetPoint.properties.meta.damaged = updatedData.metadata.damaged;
            datasetPoint.properties.meta.note = updatedData.metadata.note;
            datasetPoint.properties.meta.tree_type = updatedData.metadata.tree_type;
            datasetPoint.properties.treeType = updatedData.metadata.tree_type;
            datasetPoint.properties.meta.abQuality = updatedData.metadata.abQuality;
          }
        })

        dataset.rawData.forEach(datasetPoint => {
          if(datasetPoint.id == updatedData.id){
            datasetPoint.data.size_bucket_circumference = updatedData.data.size_bucket_circumference;
            datasetPoint.data.circumference = updatedData.data.circumference;
            datasetPoint.metadata.has_pole = updatedData.metadata.has_pole;
            datasetPoint.metadata.damaged = updatedData.metadata.damaged;
            datasetPoint.metadata.note = updatedData.metadata.note;
            datasetPoint.metadata.tree_type = updatedData.metadata.tree_type;
            datasetPoint.metadata.abQuality = updatedData.metadata.abQuality;
          }
        })
      })
    }

    this.clickData.value = null;
    this.hoverMainData.value = {};
    this.hoverMetaData.value = {};


  }

  toggleArchivedPoints(){
    this.showArchivedData = !this.showArchivedData;

    this.hoverMainData.value = {}
    this.hoverMetaData.value = {}
    this.clickData.value = null;
    this.floatingContainerData.value.count = 0;


    if(this.pointCreationMode.value != null){
      this.pointCreationMode.value = null;
      this.isCreationOn = false;
    }

    
    if(this.showArchivedData){
      let dataToShow = []
      for(let i=0; i < this.rawMeasurementData.length; i++){
        if(this.rawMeasurementData[i].archived){
          dataToShow.push({
            id: this.rawMeasurementData[i].id,
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: this.rawMeasurementData[i].location.coordinates
            },
            properties: {
              archived: this.rawMeasurementData[i].archived,
              defaultRadius: 5, // Set the default radius for this feature
              hoverRadius: 10, // Set the hover radius for this feature
              id: this.rawMeasurementData[i].id,
              time: this.rawMeasurementData[i].time,
              circumference: Number(this.rawMeasurementData[i].data.circumference.toFixed(1)),
              selected: false,
              toCreate: false,
              treeType: this.rawMeasurementData[i].metadata.tree_type,
              bucket: this.rawMeasurementData[i].data.size_bucket_circumference,
              meta: this.rawMeasurementData[i].metadata,
            }
          })
        }
      }
      this.treeMeasurementData = dataToShow;

      const metaDataSource = this.map.getSource("treeData-source") as mapboxgl.GeoJSONSource;
    if(metaDataSource){
      metaDataSource.setData({
        type: 'FeatureCollection',
        features: dataToShow
      });
    }

    const mainDatasource = this.map.getSource('treeMeasurementData-source') as mapboxgl.GeoJSONSource;
    if (mainDatasource) {
      mainDatasource.setData({
        type: 'FeatureCollection',
        features: dataToShow
      });
    }
    }
    else{
      this.loadTreeMainDataFromRaw();
    }
  }

  archiveSelectedPoints(){
    
    const archivedTrees = [];

    for(let i=0; i < this.treeMeasurementData.length; i++){
      if(this.treeMeasurementData[i].properties.selected){

        archivedTrees.push(this.treeMeasurementData[i].id);
      }
    }


    this.apiService.archiveSTMObject(archivedTrees).subscribe({
      next: (response) => {
        // Handle successful response
        this.updateArchivedPointsLocally(archivedTrees);
        this.flushSelectionModesAndMutation();
      },
      error: (error) => {
        // Handle error response
        alert("There was an error updating the objects: " + JSON.stringify(error));
        // You can show an error message to the user here
      },
      complete: () => {

      }
    });

  }

  unarchiveSelectedPoints(){
    const archivedTrees = [];

    for(let i=0; i < this.treeMeasurementData.length; i++){
      if(this.treeMeasurementData[i].properties.selected){

        archivedTrees.push(this.treeMeasurementData[i].id);
      }
    }


    this.apiService.unarchiveSTMObject(archivedTrees).subscribe({
      next: (response) => {
        // Handle successful response
        this.updateUnarchivedPointsLocally(archivedTrees);
        this.flushSelectionModesAndMutation();
      },
      error: (error) => {
        // Handle error response
        alert("There was an error updating the objects: " + JSON.stringify(error));
        // You can show an error message to the user here
      },
      complete: () => {

      }
    });
  }

  loadTreeMainDataFromRaw(){
    const dataToSet = [];

    for(let i=0; i < this.rawMeasurementData.length; i++){
      if(!this.rawMeasurementData[i].archived){
        dataToSet.push({
          id: this.rawMeasurementData[i].id,
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: this.rawMeasurementData[i].location.coordinates
          },
          properties: {
            archived: this.rawMeasurementData[i].archived,
            defaultRadius: 5, // Set the default radius for this feature
            hoverRadius: 10, // Set the hover radius for this feature
            id: this.rawMeasurementData[i].id,
            time: this.rawMeasurementData[i].time,
            circumference: Number(this.rawMeasurementData[i].data.circumference.toFixed(1)),
            selected: false,
            toCreate: false,
            treeType: this.rawMeasurementData[i].metadata.tree_type,
            bucket: this.rawMeasurementData[i].data.size_bucket_circumference,
            meta: this.rawMeasurementData[i].metadata
          }
        })
      }
    }
    this.treeMeasurementData = dataToSet;

    const metaDataSource = this.map.getSource("treeData-source") as mapboxgl.GeoJSONSource;
    if(metaDataSource){
      metaDataSource.setData({
        type: 'FeatureCollection',
        features: this.treeMeasurementData
      });
    }

    const mainDatasource = this.map.getSource('treeMeasurementData-source') as mapboxgl.GeoJSONSource;
    if (mainDatasource) {
      mainDatasource.setData({
        type: 'FeatureCollection',
        features: this.treeMeasurementData
      });
    }

  }

  updateUnarchivedPointsLocally(archivedIds){
    const dataStore = this.dataStore_TreeMeasurmentData[this.selectedDate] as {type: any, features: any, rawData: any}
    const mainDataFromDataStore = dataStore.features;
    // update data to display
    for(let i = this.treeMeasurementData.length - 1; i >= 0; i--){
      for(let j=0; j<archivedIds.length; j++){
        if(archivedIds[j] == this.treeMeasurementData[i].id){
          this.treeMeasurementData[i].properties.selected = false;
          mainDataFromDataStore.push(this.treeMeasurementData[i]);
          this.treeMeasurementData.splice(i, 1);
          break;
        }
      }
    }

    // update raw references
    for(let i=0; i < this.rawMeasurementData.length; i++){
      for(let j=0; j<archivedIds.length; j++){
        if(archivedIds[j] == this.rawMeasurementData[i].id){
          this.rawMeasurementData[i].archived = false;
        }
      }
    }


    if (this.dataStore_TreeMeasurmentData['all']) {
      // update data to display
      const dataset = this.dataStore_TreeMeasurmentData[this.selectedDate] as {type: any, features: any, rawData: any}
      for (let i = dataset.features.length - 1; i >= 0; i--) {
        for (let j = 0; j < archivedIds.length; j++) {
          if (archivedIds[j] == dataset.features[i].id) {
            const alltimeDate = this.dataStore_TreeMeasurmentData['all'] as {type: any, features: any, rawData: any}
            alltimeDate.features.push(dataset.features[i]);
            break;
          }
        }
      }

      // update raw references
      for (let i = 0; i < dataset.rawData.length; i++) {
        for (let j = 0; j < archivedIds.length; j++) {
          if (archivedIds[j] == dataset.rawData[i].id) {
            const alltimeDate = this.dataStore_TreeMeasurmentData['all'] as {type: any, features: any, rawData: any}
            alltimeDate.rawData.push(dataset.rawData[i]);
          }
        }
      }
    }



    dataStore.rawData =  this.rawMeasurementData;

    this.clickData.value = null;
    this.hoverMainData.value = {};
    this.hoverMetaData.value = {};

  }

  updateArchivedPointsLocally(archivedIds){
    // update data to display
    for(let i = this.treeMeasurementData.length - 1; i >= 0; i--){
      for(let j=0; j<archivedIds.length; j++){
        if(archivedIds[j] == this.treeMeasurementData[i].id){
          this.treeMeasurementData.splice(i, 1);
          break;
        }
      }
    }

    for(let i=0; i < this.rawMeasurementData.length; i++){
      for(let j=0; j<archivedIds.length; j++){
        if(archivedIds[j] == this.rawMeasurementData[i].id){
          this.rawMeasurementData[i].archived = true;
        }
      }
    }


    this.dataStore_TreeMeasurmentData[this.selectedDate] = {
      type: 'FeatureCollection',
      features: this.treeMeasurementData,
      rawData: this.rawMeasurementData
    }

    // update raw references
    
    if(this.selectedDate == 'all'){
      for(let j=0; j<archivedIds.length; j++){
        const archivedId = archivedIds[j];

        // find dataset
      
      datasetLoop:
      for (let date in this.dataStore_TreeMeasurmentData) {
        if (this.dataStore_TreeMeasurmentData.hasOwnProperty(date)) {
          const dataset = this.dataStore_TreeMeasurmentData[date] as {type: any, features: any, rawData: any};

          for (let i = 0; i < dataset.rawData.length; i++) {
            const datasetData = dataset.rawData[i];
            if (datasetData.id == archivedId) {
              datasetData.archived = true; 
              break datasetLoop; 
            }
          }
        }
      }

      datasetLoop:
      for (let date in this.dataStore_TreeMeasurmentData) {
        if (this.dataStore_TreeMeasurmentData.hasOwnProperty(date)) {
          const dataset = this.dataStore_TreeMeasurmentData[date] as {type: any, features: any, rawData: any};

          for (let i = 0; i < dataset.features.length; i++) {
            const datasetData = dataset.features[i];
            if (datasetData.id == archivedId) {
              dataset.features.splice(i, 1);
              break datasetLoop; 
            }
          }
        }
      }
        
      }
    }
    

    this.clickData.value = null;
    this.hoverMainData.value = {};
    this.hoverMetaData.value = {};

  }

  // Point creation
  hanldeChangePointCreationMode(mode){
    if(this.pointCreationMode.value == mode){
      this.pointCreationMode.value = null;
      this.isCreationOn = false;
      this.map.getCanvas().style.cursor = "grab"
      this.removePlantingLine();
      setTimeout(() => {
        this.clearNewTreePoints();
      }, 0)
      
    }
    else{
      if(this.pointSelectionMode.value != null){
        this.changePointSelectionMode(this.pointSelectionMode.value);
      }
      this.pointCreationMode.value = mode;
      this.map.getCanvas().style.cursor = "crosshair";
      this.pointsCreationInputComponent.component = null;

      setTimeout(() => {
        switch(mode){
          case "row-fill-creation":
            this.pointsCreationInputComponent.component = StemThicknessMeasurementRowFillCreation
            this.pointsCreationInputComponent.data.value = {};
            this.isAPointSelectionOn = true;
            break;
          case "tree-creation":
            this.pointsCreationInputComponent.component = StemThicknessMeasurementCreation;
            this.pointsCreationInputComponent.data.value = {};
            break;
        }
      }, 0)
      

      this.showLeftMenu.value = true;
      this.isCreationOn = true;
    }
  }

  createNewStmTrees(inputs){
    let newTrees = [];

    
    let dateTimestamp;
    if(this.selectedDate != 'all'){
      const dateObj = new Date(this.selectedDate);
      dateTimestamp = dateObj.toISOString();
    }
    else{
      const dateObj = new Date();
      dateTimestamp = dateObj.toISOString();
    }

    for(let i = 0; i < this.treeMeasurementData.length; i++){
      if(this.treeMeasurementData[i].id == "new-point"){
          let tree = {
            time: dateTimestamp,
            data: {
              circumference: inputs.circumference,
              size_bucket_circumference: inputs.bucket
            },
            location: {
              type: "Point",
              coordinates: this.treeMeasurementData[i].geometry.coordinates
            },
            metadata:{
              abQuality: inputs.abQuality,
              damaged: inputs.damaged,
              has_pole: inputs.hasPole,
              note: inputs.note,
              row_index: inputs.rowIndex,
              tree_type: inputs.treeType,
            },
            lifetime: 5
          }
          newTrees.push(tree) 
      }
    }

    this.apiService.createSTMTree(newTrees, this.selectedField.id).subscribe({
      next: (response) => {
        this.eventBusService.emit({target: "creationComponent", action: "success"})
        // Handle successful response
        setTimeout(() => {
          this.loadData(this.selectedDate, this.organizationId, true).then(() => {
            this.hanldeChangePointCreationMode(this.pointCreationMode.value)
          })
    }, 1000)
      },
      error: (error) => {
        // Handle error response
        this.eventBusService.emit({target: "creationComponent", action: "error", message: JSON.stringify(error)})
      },
      complete: () => {

      }
    });
    
  }


  // New trees row generation
  isAPointSelectionOn = false;
  selectPointAForTreeGen(){
    this.isAPointSelectionOn = true;
  }

  isBPointSelectionOn = false;
  selectPointBForTreeGen(){
    this.isBPointSelectionOn = true;
  }

  drawPlantingLine(){
    if(this.isCreationOn && this.pointsCreationInputComponent.data.value.pointA != null && this.pointsCreationInputComponent.data.value.pointB != null){
      this.clearNewTreePoints();
      const pointA = this.pointsCreationInputComponent.data.value.pointA;
      const pointB = this.pointsCreationInputComponent.data.value.pointB;


      const coordinates = [
        pointA.coordinates, // Start point [lng, lat]
        pointB.coordinates // End point [lng, lat]
      ];
  
      // Add a source for the line
      const plantingLineSource = this.map.getSource("planting-line");
      if(plantingLineSource){
        const newGeoJSON: FeatureCollection = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'LineString',
                coordinates: coordinates
              },
              properties: {}
            }
          ]
        };

        const geoJsonSource = plantingLineSource as mapboxgl.GeoJSONSource;
        // Update the source data with the new GeoJSON
        geoJsonSource.setData(newGeoJSON);
      }
      else{
        this.map.addSource('planting-line', {
          type: 'geojson',
          data: {
            type: 'Feature',
            properties: {
            },
            geometry: {
              type: 'LineString',
              coordinates: coordinates
            }
          }
        });

        // Add the line layer
      this.map.addLayer({
        id: 'plantingLineLayer',
        type: 'line',
        source: 'planting-line',
        layout: {
          'line-join': 'bevel',
          'line-cap': 'butt'
        },
        paint: {
          'line-color': '#6356A1', // Line color
          'line-width': 4, // Line width
          'line-dasharray': [3, 1]
        }
      }, "treeMeasurementData-layer");
      }
      
  
      

    }
  }

  removePlantingLine(){
    // remove the planting line
    if (this.map.getLayer("plantingLineLayer")) {
      this.map.removeLayer("plantingLineLayer");
    }
    if (this.map.getSource("planting-line")) {
      this.map.removeSource("planting-line");
    }

    if(this.pointsCreationInputComponent.data.value != null){
      this.pointsCreationInputComponent.data.value.pointA = null;
      this.pointsCreationInputComponent.data.value.pointB = null;
    }
    
  }

  clearNewTreePoints(){
    // remove the points
    if (this.treeMeasurementData.some(obj => obj.id != "new-point")) {
      this.treeMeasurementData = this.treeMeasurementData.filter(obj => obj.id != "new-point");

      const source = this.map.getSource('treeMeasurementData-source') as mapboxgl.GeoJSONSource;
      if (source) {
        source.setData({
          type: 'FeatureCollection',
          features: this.treeMeasurementData
        });
      }
    }

  }

  generateTreesOnPlantingLine(plantingDistance){
    if(this.isCreationOn && this.pointsCreationInputComponent.data.value.pointA != null && this.pointsCreationInputComponent.data.value.pointB != null && plantingDistance != "" && plantingDistance != 0){
      this.clearNewTreePoints();
      const pointA = this.pointsCreationInputComponent.data.value.pointA;
      const pointB = this.pointsCreationInputComponent.data.value.pointB;
      const coordinates = [
        pointA.coordinates, // Start point [lng, lat]
        pointB.coordinates // End point [lng, lat]
      ];

      // Calculate the length of the line
      const line = turf.lineString(coordinates);
      const lineLength = turf.length(line, { units: 'meters' });

      // const actualPlantingDistance = lineLength - plantingDistance + 0.5;
      // const numberOfPoints = Math.floor(actualPlantingDistance / plantingDistance);

      // Calculate the number of points
      const numberOfPoints = Math.floor(lineLength / plantingDistance);



      // Generate points along the line
    for (let i = 1; i <= numberOfPoints; i++) {
      const segment = i * plantingDistance;
      const point = turf.along(line, segment / 1000, { units: 'kilometers' });

      let newTree = {
        id: "new-point",
        geometry: {
          coordinates: point.geometry.coordinates,
          type: "Point"
        },
        type: "Feature",
        properties: {
          defaultRadius: 5, // Set the default radius for this feature
          hoverRadius: 10, // Set the hover radius for this feature
          circumference: 0,
          selected: false,
          toCreate: true,
          id: "new-tree"
        }
      }
      
      this.treeMeasurementData.push(newTree);
    }

    const source = this.map.getSource('treeMeasurementData-source') as mapboxgl.GeoJSONSource;
    if (source) {
      source.setData({
        type: 'FeatureCollection',
        features: this.treeMeasurementData
      });
    }
    }
  }





}