import { Component, OnInit } from '@angular/core';
import { MapFacade } from '../../dashboard/map/MapFacade';
import { LngLatLike, Map } from 'mapbox-gl';
import { LayerService } from '../../services/layer.service';
import { Observable } from 'rxjs';
import { FieldService } from '../../services/field.service';
import { Router } from '@angular/router';
import mapboxgl from 'mapbox-gl';
import { environment } from 'src/environments/environment';
import { PortalAppsService } from 'src/app/services/portal-apps/portal-apps.service';

@Component({
  selector: 'app-add-field-page',
  templateUrl: './add-field-page.component.html',
  styleUrls: ['./add-field-page.component.css']
})
export class AddFieldPageComponent implements OnInit {
  map: mapboxgl.Map;

  constructor(private mapFacade: MapFacade, private fieldService: FieldService, private route: Router, private portalAppsService: PortalAppsService) {
    this.enableAddField = false;
    this.selectedBrpZone$ = this.mapFacade.SelectedBrpZone$;
    this.selectedBrpZone$.subscribe((res: []) => {
        if (res !== undefined) {
          if (res.length > 0) {
            this.selectedBrpZone = res;
            this.enableAddField = true; 
          }
        }
      }
    );
  }
  bbox: number[][];
  layers: LayerService;
  selectedBrpZone$: Observable<number[][]>;
  selectedBrpZone;
  enableAddField: boolean;
  fieldName: string;
  searchBar: string;

  max = 100;
  min = 0;
  opacityValue = 100;

  enableBrpPlots = false;

  satellite = false;
  heightMap = false;

  num = 15;

  center: LngLatLike;

  ngOnInit(): void {
    this.enableAddField = false;

    this.center = [6.1231334, 51.4063859];


    mapboxgl.accessToken = environment.mapbox.accessToken;

    this.map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/outdoors-v12',
      center: this.center,
      zoom: 16
    });
    this.map.on('load', () => {
      this.loadMap()
    });
    
  }

  loadMap() {
    this.map.on('style.load', () => {
      this.layers.setZIndexes();
    });
    
    this.updateBbox();
    this.layers = new LayerService(this.mapFacade, this.map);
    this.map.on('draw.delete', d => {
      this.enableAddField = false;
    });
  }

  updateBbox() {
    if (this.map.getZoom() > 14) {
      const bounds = this.map.getBounds();
      this.bbox = [
        [bounds.getNorthEast().lng, bounds.getNorthEast().lat],
        [bounds.getNorthEast().lng, bounds.getSouthWest().lat],
        [bounds.getSouthWest().lng, bounds.getSouthWest().lat],
        [bounds.getSouthWest().lng, bounds.getNorthEast().lat],
        [bounds.getNorthEast().lng, bounds.getNorthEast().lat]
      ];
      this.mapFacade.updateBbox(this.bbox);
    }
  }

  toggle_element(element_id: string, e:HTMLElement) {
    var el = document.getElementById(element_id)
    if(el.style.visibility == 'hidden'){
      el.style.visibility = 'visible'
    }
    else {
      el.style.visibility = 'hidden'
    }
    e.classList.toggle("change")
  }

  layerVisibility(layer: string) {
    
    this.layers.updateVisibility(layer);
  }

  changeMapStyle(e: any) {

    let style = 'mapbox://styles/mapbox/outdoors-v12'
    if (e.currentTarget.checked) {
      style = 'mapbox://styles/mapbox/satellite-streets-v12'
    }

    // Update the map style URL with the desired style
    this.map.setStyle(style);
  }

  async saveField() {
    const app = this.portalAppsService.activeApp;
    let app_id;
    if(app){
      app_id = app.measurementAppsMap[0];
    }
    if (this.fieldName) {
      this.fieldService.save_field(app_id, this.fieldName, this.selectedBrpZone).subscribe(d => {
        const innerRoute = "fields";
        const url = "/apps/" + app.codeName;
        this.route.navigate([url], {queryParams: {navigateTo: innerRoute }}).then();
      });
    } else {
      document.getElementById('fieldName').style.backgroundColor = '#ff000040'
      await new Promise(resolve => setTimeout(resolve, 500));
      document.getElementById('fieldName').style.backgroundColor = 'white'
    }
  }


  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy() {
    this.mapFacade.updateSelectedBrpZone(undefined);
  }

  searchLocation(): void {
    this.fieldService.search_map_box(this.searchBar).subscribe(d => {
      if (d.features.length > 0) {
        this.layers.flyToPoint(d.features[0].center);
      }
    });
  }
}
