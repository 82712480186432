import { Component, Input, OnInit, ViewContainerRef } from "@angular/core";

@Component({
    selector: 'app-dynamic-map-info-content', // Define your custom HTML tag here
    template: '', // Since this is a dynamic component, it doesn't need its own template,
    styles: [":host {position: absolute}"]
  })
export class DynamicMapEntityInfoContentComponent implements OnInit {

    // change accordingly
    @Input() component: any;
    @Input() isVisible: boolean;
    @Input() displayData: any;
  
    constructor(public viewContainerRef: ViewContainerRef) { }
    
  
    ngOnInit() {
      this.viewContainerRef.clear();
      const componentRef = this.viewContainerRef.createComponent(this.component);
      (componentRef.instance as DynamicMapEntityInfoContentComponent).isVisible = this.isVisible;
      (componentRef.instance as DynamicMapEntityInfoContentComponent).displayData = this.displayData;
    }

    
  }