import { Component, Input } from '@angular/core';
import { PortalAppsService } from '../../../portal-apps.service';

@Component({
  selector: 'go-to-field-action-component',
  templateUrl: './go-to-field.component.html',
  styleUrls: ['./go-to-field.component.css']
})

export class GoToFieldActionComponent  {

    mapStrategy;
    constructor(private portalAppsService: PortalAppsService){
        this.mapStrategy = this.portalAppsService.activeApp.mapStrategy;
    }

    zoomToField(){
        this.mapStrategy.zoomToField();
    }



}