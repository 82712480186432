import {Component, Inject, Input, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { PortalAppsService } from 'src/app/services/portal-apps/portal-apps.service';
import { IPortalApp } from 'src/app/services/portal-apps/iportal-app';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit {
  constructor(private auth: AuthService, @Inject(DOCUMENT) private doc: Document, public translate: TranslateService,private router: Router, private route: ActivatedRoute, private portalAppService: PortalAppsService) {
    translate.setDefaultLang('nld')
    this.translate.use(localStorage.getItem('lang') || 'nld');
  }
  @ViewChild('navAppContainer',{read: ViewContainerRef}) navAppContainer: ViewContainerRef
  faCoffee = 'faCoffee';
  logoSize = 'Size';
  activeSideNav = false;
  opened = false;

  isExpanded = true;
  showSubmenu = false;
  isShowing = false;
  showBigNav = false;
  showConfirmLogout = false;

  currentRoute = "";
  currentApp: IPortalApp = null;

  bigContent = false;
  lang = 'nld';

  mobileSideMenuOpen = false;

  

  ngOnInit(): void {
    this.lang = localStorage.getItem('lang') ||  'nld';
    // this.bigContent = true;
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        // Get current route path
        this.currentRoute = this.route.snapshot.firstChild.routeConfig.path;
        if(this.currentRoute.includes("apps/")){
          
          this.currentApp = this.portalAppService.activeApp;
          
        }

        if(this.currentRoute.includes("fieldmap")){
          this.toggleBigContent();
        }
        else{
          if(this.bigContent){
            this.toggleBigContent();
          }
        }
       
      });

      
  }

  
  toggleMobileSideMenu(){
    this.mobileSideMenuOpen = !this.mobileSideMenuOpen;
  }

  toggleBigContent(){
    this.bigContent = !this.bigContent;
    setTimeout(() => {
      const parentElement = document.querySelector('.fixedNav') as HTMLElement;
      const childElement = document.querySelector('.nav-item') as HTMLElement;
      const logoElement = document.querySelector(".app-logo") as HTMLElement;
      const mobileIcon = (document.getElementsByClassName("mobile-navbar-open-icon")[0] as HTMLElement);
      const isMobile = window.getComputedStyle(mobileIcon).display == "block";

      if (parentElement && childElement && !isMobile) {
        
        if(this.bigContent){
          parentElement.style.transition = 'width 0.2s ease-in-out';
          childElement.style.transition = 'width 0.2s ease-in-out';
          logoElement.style.transition = "transform 0.2s ease-in-out";
        }
        else{
          parentElement.style.transition = 'unset';
          childElement.style.transition = 'unset';
          logoElement.style.transition = "unset";
        }
        
      }
    }, 50)
  }

  showSideNav() {
    this.activeSideNav = !this.activeSideNav;
  }

  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }

  logout() {
    localStorage.removeItem('org_id');
    this.auth.logout({ 
      logoutParams: {
        returnTo: this.doc.location.origin
      }
    });
  }

  toggleLogoutConfirmation(visibility: boolean) {
    this.showConfirmLogout = visibility;
  }

  showLanguageSelector = false

  changeLang(lang: string){
    localStorage.setItem('lang', lang);
    this.translate.use(lang);
    window.location.reload()
  }

  async backToMainMenuClick(){
    this.currentApp = null;
    if(this.bigContent){
      this.toggleBigContent();
    }
    // const apps = await this.portalAppService.getAllAvaliableApps();
    // if(apps.length == 1){
    //   this.router.navigateByUrl('/company-management');
    // }
    // else{
    //   this.router.navigateByUrl('/apps');
    // }

    this.router.navigateByUrl('/apps');
    
  }
}
