import { Injectable, NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules, PreloadingStrategy, Router, ROUTES, Route } from '@angular/router';
import { ProfilePageComponent } from './pages/profile-page/profile-page.component';
import { CompanyManagementPageComponent } from './pages/company-management-page/company-management-page.component'
import { DevicePageComponent } from './pages/devices-page/device-page.component';
import { FieldsPageComponent } from './pages/fields-overview-page/fields-page.component';
import { AddFieldPageComponent } from './pages/add-field-page/add-field-page.component';
import { FieldPageComponent } from './pages/field-page/field-page.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { DataManagementPageComponent } from "./pages/data-management-page/data-management-page.component";
import { StatisticsPageComponent } from "./pages/statistics-page/statistics-page.component";
import { TermsAndConditionsComponent } from './pages/terms-and-conditions-page/terms-and-conditions.component';
import { SuperuserPageComponent } from './pages/superuser-page/superuser-page.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { FieldMapPageComponent } from './pages/field-map-page/field-map-page.component';
import { ApplicationsPageComponent } from "./pages/applications-page/applications-page.component"; 
import { PortalAppsService } from './services/portal-apps/portal-apps.service';
import { AppRouteResolver } from './services/portal-apps/appRouteResolver';
import { HelpPageComponent } from './pages/help-page/help-page.component';


export const standardRoutes: Routes = [ 
  
  { path: '', redirectTo: 'welcome', pathMatch: 'full' },
  { path: 'login', redirectTo: 'welcome', pathMatch: 'full' },
  {
    path: 'welcome',
    component: LandingPageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'profile',
    component: ProfilePageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'company-management',
    component: CompanyManagementPageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'devices',
    component: DevicePageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "apps",
    component: ApplicationsPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "apps/:appCode",
    component: ApplicationsPageComponent,
    resolve: {appData: AppRouteResolver},
    canActivate: [AuthGuard]
  },
  {
    path: 'terms',
    component: TermsAndConditionsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'superuser',
    component: SuperuserPageComponent,
    canActivate: [AuthGuard],
  },
  { 
    path: 'manage_data', 
    component: DataManagementPageComponent, 
    canActivate: [AuthGuard] 
  },
  { 
    path: 'help', 
    component: HelpPageComponent, 
    canActivate: [AuthGuard] 
  },
  {path: "**", component: LandingPageComponent,
    canActivate: [AuthGuard]}
];



@NgModule({
  imports: [RouterModule.forRoot(standardRoutes, {})],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
