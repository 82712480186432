export function treeMeasurementDataLayer(map: mapboxgl.Map, visible: boolean){
    // map colors
    const darkGreen = '#0b4000'
    const red = '#ED2938';
    const white = '#FFFFFF';
    const blue = '#0088DD';
    const yellow = '#FFEB01';
    const lightGreen = '#74ff58'

    map.addLayer({
        id: 'treeMeasurementData-layer',
        type: 'circle',
        source: 'treeMeasurementData-source',
        layout: {
          visibility: (visible ? 'visible' : 'none')
        },
        paint: {
          'circle-radius': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            ['get', 'hoverRadius'], // Use a data property 'hoverRadius' for the hovered state

            ['boolean', ['feature-state', 'clicked'], false], // Check 'clicked'
            ['get', 'hoverRadius'], // If 'clicked' is true, use 'hoverRadius'

            ['get', 'defaultRadius'], // Use the default radius for other cases
          ],
          'circle-color': [
            'case',
            ['<', ['get', 'circumference'], 8],
            darkGreen,
            ['all', ['>=', ['get', 'circumference'], 8], ['<', ['get', 'circumference'], 10]],
            yellow,
            ['all', ['>=', ['get', 'circumference'], 10], ['<', ['get', 'circumference'], 12]],
            red,
            ['all', ['>=', ['get', 'circumference'], 12], ['<', ['get', 'circumference'], 14]],
            white,
            ['all', ['>=', ['get', 'circumference'], 14], ['<', ['get', 'circumference'], 16]],
            blue,
            ['all', ['>=', ['get', 'circumference'], 16], ['<', ['get', 'circumference'], 18]],
            yellow,
            ['all', ['>=', ['get', 'circumference'], 18], ['<', ['get', 'circumference'], 20]],
            red,
            ['all', ['>=', ['get', 'circumference'], 20], ['<', ['get', 'circumference'], 25]],
            white,
            ['all', ['>=', ['get', 'circumference'], 25], ['<', ['get', 'circumference'], 30]],
            blue,
            ['all', ['>=', ['get', 'circumference'], 30], ['<', ['get', 'circumference'], 35]],
            yellow,
            ['all', ['>=', ['get', 'circumference'], 35], ['<', ['get', 'circumference'], 40]],
            red,
            ['all', ['>=', ['get', 'circumference'], 40], ['<', ['get', 'circumference'], 45]],
            white,
            ['all', ['>=', ['get', 'circumference'], 45], ['<', ['get', 'circumference'], 50]],
            blue,
            ['all', ['>=', ['get', 'circumference'], 50], ['<', ['get', 'circumference'], 60]],
            yellow,
            ['all', ['>=', ['get', 'circumference'], 60], ['<', ['get', 'circumference'], 70]],
            red,
            ['all', ['>=', ['get', 'circumference'], 70], ['<', ['get', 'circumference'], 80]],
            white,
            ['all', ['>=', ['get', 'circumference'], 80], ['<', ['get', 'circumference'], 90]],
            blue,
            lightGreen
          ],
          'circle-stroke-color' : [
            'case',
            ['boolean', ['get', 'selected'], true],
            "#00ff00",

            ['boolean', ['get', 'toCreate'], true],
            "#00ff00",

            "#ffffff",
          ],
          "circle-stroke-opacity" : 1,
          "circle-stroke-width" : [
            'case',
            ['boolean', ['get', 'selected'], true],
            3,

            ['boolean', ['get', 'toCreate'], true],
            3,

            0
          ]

        }
      });
}