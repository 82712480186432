import { Component, OnInit } from '@angular/core';
import {DeviceForm} from '../../models/device.model';
import { DeviceService } from '../../services/device.service';

@Component({
  selector: 'app-devices-list',
  templateUrl: './devices-list.component.html',
  styleUrls: ['./devices-list.component.css'],
})
export class DevicesListComponent implements OnInit {
  devices: DeviceForm[] = [];
  showingDevices: DeviceForm[] = [];
  nextDevices: DeviceForm[] = [];
  connectedDevices: DeviceForm[] = [];
  page = 1;
  showDevices = true;
  showConnectedDevice = true;
  pages: number[] = [];

  constructor(
    private deviceService: DeviceService
  ) {
    this.deviceService.loadDevices(this.page).subscribe(d => {
      this.devices = d.devices;
      this.paginate(1);
      let i = 1;
      while (Math.ceil(this.devices.length / 3) >= i) {
        this.pages.push(i);
        i++;
      }
    });
  }

  ngOnInit(): void {
  }

  nextPage() {
      if (Math.ceil(this.devices.length / 3) >= this.page + 1) {
      this.paginate(this.page + 1);
    }
  }

  previousPage() {
    if (this.page > 1) {
      this.paginate(this.page - 1);
    }
  }

  loadConnectedDevices(deviceId: string) {
    this.deviceService.loadConnectedDevices(deviceId).subscribe(d => {
      this.connectedDevices = d.devices;
    });
  }

 paginate(pageNumber: number) {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  this.page = pageNumber;
  this.showingDevices = this.devices.slice((pageNumber - 1) * 3, pageNumber * 3);
}
}
