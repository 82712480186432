<div class="spacing_non_map_pages">
  <div fxLayout="column" fxLayoutAlign="start start">


    <div class="container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="50px">

      <div fxLayout="row" fxLayoutAlign="end center">
        <!-- fxLayoutGap.xs="150px"
       fxLayoutGap="1300px"
       fxLayoutGap.sm="320px"-->

        <h1 id="menu-text" class="unselectable">{{'i18n.DEVICES' | translate}}</h1>
        <div class="paginate-buttons unselectable" fxLayout="row" fxLayoutAlign="end center">
          <div class="previous-devices paginate-button mouse-pointer-click" (click)="previousPage()">
            <p class="paginate-arrows"><b>
                < </b>
            </p>
          </div>
          <p *ngFor="let p of pages" class="paginate-number mouse-pointer-click" [ngClass]="{'active-page': page == p}"
            (click)="paginate(p)">{{p}}</p>
          <div class="previous-devices paginate-button mouse-pointer-click" (click)="nextPage()">
            <p class="paginate-arrows"><b> > </b></p>
          </div>
        </div>
        <!--
      <div class="drop-down-menu">
      <button mat-button [matMenuTriggerFor]="menu"  fxLayout="row" fxLayoutAlign="end center" fxFlex>
          <mat-icon id="menu-icon">more_vert</mat-icon>
      </button>
      <mat-menu class="menu" #menu="matMenu">
          <button mat-menu-item>
              <svg id="delete-icon"
                   xmlns="http://www.w3.org/2000/svg"
                   xmlns:xlink="http://www.w3.org/1999/xlink"
                   width="0.347in" height="0.403in">
                  <path fill-rule="evenodd"  fill="rgb(255, 255, 255)"
                        d="M2.049,7.269 L5.540,28.211 L19.501,28.211 L22.992,7.269 L2.049,7.269 ZM24.155,2.616 L24.155,4.943 L0.886,4.943 L0.886,2.616 L7.530,2.616 C8.577,2.616 9.428,1.337 9.428,0.289 L15.613,0.289 C15.613,1.337 16.462,2.616 17.511,2.616 L24.155,2.616 Z"/>
              </svg>
              Delete</button>
          <button mat-menu-item>
              <svg id="restart-icon"
                   xmlns="http://www.w3.org/2000/svg"
                   xmlns:xlink="http://www.w3.org/1999/xlink"
                   width="0.403in" height="0.361in">
                  <path fill-rule="evenodd"  fill="rgb(255, 255, 255)"
                        d="M16.111,0.687 C9.462,0.687 4.048,5.928 3.737,12.500 L0.164,12.500 L5.523,19.489 L10.795,12.500 L7.281,12.500 C7.588,7.891 11.425,4.231 16.111,4.231 C20.997,4.231 24.971,8.205 24.971,13.091 C24.971,17.976 20.997,21.950 16.111,21.950 C13.298,21.950 10.793,20.628 9.170,18.579 L6.988,21.472 C9.255,23.938 12.498,25.494 16.111,25.494 C22.960,25.494 28.515,19.940 28.515,13.091 C28.515,6.242 22.960,0.687 16.111,0.687 Z"/>
              </svg>
              Restart</button>
          <button mat-menu-item>
              <svg id="update-icon"
                   xmlns="http://www.w3.org/2000/svg"
                   xmlns:xlink="http://www.w3.org/1999/xlink"
                   width="0.458in" height="0.361in">
                  <path fill-rule="evenodd"  fill="rgb(255, 255, 255)"
                        d="M12.627,12.931 L6.566,19.680 L0.403,12.931 L4.478,12.931 C4.478,6.181 9.951,0.708 16.702,0.708 C19.955,0.708 22.902,1.987 25.094,4.060 L22.372,7.090 C20.904,5.664 18.905,4.782 16.702,4.782 C12.207,4.782 8.553,8.437 8.553,12.931 L12.627,12.931 ZM26.838,6.183 L20.776,12.931 L24.851,12.931 C24.851,17.425 21.196,21.080 16.702,21.080 C14.497,21.080 12.500,20.197 11.031,18.773 L8.309,21.803 C10.500,23.875 13.449,25.155 16.702,25.155 C23.452,25.155 28.925,19.681 28.925,12.931 L33.000,12.931 L26.838,6.183 Z"/>
              </svg>
              Update</button>
      </mat-menu>
      </div>
      -->
      </div>
    </div>

    <div class="content" fxLayout="row wrap" fxLayoutAlign="space-between" fxLayoutGap="50px" fxLayoutGap.md="30px"
      fxLayout.md="row wrap" fxLayoutAlign.md="start stretch" fxLayout.sm="row wrap" fxLayoutAlign.sm="start stretch"
      fxLayoutGap.sm="30px" fxLayout.lg="row wrap" fxLayoutAlign.lg="start stretch" fxLayoutGap.lg="30px"
      fxLayout.gt-xs="row wrap" fxLayoutAlign.gt-xs="start stretch" fxFlexFill>
      <app-device-card *ngFor="let d of showingDevices" [device]="d" (click)="loadConnectedDevices(d.device_id)"
        class="mouse-pointer-click" fxFlex="30" fxFlex.lt-sm="100" fxFlex.sm="40" fxFlex.md="39" fxFlex.lg="30">
      </app-device-card>
    </div>


    <div class="container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="50px"
      *ngIf="connectedDevices.length > 0">


      <div fxLayout="row" fxLayoutAlign="end center">

        <h2 class="h2-title unselectable">{{'i18n.DEVICES_PAGE.CONNECTED_DEVICES' | translate}}</h2>
      </div>
    </div>

    <div class="content" fxLayout="row wrap" fxLayoutAlign="start stretch" fxLayoutGap="50px" fxLayoutGap.md="30px"
      fxLayout.md="row wrap" fxLayoutAlign.md="start stretch" fxLayout.sm="row wrap" fxLayoutAlign.sm="start stretch"
      fxLayoutGap.sm="30px" fxLayout.lg="row wrap" fxLayoutAlign.lg="start stretch" fxLayoutGap.lg="30px"
      fxLayout.gt-xs="row wrap" fxLayoutAlign.gt-xs="start stretch" fxFlexFill>
      <app-device-card *ngFor="let d of connectedDevices" [device]="d" [isCamera]="true" fxFlex="30" fxFlex.lt-sm="100"
        fxFlex.sm="40" fxFlex.md="39" fxFlex.lg="30"></app-device-card>
    </div>

  </div>
</div>