import authInfo from '../../auth_config.json';


// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  auth: {
    domain: authInfo.domain,
    clientId: authInfo.clientId,
    redirectUri: window.location.origin,
    
  },
  audience: authInfo.audience,
  api_gateway_url: 'https://api-gateway-new-stag-t7qk35obsa-ez.a.run.app',
  httpInterceptor: {
    allowedList: [
        '/*',
        {uri: 'https://api-gateway-new-stag-t7qk35obsa-ez.a.run.app/*'},
    ],
  },
  mapbox: {
    accessToken: 'pk.eyJ1IjoiZmxvcmlzcGV0ZXJzIiwiYSI6ImNrcjM2MzU2NjB3aWYycnFtbno2ampqYzgifQ.A-51Hqh4pULfmnMntVst7Q'
  },
  dev: {
    serverUrl: authInfo.serverUrl,
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
