<div class="spacing_non_map_pages">

  <div fxLayout="column" class="flex-container">
    <div class="fields_heading">
      <div class="navigation_arrows">

        <h1 id="menu-text" class="unselectable">{{'i18n.FIELDS_PAGE.TITLE' | translate}}</h1>
        <div class="paginate-buttons unselectable">
          <div class="previous-devices paginate-button pointer-click" (click)="changePage(activePage - 1)">
            <p class="paginate-arrows"><b>
                < </b>
            </p>
          </div>
          <p *ngFor="let p of pages" class="paginate-number pointer-click" [ngClass]="{'active-page': activePage == p}"
            (click)="changePage(p)">{{p}}</p>
          <div class="previous-devices paginate-button pointer-click" (click)="changePage(activePage + 1)">
            <p class="paginate-arrows"><b> > </b></p>
          </div>
        </div>
      </div>

      <button [routerLink]="['/apps/' + this.activeAppCode + '/fields/add']" class="global_btn add_field_btn unselectable">+
        {{'i18n.FIELDS_PAGE.NEW_FIELD' | translate}}</button>
    </div>



    <div class="content" *ngIf="fieldList">
      <div class="no_fields_note" *ngIf="fieldList.items.length == 0">
        {{'i18n.FIELDS_PAGE.NO_FIELDS' | translate}}
      </div>
      <ng-container *ngFor="let field of fieldList.items">
        <div class="global_tile card pointer-click" (click)="go_to_field(field.id, field.center)">
          <div #test class="svg_container">
            <svg style="width: 100%; height: 100%;">
              <polygon [attr.points]="getPoints(field)"
                style="stroke:purple;stroke-width:1;fill-rule:evenodd; fill: lightcoral;" />
              {{'i18n.FIELDS_PAGE.NO_POLYGON_SUPPORT' | translate}}
            </svg>
          </div>
          <div class="field_card_header">
            <span>{{field.name}}</span>

            <div class="global-option-icon unselectable" [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
              <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" focusable="false"
                style="pointer-events: none; display: block; width: 100%; height: 100%;">
                <g>
                  <path
                    d="M12,16.5c0.83,0,1.5,0.67,1.5,1.5s-0.67,1.5-1.5,1.5s-1.5-0.67-1.5-1.5S11.17,16.5,12,16.5z M10.5,12 c0,0.83,0.67,1.5,1.5,1.5s1.5-0.67,1.5-1.5s-0.67-1.5-1.5-1.5S10.5,11.17,10.5,12z M10.5,6c0,0.83,0.67,1.5,1.5,1.5 s1.5-0.67,1.5-1.5S12.83,4.5,12,4.5S10.5,5.17,10.5,6z">
                  </path>
                </g>
              </svg>
              <mat-menu #menu="matMenu">
                <!-- <button mat-menu-item (click)="editField(field.id)">
                  <mat-icon>edit</mat-icon>
                  <ng-container>Edit</ng-container>
                </button> -->
                <button mat-menu-item (click)="archiveField(field.id)">
                  <mat-icon>archive</mat-icon>
                  <ng-container>{{'i18n.GLOBAL.ARCHIVE' | translate}}</ng-container>
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>