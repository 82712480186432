<div class="wrapper">
    <button (click)="closeSelection()" class="close-btn">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
    </button>
    <h1>{{'i18n.APP_SPECIFIC.STM.TREE_SELECTION' | translate}}</h1>
    <div class="content">
        <div class="content-row">
            <span class="count-text">{{'i18n.APP_SPECIFIC.STM.NUM_OF_SELECTED_TREES' | translate}}</span><span class="count">{{data.value.count}}</span>
        </div>
        <div class="buttons-row">
            
            <div *ngIf="data.value.count != 0 && !mapStrategy.isMutationOn && !mapStrategy.showArchivedData" class="left-side-btns">
                <button (click)="mutateSelectedPoints()" class="mutate">{{'i18n.APP_SPECIFIC.STM.MUTATE' | translate}}</button>
                <button (click)="archiveSelectedPoints()" class="archive">{{'i18n.GLOBAL.ARCHIVE' | translate}}</button>
            </div>

            <div *ngIf="data.value.count != 0 && mapStrategy.showArchivedData" (click)="unarchiveSelectedpoints()" class="left-side-btns">
                <button (click)="unarchiveSelectedpoints()" class="unarchive">
                    {{'i18n.GLOBAL.UNARCHIVE' | translate}}
                </button>
            </div>

            <button (click)="clearPoints()" class="clear">{{'i18n.GLOBAL.DESELECT' | translate}}</button>
           
        </div> 
    </div>
</div>