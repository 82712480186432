import { Component, OnInit } from '@angular/core';
import { SuperuserService } from '../../services/superuser.service';

@Component({
  selector: 'app-superuser-page',
  templateUrl: './superuser-page.component.html',
  styleUrls: ['./superuser-page.component.css']
})
export class SuperuserPageComponent implements OnInit {
  adminId: any;
  conDeviceId: any;
  orgId: any;
  
  constructor(private superuserService: SuperuserService) { }

  ngOnInit(): void {
  }

  createOrgAndAdmin(name: string, username: string, email: string){
    // this.orgId = this.superuserService.createOrganization(name);
    this.adminId = this.superuserService.createOrgAdmin(username, email, this.orgId);
  }

  newConDevice(conDevice){
    this.conDeviceId = this.superuserService.createConDevice(conDevice, this.adminId)
  }

  newCameraDevice(cameraName){
    this.superuserService.createCameraDevice(cameraName, this.conDeviceId)
  }
}
