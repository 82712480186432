import { Component, Input } from '@angular/core';
import { PortalAppsService } from '../../../portal-apps.service';

@Component({
  selector: 'map-tree-selection-component',
  templateUrl: './map-tree-selection.component.html',
  styleUrls: ['./map-tree-selection.component.css']
})

export class MapTreeSelectionComponent  {

    mapStrategy;
    isSelected = false;
    constructor(private portalAppsService: PortalAppsService){
        this.mapStrategy = this.portalAppsService.activeApp.mapStrategy;
    }


    openTreeSelection(){
        this.mapStrategy.changePointSelectionMode("tree");
    }


}