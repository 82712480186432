import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserForm } from '../../models/user.model';
import { ChangePasswordDialogComponent } from '../../dialogs/change-password-dialog/change-password-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';
import { UserService } from '../../services/user.service';
import { IMember } from 'src/app/models/organization.model';

@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.css']
})
export class ProfilePageComponent implements OnInit {

  constructor(
    public auth: AuthService,
    private userService: UserService,
  ) {}

  editAble = true;
  editingProfile = false;
  userInfo: IMember;

  async ngOnInit() {
    this.userService.get_user().subscribe(result => {
      this.userInfo = result as IMember;
    });
  }

  updateProfile() {
    let firstName = document.getElementById('firstName') as HTMLInputElement
    let lastName = document.getElementById('lastName') as HTMLInputElement

    this.userService.update_user(firstName.value, lastName.value).subscribe(d => {

    });

    // let email;
    // let nickname;
    // if (this.userData.email !== this.userDataForm.value.mail) {
    //   email = this.userDataForm.value.nickname;
    // }
    // if (this.userData.nickname !== this.userDataForm.value.nickname) {
    //   nickname = this.userDataForm.value.nickname;
    // }
    // if (nickname || email) {
    //   this.userService.updateUserInfo(email, nickname).subscribe(d => {
    //     this.userData = d;
    //     this.username = this.userData.nickname;
    //     this.userDataForm = new FormGroup({
    //       nickname: new FormControl(this.userData.nickname, Validators.required),
    //       mail: new FormControl(this.userData.email, Validators.email)
    //     });
    //   });
    //   this.updatedEditForm();
    // } else {
    //   this.updatedEditForm();
    // }

  }
}
