<div class="meta-data-container" *ngIf="this.isVisible.value">
    <h3>{{'i18n.APP_SPECIFIC.STM.META_DATA_MEASUREMENTS.TITLE' | translate}}</h3>

    <table>
        <tr>
            <th>
                {{'i18n.APP_SPECIFIC.STM.META_DATA_MEASUREMENTS.PROPERTY' | translate}}
            </th>
            <th>
                {{'i18n.APP_SPECIFIC.STM.META_DATA_MEASUREMENTS.VALUE' | translate}}
            </th>
        </tr>
        <tr>
            <td>{{'i18n.APP_SPECIFIC.STM.META_DATA_MEASUREMENTS.TREE_TYPE' | translate}}</td>
            <td><span *ngIf="displayData.value.tree_type != 'null'">{{displayData.value.tree_type}}</span></td>
        </tr>   
        <tr>
            <td>{{'i18n.APP_SPECIFIC.STM.META_DATA_MEASUREMENTS.ROW' | translate}}</td>
            <td><span *ngIf="displayData.value.row_index != 'null'">{{displayData.value.row_index}}</span></td>
        </tr>
        <tr>
            <td>{{'i18n.APP_SPECIFIC.STM.META_DATA_MEASUREMENTS.NOTE' | translate}}</td>
            <td><span *ngIf="displayData.value.note">{{displayData.value.note}}</span></td>
        </tr>
        <tr>
            <td>{{'i18n.APP_SPECIFIC.STM.META_DATA_MEASUREMENTS.DAMAGED' | translate}}</td>
            <td><span *ngIf="displayData.value.damaged != null">{{displayData.value.damaged}}</span></td>
        </tr>
        <tr>
            <td>{{'i18n.APP_SPECIFIC.STM.META_DATA_MEASUREMENTS.HAS_POLE' | translate}}</td>
            <td><span *ngIf="displayData.value.has_pole != null">{{displayData.value.has_pole}}</span></td>
        </tr>
        <tr>
            <td>{{'i18n.APP_SPECIFIC.STM.META_DATA_MEASUREMENTS.AB_QUALITY' | translate}}</td>
            <td><span *ngIf="displayData.value.abQuality != null">{{displayData.value.abQuality}}</span></td>
        </tr>
    </table>

    <!-- <p *ngIf="customerAddedTreeData.length > 0"> klant specifiek </p>
    <div>
        <p *ngFor="let item of customerAddedTreeData">{{item.key}}: <span>{{item.value}}</span></p>

    </div> -->
</div>