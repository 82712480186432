import { Component, Injectable, Type } from '@angular/core';
import { IPortalApp } from '../iportal-app';
import { Route } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { FieldsPageComponent } from 'src/app/pages/fields-overview-page/fields-page.component';
import { FieldPageComponent } from 'src/app/pages/field-page/field-page.component';
import { FieldMapPageComponent } from 'src/app/pages/field-map-page/field-map-page.component';
import { AddFieldPageComponent } from 'src/app/pages/add-field-page/add-field-page.component';
import { DevicePageComponent } from 'src/app/pages/devices-page/device-page.component';
import { StatisticsPageComponent } from 'src/app/pages/statistics-page/statistics-page.component';
import { FieldsNavItem } from 'src/app/shared/layout/navbar_components/fields-nav-item/fields-nav-item.component';
import { StatisticsNavItem } from 'src/app/shared/layout/navbar_components/statistics-nav-item/statistics-nav-item.component';
import { DevicesNavItem } from 'src/app/shared/layout/navbar_components/devices-nav-item/devices-nav-item.component';
import { StmMapStrategy } from '../map-strategies/stm-map-strategy';
import { STMStatisticsPageComponent } from '../app-statistics/stm-statistics-page/stm-statistics-page.component';

@Injectable({
  providedIn: 'root'
})
export class StmAppService implements IPortalApp {

    measurementAppsMap: string[] = ["7fa63b0e-ee95-416d-8fa9-cc5dccfa2c4f"];  // an array of measurement app ids that this portal app use. A user must match all of the delcared measurement apps to utilize the portal app.
    iconPath: string = "/assets/Icons/app-icons/StamDikteMeter.png";
    codeName: string = "STM";
    name: string = "i18n.APPS_PAGE.STM_TITLE";
    navbarItems: any[] = [FieldsNavItem, StatisticsNavItem,DevicesNavItem]; 
    pages: Route[] = [{
      path: "fields",
      component: FieldsPageComponent,
      canActivate: [AuthGuard]
    },
    {
      path: 'fields/field',
      component: FieldPageComponent,
      canActivate: [AuthGuard],
    },
    {
      path: 'fields/fieldmap',
      component: FieldMapPageComponent,
      canActivate: [AuthGuard],
    },
    {
      path: 'fields/add',
      component: AddFieldPageComponent,
      canActivate: [AuthGuard],
    },
    {
      path: 'devices',
      component: DevicePageComponent,
      canActivate: [AuthGuard],
    },
    { path: 'statistics', component: StatisticsPageComponent, canActivate: [AuthGuard] }
  ];
    mapStrategy: any;
    statisticsComponent: any = STMStatisticsPageComponent;
    

    constructor(private stmMapStrategy: StmMapStrategy) {
        this.mapStrategy = this.stmMapStrategy;
    }

}