import { Component, Input } from '@angular/core';
import { RouterLinkActive, RouterModule  } from '@angular/router';

@Component({
  selector: 'devices-nav-item',
  templateUrl: './devices-nav-item.component.html'
})

export class DevicesNavItem  {
    @Input() codeName: string;
}