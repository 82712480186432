import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import * as global from '../../globals';
import {DeviceForm} from '../models/device.model';
import {concatMap, take} from 'rxjs/operators';
import {AuthService} from '@auth0/auth0-angular';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  constructor(private http: HttpClient, private auth: AuthService) {
  }

  loadDevices(page: number): Observable<any> {
    return this.auth.user$
      .pipe(
        take(1),
        concatMap((user) => {
            return this.http.get<any>(global.apiUrl + global.device + '/accessibledevices?limit=3&page=' + page + '&limit=100');
          }
        )
      );
  }

  loadDevice(deviceId: string): Observable<DeviceForm> {
    return this.auth.user$
      .pipe(
        take(1),
        concatMap((user) => {
            return this.http.get<any>(global.apiUrl + global.device + '/device/' + deviceId);

          }
        )
      );
  }

  loadConnectedDevices(deviceId: string): Observable<any> {
    return this.auth.user$
      .pipe(
        take(1),
        concatMap((user) => {
            return this.http.get<any>(global.apiUrl + global.device + '/connected_devices/' + deviceId);

          }
        )
      );
  }
}
