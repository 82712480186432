import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'fields-nav-item',
  templateUrl: './fields-nav-item.component.html'
})

export class FieldsNavItem {
  constructor(private router: Router){
   
  }
  @Input() codeName: string;

}