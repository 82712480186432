export function fieldBoundarySource(map: mapboxgl.Map, coordinates){
    map.addSource('fieldBoundery-source', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature' as const,
              properties: {},
              geometry: {
                type: 'Polygon' as const,
                coordinates: coordinates
              }
            }
          ]
        }
      });
}