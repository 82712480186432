import { Component, Input } from '@angular/core';
import { PortalAppsService } from '../../../portal-apps.service';

@Component({
  selector: 'map-row-selection-component',
  templateUrl: './map-row-selection.component.html',
  styleUrls: ['./map-row-selection.component.css']
})

export class MapRowSelectionComponent  {

    mapStrategy;
    constructor(private portalAppsService: PortalAppsService){
        this.mapStrategy = this.portalAppsService.activeApp.mapStrategy;
    }


    openRowSelection(){
        this.mapStrategy.changePointSelectionMode("row");
    }


}