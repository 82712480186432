import { Component, OnInit } from '@angular/core';
import { ApiService } from './services/api.service';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     // Store the current route in localStorage
    //     localStorage.setItem('lastVisitedRoute', event.urlAfterRedirects);
    //   }
    // });

    // // Restore the last visited route on page reload
    // const lastVisitedRoute = localStorage.getItem('lastVisitedRoute');
    // if (lastVisitedRoute) {
    //   if(lastVisitedRoute.includes("/apps/")){
    //     const directories = lastVisitedRoute.split("/");
    //     const appCode = directories[2];
    //     const appUrl = "/apps/" + appCode
    //     const innerRoute = lastVisitedRoute.replace(appUrl + "/", "");
    //     this.router.navigate([appUrl], {queryParams: {navigateTo: innerRoute }})
        
    //   }
    // }
  }
}
