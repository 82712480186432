<div class="backgroundContainer">
  <!-- Logout modal -->
  <div class="logout-confirmation-background" *ngIf="showConfirmLogout">
    <div class="logout-confirmation">
      <p class="logout-text">{{'i18n.MENU.LOGOUT.CONFIRM' | translate}}</p>
      <button class="global_btn logout_btns" (click)="logout()">{{'i18n.MENU.LOGOUT.LOGOUT' | translate}}</button>
      <button class="global_btn logout_btns cancel_btn" (click)="toggleLogoutConfirmation(false)">{{'i18n.GLOBAL.CANCEL'
        | translate}}</button>
    </div>
  </div>
  <div class="mobile-navbar-open-icon" (click)="toggleMobileSideMenu()" [ngClass]="{'toggled' : mobileSideMenuOpen}">
    <div class="bar1"></div>
    <div class="bar2"></div>
    <div class="bar3"></div>
  </div>
  <mat-sidenav-container fxLayout="row" fxLayoutAlign="start stretch" class="global_page_background" [ngClass]="{'expanded-content' : bigContent}">
    <div [ngClass]="{'fixedNav' : true, 'shrinked-nav': bigContent, 'mobile-nav-open': mobileSideMenuOpen}">
      <div class="top-nav-section unselectable">
        <app-logo [Size]="logoSize" class="mouse-pointer-click app-logo" routerLink="/"></app-logo>
      </div>
      <div class="middle-nav-section">
        <!-- Old navigation with the applications page -->
        <ul *ngIf="currentApp == null" class="nav-list">
          <li [routerLinkActive]="['nav-item-selected']" class="nav-item mouse-pointer-click" routerLink="/apps">
            <div class="nav-list-item">
              <svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path class="svg-icon" d="M290.8 48.6l78.4 29.7L288 109.5 206.8 78.3l78.4-29.7c1.8-.7 3.8-.7 5.7 0zM136 92.5V204.7c-1.3 .4-2.6 .8-3.9 1.3l-96 36.4C14.4 250.6 0 271.5 0 294.7V413.9c0 22.2 13.1 42.3 33.5 51.3l96 42.2c14.4 6.3 30.7 6.3 45.1 0L288 457.5l113.5 49.9c14.4 6.3 30.7 6.3 45.1 0l96-42.2c20.3-8.9 33.5-29.1 33.5-51.3V294.7c0-23.3-14.4-44.1-36.1-52.4l-96-36.4c-1.3-.5-2.6-.9-3.9-1.3V92.5c0-23.3-14.4-44.1-36.1-52.4l-96-36.4c-12.8-4.8-26.9-4.8-39.7 0l-96 36.4C150.4 48.4 136 69.3 136 92.5zM392 210.6l-82.4 31.2V152.6L392 121v89.6zM154.8 250.9l78.4 29.7L152 311.7 70.8 280.6l78.4-29.7c1.8-.7 3.8-.7 5.7 0zm18.8 204.4V354.8L256 323.2v95.9l-82.4 36.2zM421.2 250.9c1.8-.7 3.8-.7 5.7 0l78.4 29.7L424 311.7l-81.2-31.1 78.4-29.7zM523.2 421.2l-77.6 34.1V354.8L528 323.2v90.7c0 3.2-1.9 6-4.8 7.3z"/></svg>
              <p class="link-text text unselectable">{{'i18n.MENU.ITEMS.APPS' | translate}}</p>
            </div>
          </li>

          <li [routerLinkActive]="['nav-item-selected']" class="nav-item mouse-pointer-click" routerLink="/profile">
            <div class="nav-list-item">
              <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <defs>
                </defs>
                <path id="Vector" class="svg-icon"
                  d="M75,587a7,7,0,1,1-7-7A7,7,0,0,1,75,587Zm-1.6,7.18a8.93,8.93,0,0,1-10.809,0C58.521,595.972,56,601.555,56,604H80C80,601.577,77.4,595.994,73.4,594.181Z"
                  transform="translate(-56 -580)" />
              </svg>
              <p class="link-text text unselectable">{{'i18n.MENU.ITEMS.PROFILE' | translate}}</p>
            </div>
          </li>

          <li [routerLinkActive]="['nav-item-selected']" class="nav-item mouse-pointer-click"
            routerLink="/company-management">
            <div class="nav-list-item">
              <svg class="icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                height="24" width="24" version="1.1" id="_x32_" viewBox="0 0 512 512" xml:space="preserve">

                <g>
                  <path class="svg-icon"
                    d="M320.707,0L37.037,69.971v417.625L320.447,512l154.516-26.258V62.568L320.707,0z M290.346,471.742   l-92.584-7.974v-79.426l-55.086-0.677v75.36l-68.109-5.866V99.367l215.779-53.224V471.742z" />
                  <polygon class="svg-icon" points="271.25,76.933 226.537,86.32 226.537,138.956 271.25,131.246  " />
                  <polygon class="svg-icon" points="118.574,112.033 87.416,118.622 87.416,164.818 118.574,159.469  " />
                  <polygon class="svg-icon" points="190.012,95.942 150.426,104.23 150.426,153.027 190.012,146.202  " />
                  <polygon class="svg-icon" points="118.576,203.184 87.416,207.448 87.416,253.722 118.576,250.622  " />
                  <polygon class="svg-icon"
                    points="190.012,192.792 150.426,198.154 150.426,246.952 190.012,243.052  " />
                  <polygon class="svg-icon" points="271.25,181.04 226.537,187.097 226.537,238.911 271.25,234.506  " />
                  <polygon class="svg-icon" points="271.25,286.135 226.537,288.889 226.537,340.702 271.25,339.6  " />
                  <polygon class="svg-icon"
                    points="190.012,291.476 150.426,293.914 150.426,342.712 190.012,341.737  " />
                  <polygon class="svg-icon" points="118.574,296.198 87.416,298.136 87.416,344.409 118.574,343.634  " />
                </g>
              </svg>
              <p class="link-text text unselectable">{{'i18n.MENU.ITEMS.COMPANY' | translate}}</p>
            </div>
          </li>

          <li [routerLinkActive]="['nav-item-selected']" class="nav-item mouse-pointer-click" routerLink="/help">
            <div class="nav-list-item">
              <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path class="svg-icon" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM169.8 165.3c7.9-22.3 29.1-37.3 52.8-37.3l58.3 0c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24l0-13.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1l-58.3 0c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/></svg>
              <p class="link-text text unselectable">{{'i18n.MENU.ITEMS.HELP' | translate}}</p>
            </div>
          </li>

          <!-- <li [routerLinkActive]="['nav-item-selected']" class="nav-item mouse-pointer-click" routerLink="/manage_data">
            <div class="nav-list-item">
              <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="icon">
                <path id="Vector" class="svg-icon"
                  d="M22 18.055v2.458c0 1.925-4.655 3.487-10 3.487-5.344 0-10-1.562-10-3.487v-2.458c2.418 1.738 7.005 2.256 10 2.256 3.006 0 7.588-.523 10-2.256zm-10-3.409c-3.006 0-7.588-.523-10-2.256v2.434c0 1.926 4.656 3.487 10 3.487 5.345 0 10-1.562 10-3.487v-2.434c-2.418 1.738-7.005 2.256-10 2.256zm0-14.646c-5.344 0-10 1.562-10 3.488s4.656 3.487 10 3.487c5.345 0 10-1.562 10-3.487 0-1.926-4.655-3.488-10-3.488zm0 8.975c-3.006 0-7.588-.523-10-2.256v2.44c0 1.926 4.656 3.487 10 3.487 5.345 0 10-1.562 10-3.487v-2.44c-2.418 1.738-7.005 2.256-10 2.256z" />
              </svg>
              <p class="link-text text unselectable">{{'i18n.DATA' | translate}}</p>
            </div>
          </li> -->
        </ul>
        <div class="app-title" *ngIf="currentRoute.includes('apps/')">
          <svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path class="svg-icon" d="M234.5 5.7c13.9-5 29.1-5 43.1 0l192 68.6C495 83.4 512 107.5 512 134.6V377.4c0 27-17 51.2-42.5 60.3l-192 68.6c-13.9 5-29.1 5-43.1 0l-192-68.6C17 428.6 0 404.5 0 377.4V134.6c0-27 17-51.2 42.5-60.3l192-68.6zM256 66L82.3 128 256 190l173.7-62L256 66zm32 368.6l160-57.1v-188L288 246.6v188z"/></svg>
          <span>{{currentApp.name  | translate}}</span>
        </div>
        <!-- New navigation within the app -->
        <ul *ngIf="currentApp != null" class="nav-list-app">
            <!-- use appropriate navigation for an app -->
            <app-dynamic-navbar-content *ngFor="let navbarItem of currentApp.navbarItems" [component]="navbarItem" [codeName]="currentApp.codeName"></app-dynamic-navbar-content>
        </ul>
      </div>
      <div class="bottom-nav-section">





        <div class="nav-list" *ngIf="currentApp == null">

          <div class="custom-select">
            <ul class="options" *ngIf="showLanguageSelector" (mouseleave)="showLanguageSelector = false">
              <li (click)="changeLang('nld')">{{'i18n.MENU.LANG.DUTCH' | translate}}</li>
              <li (click)="changeLang('eng')">{{'i18n.MENU.LANG.ENGLISH' | translate}}</li>
            </ul>
            <p class="selected-option" (click)="showLanguageSelector = true" >
              <span *ngIf="lang == 'nld'">{{'i18n.MENU.LANG.DUTCH' | translate}}</span>
              <span *ngIf="lang == 'eng'">{{'i18n.MENU.LANG.ENGLISH' | translate}}</span>
              <svg
              xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cc="http://creativecommons.org/ns#"
              xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns="http://www.w3.org/2000/svg" width="14px"
              height="14px" viewBox="0 0 16 16" id="svg2" version="1.1">



              <g id="layer1" transform="rotate(45 1254.793 524.438)">

                <path
                  style="fill:#373737;fill-opacity:1;fill-rule:evenodd;stroke:none;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
                  d="M4.468 1048.341h1.996v-9h9v-2h-11z" id="path4179" />

              </g>

            </svg></p>
          </div>

          <div (click)="toggleLogoutConfirmation(true)" class="logout_btn">

            <svg id="logout-icon" version="1.1" class="icon" xmlns="http://www.w3.org/2000/svg" width="28.125"
              height="25.75" viewBox="0 0 320.002 320.002" style="enable-background:new 0 0 320.002 320.002;"
              xml:space="preserve">
              <g id="XMLID_6_">
                <path class="svg-icon" id="XMLID_7_" d="M51.213,175.001h173.785c8.284,0,15-6.716,15-15c0-8.284-6.716-15-15-15H51.213l19.394-19.394
		c5.858-5.858,5.858-15.355,0-21.213c-5.857-5.858-15.355-5.858-21.213,0L4.396,149.393c-0.351,0.351-0.683,0.719-0.997,1.103
		c-0.137,0.167-0.256,0.344-0.385,0.515c-0.165,0.22-0.335,0.435-0.488,0.664c-0.14,0.209-0.261,0.426-0.389,0.64
		c-0.123,0.206-0.252,0.407-0.365,0.619c-0.118,0.22-0.217,0.446-0.323,0.67c-0.104,0.219-0.213,0.435-0.306,0.659
		c-0.09,0.219-0.164,0.442-0.243,0.664c-0.087,0.24-0.179,0.477-0.253,0.722c-0.067,0.222-0.116,0.447-0.172,0.672
		c-0.063,0.249-0.133,0.497-0.183,0.751c-0.051,0.259-0.082,0.521-0.119,0.782c-0.032,0.223-0.075,0.443-0.097,0.669
		c-0.048,0.484-0.073,0.971-0.074,1.457c0,0.007-0.001,0.015-0.001,0.022c0,0.007,0.001,0.015,0.001,0.022
		c0.001,0.487,0.026,0.973,0.074,1.458c0.022,0.223,0.064,0.44,0.095,0.661c0.038,0.264,0.069,0.528,0.121,0.79
		c0.05,0.252,0.119,0.496,0.182,0.743c0.057,0.227,0.107,0.456,0.175,0.681c0.073,0.241,0.164,0.474,0.248,0.71
		c0.081,0.226,0.155,0.453,0.247,0.675c0.091,0.22,0.198,0.431,0.3,0.646c0.108,0.229,0.21,0.46,0.33,0.685
		c0.11,0.205,0.235,0.4,0.354,0.599c0.131,0.221,0.256,0.444,0.4,0.659c0.146,0.219,0.309,0.424,0.466,0.635
		c0.136,0.181,0.262,0.368,0.407,0.544c0.299,0.364,0.616,0.713,0.947,1.048c0.016,0.016,0.029,0.034,0.045,0.05l45,45.001
		c2.93,2.929,6.768,4.394,10.607,4.394c3.838-0.001,7.678-1.465,10.606-4.393c5.858-5.858,5.858-15.355,0.001-21.213L51.213,175.001
		z" />
                <path class="svg-icon" id="XMLID_8_" d="M305.002,25h-190c-8.284,0-15,6.716-15,15v60c0,8.284,6.716,15,15,15s15-6.716,15-15V55h160v210.001h-160
		v-45.001c0-8.284-6.716-15-15-15s-15,6.716-15,15v60.001c0,8.284,6.716,15,15,15h190c8.284,0,15-6.716,15-15V40
		C320.002,31.716,313.286,25,305.002,25z" />
              </g>
            </svg>
            <p class="link-text text unselectable">{{'i18n.MENU.LOGOUT.LOGOUT' | translate}} </p>

          </div>
        </div>

        <div class="nav-list"  *ngIf="currentApp != null">
            <div class="back-btn" (click)="backToMainMenuClick()">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M512 256A256 256 0 1 0 0 256a256 256 0 1 0 512 0zM231 127c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-71 71L376 232c13.3 0 24 10.7 24 24s-10.7 24-24 24l-182.1 0 71 71c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L119 273c-9.4-9.4-9.4-24.6 0-33.9L231 127z"/></svg>
              <span>{{'i18n.MENU.ITEMS.MENU' | translate}}</span>
            </div>
        </div>
      </div>



    </div>


    <div class="mobile-content-cover" [ngClass]="{'no-cover': !mobileSideMenuOpen}" (click)="toggleMobileSideMenu()">

    </div>
    <div [ngClass]="'rout-container-s'">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-container>


</div>